import { Pto } from '@merchx-v3/pto'
import { WebSocket } from '@merchx-v3/web-socket'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { store } from 'app/store'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

export const designsApi = createApi({
  reducerPath: 'designsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['Designs'],
  endpoints: (builder) => ({
    design: builder.query<Pto.Designs.Design, string>({
      query: (designId) => ({
        url: `/designs/${designId}`
      }),
      providesTags: (_result, _error, designId) => getProvidedTags('Designs', designId)
    }),
    customerDesignList: builder.query<Pto.Designs.DesignList, Pto.Designs.GetListArgs>({
      query: (params) => ({
        url: `/designs`,
        params
      }),
      providesTags: (customerDesignListData, _error, _args) =>
        getProvidedTags(
          'Designs',
          'LIST',
          customerDesignListData?.items.map((item) => item.id)
        )
    }),
    customerDesignOptions: builder.query<Pto.Option[], Pto.Designs.GetListArgs>({
      query: (params) => ({
        url: `/designs/options`,
        params
      }),
      providesTags: (customerDesignOptionsData, _error, _args) =>
        getProvidedTags(
          'Designs',
          'OPTIONS',
          customerDesignOptionsData?.map((item) => item.value)
        )
    }),
    createCustomerDesign: builder.mutation<void, Pto.Designs.CreateDesign>({
      query: (body) => ({
        url: '/designs',
        body,
        method: 'POST'
      }),
      invalidatesTags: (res, err, args) => [
        { type: 'Designs', id: `OPTIONS_FOR_${args.customerId}` },
        { type: 'Designs', id: `LIST_FOR_${args.customerId}` }
      ]
    }),
    changeDesignStatus: builder.mutation<void, Pto.Designs.UpdateStatus>({
      query: (body) => ({
        url: '/designs/update-status',
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, args) => [{ type: 'Designs', id: args.designId }]
    }),
    changeDesignName: builder.mutation<void, Pto.Designs.UpdateName>({
      query: (body) => ({
        url: '/designs/update-name',
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, args) => [{ type: 'Designs', id: args.designId }]
    }),
    uploadCustomerDesignSourceFiles: builder.mutation<void, Pto.Designs.UploadSourceFiles>({
      query: (body) => ({
        url: `/designs/upload-source-files`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, args) => [{ type: 'Designs', id: args.designId }]
    }),
    uploadCustomerDesignThumbnail: builder.mutation<void, Pto.Designs.UploadThumbnail>({
      query: (body) => ({
        url: `/designs/upload-thumbnail`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, args) => [{ type: 'Designs', id: args.designId }]
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Design') {
    store.dispatch(designsApi.util.invalidateTags([{ type: 'Designs', id: payload.entityId }]))
  }
}

export const subscribeToDesignEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromDesignEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const {
  useDesignQuery,
  useCustomerDesignListQuery,
  useCustomerDesignOptionsQuery,
  useCreateCustomerDesignMutation,
  useChangeDesignStatusMutation,
  useChangeDesignNameMutation,
  useUploadCustomerDesignSourceFilesMutation,
  useUploadCustomerDesignThumbnailMutation
} = designsApi
