export class TokenProviderInitializedEvent extends CustomEvent<string | undefined> {
  constructor(accessToken?: string) {
    super('token-provider-initialized', { detail: accessToken })
  }
}

export class AccessTokenChangedEvent extends CustomEvent<string | undefined> {
  constructor(accessToken?: string) {
    super('access-token-changed', { detail: accessToken })
  }
}

export class AccessTokenLoadedEvent extends CustomEvent<string | undefined> {
  constructor(accessToken: string) {
    super('access-token-loaded', { detail: accessToken })
  }
}

export class AccessTokenRemovedEvent extends Event {
  constructor() {
    super('acces-token-removed')
  }
}

export class RefreshTokenChangedEvent extends CustomEvent<string | undefined> {
  constructor(refreshToken?: string) {
    super('refresh-token-changed', { detail: refreshToken })
  }
}

export interface TokenProviderEventMap {
  'token-provider-initialized': TokenProviderInitializedEvent
  'access-token-loaded': AccessTokenLoadedEvent
  'access-token-removed': AccessTokenRemovedEvent
  'access-token-changed': AccessTokenChangedEvent
  'refresh-token-changed': RefreshTokenChangedEvent
}

interface TokenProviderET extends EventTarget {
  addEventListener<K extends keyof TokenProviderEventMap>(type: K, listener: (ev: TokenProviderEventMap[K]) => void, options?: boolean | AddEventListenerOptions): void
  addEventListener(type: string, callback: EventListenerOrEventListenerObject | null, options?: EventListenerOptions | boolean): void
  removeEventListener<K extends keyof TokenProviderEventMap>(type: K, callback: (ev: TokenProviderEventMap[K]) => void): void
  removeEventListener(type: string, callback: EventListenerOrEventListenerObject): void
}

export const TokenProviderEventTarget = EventTarget as { new (): TokenProviderET; prototype: TokenProviderET }

export interface ITokenProvider extends TokenProviderET {
  get state(): 'INIT' | 'READY'

  getAccessToken: () => Promise<string | undefined>
  setAccessToken: (newToken?: string) => void

  getRefreshToken: () => string | undefined
  setRefreshToken: (newRefreshToken?: string) => void

  setIsRememberMe: (isRememberMe?: boolean) => void

  prepareHeaders: () => Promise<Headers>
}
