import { useEffect } from 'react'
import { Button, Form, Input, Modal, Select, Switch, Typography } from 'antd'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import { Pto } from '@merchx-v3/pto'
import { toast } from 'react-toastify'
import styles from './AddSupplierSelectColumnModal.module.scss'

const valueTypes = [
  Pto.Plugins.ValueType.Artworks,
  Pto.Plugins.ValueType.Boolean,
  Pto.Plugins.ValueType.Float,
  Pto.Plugins.ValueType.Integer,
  Pto.Plugins.ValueType.JSON,
  Pto.Plugins.ValueType.String
]

type Props = {
  isOpened: boolean
  onClose: () => void
}

const AddSupplierSelectColumnModal = ({ isOpened, onClose }: Props) => {
  const [form] = Form.useForm()

  const handleFinishFailed = (errorInfo: ValidateErrorEntity) => {
    toast.error(errorInfo?.errorFields?.map((errField) => errField.errors.join('\n')).join('\n'))
  }

  useEffect(() => {
    if (!isOpened) {
      form.resetFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened])

  return (
    <Modal open={isOpened} onCancel={onClose} footer={false} forceRender>
      <Typography.Title level={5}>Add </Typography.Title>

      <Form name="editSupplierSelectColumn" layout="vertical" form={form} className={styles.form} onFinishFailed={handleFinishFailed}>
        <Form.Item label="Field Key" name="fieldKey" rules={[{ required: true, message: 'Please input Field Key!' }]}>
          <Input size="large" placeholder="Field Key" />
        </Form.Item>

        <Form.Item label="Display Name" name="displayName" rules={[{ required: true, message: 'Please input Display Name!' }]}>
          <Input size="large" placeholder="Display Name" />
        </Form.Item>

        <Form.Item label="Is Required" name="isRequired" valuePropName="checked" initialValue={true}>
          <Switch />
        </Form.Item>

        <Form.Item label="Role" name="role" rules={[{ required: true, message: 'Please select Role!' }]}>
          <Select size="large" style={{ width: '100%' }} placeholder="Role">
            {Object.values(Pto.Plugins.SupplierSelectColumnRole).map((item) => (
              <Select.Option key={item}>{item}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Value Type" name="valueType" rules={[{ required: true, message: 'Please select Value Type!' }]}>
          <Select size="large" style={{ width: '100%' }} placeholder="Value Type">
            {valueTypes.map((item) => (
              <Select.Option key={item}>{item}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <div className={styles.buttonGroup}>
          <Button size="large" onClick={onClose}>
            Cancel
          </Button>
          <Button size="large" type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default AddSupplierSelectColumnModal
