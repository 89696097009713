import { toast } from 'react-toastify'
import React, { useRef } from 'react'
import { Button, Form, Input, InputRef } from 'antd'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'

import { authProvider } from 'app/auth/auth-provider'
import { useConversationSignUpMutation } from 'app/api/users-api'

import styles from './Register.module.scss'

type FormProps = {
  email: string
  fullName: string
  password: string
  repeatPassword: string
}

type Props = {
  inviteLink: string
  conversationId: string
  email?: string
  firstName?: string
  lastName?: string
}

const Register = ({ inviteLink, conversationId, email, firstName, lastName }: Props) => {
  const passwordRef = useRef<InputRef>(null)

  const [form] = Form.useForm<FormProps>()

  const [register, { isLoading }] = useConversationSignUpMutation()

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    toast.error(errorInfo?.errorFields?.map((errField) => errField.errors.join('\n')).join('\n'))
  }

  const onFinishHandler = async (values: FormProps) => {
    const { email, fullName, password } = values
    // e.preventDefault()
    // passwordRef.current?.focus()
    if (!isLoading && email && password) {
      await register({ email, fullName, password, inviteLink })
        .unwrap()
        .then((userData) => {
          authProvider.signIn(userData.authData.token, userData.authData.refreshToken)
        })
        .catch((error) => toast.error(error))
    }
  }

  return (
    <div className={styles.container}>
      <Form name="register" layout="vertical" form={form} onFinish={onFinishHandler} onFinishFailed={onFinishFailed} disabled={isLoading} className={styles.form}>
        <div className={styles.header}>
          <h1>Register</h1>
          <div className={styles.logo} />
        </div>

        <Form.Item
          label="Email"
          name="email"
          initialValue={email}
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!'
            },
            {
              required: true,
              message: 'Please input your E-mail!'
            }
          ]}
        >
          <Input autoFocus={true} placeholder="Email" />
        </Form.Item>
        <Form.Item label="Full name" name="fullName" initialValue={[firstName, lastName].filter(Boolean).join(' ')}>
          <Input placeholder="First and last name" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!'
            }
          ]}
          hasFeedback
        >
          <Input.Password ref={passwordRef} placeholder="Password" />
        </Form.Item>

        <Form.Item
          label="Repeat Password"
          name="repeatPassword"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!'
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('The new password that you entered do not match!'))
              }
            })
          ]}
        >
          <Input.Password placeholder="Repeat Password" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" size="large" className={styles.submitButton}>
            Register
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default Register
