import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Form, Space } from 'antd'

import CategoryOptions from './CategoryOptions'
import SubcategoryMultipleOptions from './SubcategoryMultipleOptions'

type Props = {
  name: string
}

const ProductFilters = ({ name }: Props) => {
  return (
    <Form.List name={name} initialValue={[]}>
      {(fields, { add, remove }) => (
        <React.Fragment key="filter">
          {fields.map(({ key, name, ...restField }) => (
            <Space.Compact style={{ width: '100%' }} key={key}>
              <Form.Item {...restField} style={{ flex: 1 }} name={[name, 'category']} rules={[{ required: true, message: 'Missing product category' }]}>
                <CategoryOptions placeholder="Category" />
              </Form.Item>

              <Form.Item noStyle dependencies={[[name, 'category']]}>
                {(formInstance) => {
                  const category = formInstance.getFieldValue([name, 'category'])

                  return (
                    <Form.Item {...restField} style={{ flex: 1 }} name={[name, 'subcategories']}>
                      <SubcategoryMultipleOptions category={category} />
                    </Form.Item>
                  )
                }}
              </Form.Item>

              <Button type="primary" style={{ background: 'red' }} onClick={() => remove(name)}>
                Remove
              </Button>
            </Space.Compact>
          ))}
          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Add filter
            </Button>
          </Form.Item>
        </React.Fragment>
      )}
    </Form.List>
  )
}

export default ProductFilters
