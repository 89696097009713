import { TDocumentDefinitions, Content } from 'pdfmake/interfaces'
import JsBarcode from 'jsbarcode'

type Label = {
  ecomProductName: string
  compositeProductVariant: string
  fulfillmentTaskId: string
  printAreas: string
  quantity: number
  sku: string
  itemDescription: string
  unitCt: number
  barcode: string
  externalTaskItemId: string
}

export const generateBarcode = (labels: Label[]): TDocumentDefinitions => {
  const content: Content[] = labels.map((item, indx) => {
    let canvas = document.createElement('canvas')
    JsBarcode(canvas, item.barcode, { format: 'codabar', displayValue: false, width: 6, height: 62 })
    const barcode = canvas.toDataURL()

    const description = `${item.ecomProductName} ${item.compositeProductVariant} ${item.printAreas}`

    const result: Content = [
      { fontSize: 9, text: description, height: 20 },
      { text: `Quantity: ${item.quantity}, ${item.itemDescription}`, alignment: 'center' },
      { image: barcode, width: 274, alignment: 'center', marginTop: 2 },
      {
        columns: [
          { width: 'auto', text: `ID: ${item.fulfillmentTaskId}`, alignment: 'left' },
          { width: '*', text: `${item.externalTaskItemId} - (${item.barcode})`, alignment: 'center' },
          { width: 'auto', text: `Unit Ct: ${item.unitCt}`, alignment: 'right' }
        ],
        columnGap: 5
      }
    ]

    if (indx !== labels.length - 1) {
      result.push({ text: '', pageBreak: 'after' })
    }

    return result
  })

  return {
    content,
    pageSize: { width: 312, height: 85 },
    pageMargins: [20, 6, 20, 0],
    defaultStyle: {
      fontSize: 10,
      lineHeight: 0.8
    }
  }
}
