import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { fetchSomeData } from './ordersAPI'

export interface CounterState {
  data: any
  status: 'idle' | 'loading' | 'failed'
}

const initialState: CounterState = {
  data: {},
  status: 'idle'
}

export const incrementAsync = createAsyncThunk('orders/fetchData', async (id: number) => {
  const response = await fetchSomeData(id)
  return response.data
})

export const counterSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    dummyAction: (state, action: PayloadAction<number>) => {
      state.data = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(incrementAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(incrementAsync.fulfilled, (state, action) => {
        state.status = 'idle'
        state.data += action.payload
      })
      .addCase(incrementAsync.rejected, (state) => {
        state.status = 'failed'
      })
  }
})

export const { dummyAction } = counterSlice.actions

export const selectData = (state: RootState) => state.orders.data

export default counterSlice.reducer
