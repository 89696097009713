import { IAuthStorage } from './types'

export class InMemoryStorage implements IAuthStorage {
  private _data: any

  constructor(initData = {}) {
    this._data = initData
  }

  getItem(key: string): string | undefined {
    return this._data[key]
  }

  setItem(key: string, value: string) {
    this._data[key] = value
  }

  removeItem(key: string) {
    delete this._data[key]
  }
}

export class SessionStorage implements IAuthStorage {
  getItem(key: string): string | undefined {
    return sessionStorage.getItem(key) ?? undefined
  }

  setItem(key: string, value: string): void {
    sessionStorage.setItem(key, value)
  }

  removeItem(key: string): void {
    sessionStorage.removeItem(key)
  }
}

export class LocalStorage implements IAuthStorage {
  getItem(key: string): string | undefined {
    return localStorage.getItem(key) ?? undefined
  }

  setItem(key: string, value: string): void {
    localStorage.setItem(key, value)
  }

  removeItem(key: string): void {
    localStorage.removeItem(key)
  }
}
