import { createContext, useContext } from 'react'

import { ConversationContextProps } from './types'

export const ConversationContext = createContext<ConversationContextProps | undefined>(undefined)

ConversationContext.displayName = 'ConversationContext'

export const useConversation = (): ConversationContextProps => {
  const context = useContext(ConversationContext)

  if (!context) {
    throw new Error('useConversation must be within ConversationProvider')
  }

  return context
}
