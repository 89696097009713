import { Pto } from '@merchx-v3/pto'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { Button, Input, Modal, Tag, Typography } from 'antd'
import { PresentationAPI } from '@merchx-v3/shared-functions'

import { AvatarLocal } from '../AvatarLocal'
import { SearchProps } from 'antd/es/input/Search'
import { conversationService } from 'app/chat/conversation-service'
import { useConversationsUsersQuery } from 'app/api/conversations-api'

import styles from './AddNewConversationModal.module.scss'

type Props = {
  isOpen: boolean

  onClose: () => void
}

export const AddNewConversationModal = ({ isOpen, onClose }: Props) => {
  const [step, setStep] = useState<'1' | '2'>('1')
  const [chatName, setChatName] = useState<string>('')
  const [isError, setIsError] = useState<boolean>(false)
  const [selectedUsers, setSelectedUsers] = useState<Pto.Conversations.User[]>([])
  const [searchText, setSearchText] = useState('')
  const [isCreating, setIsCreating] = useState<boolean>(false)

  const { data: conversationUsers = [], isLoading } = useConversationsUsersQuery({ searchText }, { skip: step !== '2' })

  const onSearch: SearchProps['onSearch'] = (value) => setSearchText(value)

  const onFinishHandler = async () => {
    setIsCreating(true)

    try {
      await conversationService.createConversation(
        chatName,
        selectedUsers.map((item) => ({ userId: item.id, role: Pto.Conversations.Role.User }))
      )
      resetForm()
      onClose()
    } catch (exception) {
      toast.error(exception as string)
    }

    setIsCreating(false)
  }

  const resetForm = () => {
    setChatName('')
    setSelectedUsers([])
    setIsCreating(false)
    setSearchText('')
    setIsError(false)
    setStep('1')
  }

  useEffect(() => {
    if (isError && chatName !== '') {
      setIsError(false)
    }
  }, [chatName, isError])

  const handleNextButtonClick = () => {
    if (chatName === '') {
      setIsError(true)
    } else {
      setStep('2')
    }
  }

  const handleSelectUser = (user: Pto.Conversations.User) => {
    if (!selectedUsers.includes(user)) {
      setSelectedUsers((prev) => [...prev, user])
    }
    if (selectedUsers.includes(user)) {
      const oldArr = selectedUsers
      const filteredItems = oldArr.filter((item) => user.id !== item.id)
      setSelectedUsers(filteredItems)
    }
  }

  return (
    <Modal open={isOpen} onCancel={onClose} footer={false} title={step === '1' ? 'Create New Chat' : 'Add Members'}>
      <div className={styles.container}>
        {step === '1' && (
          <div className={styles.chatNameInput}>
            <Input
              placeholder="Chat name"
              onChange={(e) => {
                setChatName(e.target.value)
              }}
              status={isError ? 'error' : ''}
            />
            {isError && <div className={styles.error}>Please input Chat Name!</div>}
          </div>
        )}

        {step === '2' && (
          <div className={styles.group}>
            <div className={styles.selectedUsers}>
              {selectedUsers.length > 0 &&
                selectedUsers.map((item) => (
                  <Tag color="green" key={item.id}>
                    {PresentationAPI.getChatUserPreview(item)}
                  </Tag>
                ))}
            </div>
            <Input.Search placeholder="Search user" onSearch={onSearch} className={styles.search} />
            <div className={styles.userList}>
              {conversationUsers.map((item) => {
                return (
                  <div className={styles.userListItem} key={item.id} onClick={() => handleSelectUser(item)}>
                    <AvatarLocal
                      image={item.avatarUrl}
                      name={item.firstName.length ? item.firstName[0].toUpperCase() : item.email[0]?.toUpperCase() || 'C'}
                      online={item.presence === Pto.Conversations.Presence.Online}
                    />
                    <div className={styles.userListItemText}>
                      <Typography>{PresentationAPI.getChatUserPreview(item)}</Typography>
                      <div className={styles.email}>{`@${item.email}`}</div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
        <div className={styles.btnGroup}>
          <Button onClick={onClose}>Cancel</Button>
          {step === '1' && (
            <Button type="primary" onClick={handleNextButtonClick}>
              Next
            </Button>
          )}
          {step === '2' && (
            <Button type="primary" disabled={isLoading} loading={isCreating} onClick={onFinishHandler}>
              Create
            </Button>
          )}
        </div>
      </div>
    </Modal>
  )
}
