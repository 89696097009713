import { useState } from 'react'
import { Select } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { Pto } from '@merchx-v3/pto'
import useDebounce from 'helpers/useDebounce'
import { useEcomProductOptionsQuery } from 'app/api/ecom-products-api'

type Props = {
  defaultValue?: string
  placeholder?: string
  disabled?: boolean
  className?: string
  value?: string
  size?: SizeType

  onChange?: (value?: string) => void
}

const EcomProductOptions = (props: Props) => {
  const { placeholder, disabled, className, value: selectedProduct, size, onChange } = props

  const [searchText, setSearchText] = useState<string>('')
  const debouncedSearchText = useDebounce(searchText, 500)

  const { data: productOptions } = useEcomProductOptionsQuery({ searchText: debouncedSearchText, size: 20 })

  const handleSelect = (_value: string, option: Pto.Option) => {
    onChange && onChange(option.value)
  }

  const handleSearch = (value: string) => {
    setSearchText(value)
  }

  const handleClear = () => {
    onChange && onChange(undefined)
    setSearchText('')
  }

  return (
    <Select
      options={productOptions}
      className={className}
      value={selectedProduct}
      searchValue={searchText}
      disabled={disabled}
      onSelect={handleSelect}
      onSearch={handleSearch}
      placeholder={placeholder}
      size={size}
      allowClear
      optionFilterProp="label"
      showSearch
      onClear={handleClear}
    />
  )
}

export default EcomProductOptions
