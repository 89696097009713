import React from 'react'
import { Select } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { Pto } from '@merchx-v3/pto'
import useDebounce from 'helpers/useDebounce'
import { usePluginOptionsQuery } from 'app/api/plugins-api'

type Props = {
  pluginType: Pto.Plugins.PluginType
  defaultValue?: string
  placeholder?: string
  disabled?: boolean
  className?: string
  value?: Pto.Option
  size?: SizeType
  onChange?: (value?: Pto.Option) => void
}

const PluginOptions = (props: Props) => {
  const { placeholder, disabled, className, value: selectedPlugin, pluginType, size, onChange } = props

  const [searchText, setSearchText] = React.useState<string>('')
  const debouncedSearchText = useDebounce(searchText, 500)

  const { data: pluginOptions } = usePluginOptionsQuery({ searchText: debouncedSearchText, pluginType, size: 20 })

  const handleSelect = (_value: string, option: Pto.Option) => {
    onChange && onChange(option)
  }

  const handleSearch = (value: string) => {
    setSearchText(value)
  }

  const handleClear = () => {
    onChange && onChange(undefined)
    setSearchText('')
  }

  return (
    <Select
      options={pluginOptions}
      className={className}
      value={selectedPlugin?.value}
      searchValue={searchText}
      disabled={disabled}
      onSelect={handleSelect}
      onSearch={handleSearch}
      showSearch
      filterOption={false}
      placeholder={placeholder}
      size={size}
      allowClear
      onClear={handleClear}
    />
  )
}

export default PluginOptions
