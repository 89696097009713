import { useRef } from 'react'
import { toast } from 'react-toastify'
import { Button, Form, Input, InputRef } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'

import { NotFoundPage } from 'components'
import { useResetPasswordMutation } from 'app/api/users-api'

import styles from './ResetPassword.module.scss'

type FormProps = {
  password: string
  repeatPassword: string
}

type Props = {}

export const SetNewPassword = (_props: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const token = new URLSearchParams(location.search).get('token') || undefined

  const passwordRef = useRef<InputRef>(null)

  const [form] = Form.useForm<FormProps>()

  const [resetPassword, { isLoading }] = useResetPasswordMutation()

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    toast.error(errorInfo?.errorFields?.map((errField) => errField.errors.join('\n')).join('\n'))
  }

  const onFinishHandler = async (values: FormProps) => {
    const { password } = values
    if (!isLoading && token && password) {
      await resetPassword({ newPassword: password, resetPasswordToken: token })
        .unwrap()
        .then(() => {
          toast.success('Password was set successfully')
          navigate('/login')
        })
        .catch((error) => toast.error(error))
    }
  }

  if (!token) return <NotFoundPage />

  return (
    <div className={styles.container}>
      <Form name="register" layout="vertical" form={form} onFinish={onFinishHandler} onFinishFailed={onFinishFailed} disabled={isLoading} className={styles.confirmResetContent}>
        <div className={styles.header}>
          <h1>Set new password</h1>
          <div className={styles.logo} />
        </div>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!'
            }
          ]}
          hasFeedback
        >
          <Input.Password ref={passwordRef} placeholder="Password" />
        </Form.Item>

        <Form.Item
          label="Repeat Password"
          name="repeatPassword"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!'
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('The new password that you entered do not match!'))
              }
            })
          ]}
        >
          <Input.Password placeholder="Repeat Password" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" size="large" className={styles.submitButton}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
