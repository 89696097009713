import { CSS } from '@dnd-kit/utilities'
import type { UploadFile } from 'antd/es/upload/interface'
import { useSortable } from '@dnd-kit/sortable'
import styles from './DraggableUploadListItem.module.scss'

type DraggableUploadListItemProps = {
  originNode: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  file: UploadFile<any>
}

const DraggableUploadListItem = ({ originNode, file }: DraggableUploadListItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: file.uid
  })

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'move'
  }

  return (
    <div ref={setNodeRef} style={style} className={styles.picture} {...attributes} {...listeners}>
      {/* hide error tooltip when dragging */}
      {file.status === 'error' && isDragging ? originNode.props.children : originNode}
    </div>
  )
}

export default DraggableUploadListItem
