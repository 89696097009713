import React from 'react'
import { Pto, Role } from '@merchx-v3/pto'
import { useUser } from 'app/auth'

type Props = { skipClaimCheck?: boolean }

const RequireClaim = <T extends { disabled?: boolean }, R>(WrappedComponent: React.FunctionComponent<T>, requestedClaim: Pto.Auth.Claim) => {
  return React.forwardRef<R, T & Props>((props, ref) => {
    const [user] = useUser()

    const doesClaimPresent =
      user?.roles.includes(Role.SystemAdmin) ||
      user?.claims.some((claim) => claim.claim === requestedClaim && [Pto.Auth.AccessLevel.AllowAll, Pto.Auth.AccessLevel.LimitedAccess].includes(claim.accessLevel))

    return <WrappedComponent {...props as T} ref={ref} disabled={props.disabled || (!doesClaimPresent && !props.skipClaimCheck)} />
  })
}

export default RequireClaim
