import { Pto } from '@merchx-v3/pto'
import { DataNode } from 'antd/es/tree'
import { FormClaims } from '../../types'
import ClaimTreeArgs from './ClaimTreeArgs'

export class TreeManager {
  private static sourceClaims = {
    Designer: Pto.Auth.RoleClaims.DesignerClaims,
    Supplier: Pto.Auth.RoleClaims.SupplierClaims,
    FulfillmentFeature: Pto.Auth.ClaimGroups.ProjectClaims,
    Manager: Pto.Auth.RoleClaims.ManagerClaims
  }

  static getTree(): DataNode[] {
    const tree: DataNode[] = []

    Object.entries(TreeManager.sourceClaims).forEach(([role, claims]) => {
      const data: DataNode = {
        key: role,
        title: role,
        children: []
      }

      Object.values(claims).forEach((claim) => {
        data.children!.push({
          key: [role, claim].join('/'),
          title: <ClaimTreeArgs title={claim} claim={claim} />
        })
      })

      tree.push(data)
    })

    return tree
  }

  static getCheckedKeys(value: FormClaims): string[] {
    const checkedClaims = Object.entries(value)
      .filter(([_, value]) => value.checked)
      .map(([claim]) => claim)

    const checkedKeys: string[] = []

    for (const claim of checkedClaims) {
      Object.entries(TreeManager.sourceClaims).forEach(([role, claims]) => {
        if (Object.values(claims).includes(claim as Pto.Auth.Claim)) {
          checkedKeys.push([role, claim].join('/'))
        }
      })
    }

    return checkedKeys
  }

  static extractClaimFromTreeKey(key: React.Key): Pto.Auth.Claim {
    return key.toString().split('/')[1] as Pto.Auth.Claim
  }
}
