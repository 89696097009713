import { Avatar } from '@chatscope/chat-ui-kit-react'
import styles from './AvatarLocal.module.scss'

type Props = {
  image?: any
  name: string
  online: boolean
}

export const AvatarLocal = ({ image, name, online = false }: Props) => {
  return (
    <>
      {image ? (
        <Avatar src={image} status={online ? 'available' : 'unavailable'} />
      ) : (
        <div className={`${styles.avatar} cs-avatar`}>
          {name}
          {<span className={online ? styles.indicatorOnline : styles.indicatorOffline} />}
        </div>
      )}
    </>
  )
}
