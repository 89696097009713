import { Spin } from 'antd'
import { useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { FloatChat } from 'interfaces/sharedComponents/Chat'
import { Router as AdminRouter } from 'interfaces/admin/components'
import { Router as CommonRouter } from 'interfaces/common/components'
import { Router as SupplierRouter } from 'interfaces/suppliers/components'
import { Router as CustomersRouter } from 'interfaces/customers/components'
import { Router as DesignersRouter } from 'interfaces/designers/components'
import { Router as ManagersRouter } from 'interfaces/managers/components'

import SharedRouter from '../Router'

import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css'
import './App.scss'
import { ConversationProvider } from 'app/chat/context/provider'
import { conversationService } from 'app/chat/conversation-service'
import { conversationStorage } from 'app/chat/conversation-storage'
import { authProvider } from 'app/auth/auth-provider'
import { AuthProviderStateChanged } from 'app/auth/auth-provider.types'
import { SagaV2Modal } from 'interfaces/sharedComponents'

type Props = {}

export const PortalContext = React.createContext({
  portal: '',
  setPortal: (portal: string) => {}
})

// По какой-то причине апп маунтится дважды, будем подключать сокет только на втором маунте
const App = (props: Props) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(authProvider.state !== 'READY')
  const [portal, setPortal] = useState('')

  useEffect(() => {
    const onAuthProviderStateChanged = (event: AuthProviderStateChanged) => {
      setIsLoading(authProvider.state !== 'READY')
      dispatch({ type: 'socket/connect' })
    }

    authProvider.addEventListener('state-changed', onAuthProviderStateChanged)

    return () => {
      dispatch({ type: 'socket/disconnect' })
      setIsLoading(authProvider.state !== 'READY')
      authProvider.addEventListener('state-changed', onAuthProviderStateChanged)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading)
    return (
      <div className="spinner-centered">
        <Spin />
      </div>
    )

  return (
    <>
      <ConversationProvider
        serviceFactory={conversationService.factory}
        storage={conversationStorage}
        config={{
          typingThrottleTime: 900,
          typingDebounceTime: 900,
          debounceTyping: true
        }}
      >
        <PortalContext.Provider value={{ portal, setPortal }}>
          <BrowserRouter>
            <Routes>
              <Route path="/*" element={<SharedRouter />} />
              <Route path="admin/*" element={<AdminRouter />} />
              <Route path="customers/*" element={<CustomersRouter />} />
              <Route path="suppliers/*" element={<SupplierRouter />} />
              <Route path="designers/*" element={<DesignersRouter />} />
              <Route path="managers/*" element={<ManagersRouter />} />
              <Route path="common/*" element={<CommonRouter />} />
            </Routes>
            <FloatChat />
            <SagaV2Modal />
          </BrowserRouter>
        </PortalContext.Provider>
      </ConversationProvider>
    </>
  )
}

export default App
