import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { WebSocket } from '@merchx-v3/web-socket'
import { Pto } from '@merchx-v3/pto'

import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { store } from 'app/store'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

type CreateProductArgs = {
  createProductDto: Pto.SupplierProducts.CreateSupplierProduct
}

type UpdateProductArgs = {
  updateProductDto: Pto.SupplierProducts.UpdateSupplierProduct
}

export const supplierProductsApi = createApi({
  reducerPath: 'supplierProductsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['SupplierProducts', 'SupplierProductsCategories', 'SupplierProductsSubcategories'],

  endpoints: (builder) => ({
    supplierProduct: builder.query<Pto.SupplierProducts.SupplierProduct, string>({
      query: (productId) => `/supplier-products/${productId}`,
      providesTags: (_result, _error, productId) => getProvidedTags('SupplierProducts', productId)
    }),
    supplierProductList: builder.query<Pto.SupplierProducts.List, Pto.SupplierProducts.SupplierProductListQuery>({
      query: ({ searchText, page, size }) => ({
        url: 'supplier-products',
        params: { searchText, page, size }
      }),
      providesTags: (supplierProductListData, _error, _args) =>
        getProvidedTags(
          'SupplierProducts',
          'LIST',
          supplierProductListData?.items.map((item) => item.id)
        )
    }),
    createSupplierProduct: builder.mutation<string, CreateProductArgs>({
      query: ({ createProductDto }) => {
        return {
          url: '/supplier-products',
          body: createProductDto,
          method: 'POST',
          responseHandler: 'text'
        }
      },
      invalidatesTags: [{ type: 'SupplierProducts', id: 'LIST' }]
    }),
    updateSupplierProduct: builder.mutation<void, UpdateProductArgs>({
      query: ({ updateProductDto }) => {
        return {
          url: '/supplier-products/update',
          body: updateProductDto,
          method: 'POST'
        }
      },
      invalidatesTags: [{ type: 'SupplierProducts', id: 'LIST' }]
    }),
    categoriesOptions: builder.query<Pto.Option[], Pto.SupplierProducts.SupplierProductCategoriesQuery>({
      query: ({ searchText, size }) => ({
        url: '/supplier-products/categories',
        params: { searchText, size }
      }),
      providesTags: (categoriesOptionsData, _error, _args) =>
        getProvidedTags(
          'SupplierProductsCategories',
          'OPTIONS',
          categoriesOptionsData?.map((item) => item.value)
        )
    }),
    subcategoriesOptions: builder.query<Pto.Option[], Pto.SupplierProducts.SupplierProductSubcategoriesQuery>({
      query: ({ category, searchText, size }) => ({
        url: '/supplier-products/subcategories',
        params: { category, searchText, size }
      }),
      providesTags: (subcategoriesOptionsData, _error, _args) =>
        getProvidedTags(
          'SupplierProductsSubcategories',
          'OPTIONS',
          subcategoriesOptionsData?.map((item) => item.value)
        )
    }),
    supplierProductOptions: builder.query<Pto.Option[], Pto.SupplierProducts.SupplierProductOptionsQuery>({
      query: ({ searchText, size }) => ({
        url: '/supplier-products/options',
        params: { searchText, size }
      }),
      providesTags: (supplierProductOptionsData, _error, _args) =>
        getProvidedTags(
          'SupplierProducts',
          'OPTIONS',
          supplierProductOptionsData?.map((item) => item.value)
        )
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Supplier product') {
    store.dispatch(supplierProductsApi.util.invalidateTags([{ type: 'SupplierProducts', id: payload.entityId }]))
  }
}

export const subscribeToSupplierProductEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromSupplierProductEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const {
  useSupplierProductQuery,
  useLazySupplierProductQuery,
  useSupplierProductListQuery,
  useCreateSupplierProductMutation,
  useUpdateSupplierProductMutation,
  useCategoriesOptionsQuery,
  useSubcategoriesOptionsQuery,
  useSupplierProductOptionsQuery,
  useLazySupplierProductOptionsQuery
} = supplierProductsApi
