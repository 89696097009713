import { Pto } from '@merchx-v3/pto'
import { toast } from 'react-toastify'
import { Modal, Typography } from 'antd'

import { useManualVerifyEmailMutation } from 'app/api/users-api'

type Props = {
  user: Pto.Users.ListItem
  isModalOpened: boolean
  onClose: () => void
}

const ConfirmUserEmailModal = ({ user, isModalOpened, onClose }: Props) => {
  const [manualVerifyEmail, { isLoading }] = useManualVerifyEmailMutation()

  const handleConfirmEmail = async () => {
    manualVerifyEmail(user.id)
      .unwrap()
      .then(() => {
        toast.success('User email was confirmed successfully!')
        onClose()
      })
      .catch((error) => toast.error(error))
  }

  return (
    <Modal
      open={isModalOpened}
      onCancel={onClose}
      centered
      title="Confirm User Email"
      onOk={handleConfirmEmail}
      okButtonProps={{ loading: isLoading }}
      cancelButtonProps={{ loading: isLoading }}
    >
      <Typography style={{ fontWeight: 'bold', marginBottom: 8 }}>This email can be not real</Typography>
    </Modal>
  )
}

export default ConfirmUserEmailModal
