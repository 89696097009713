import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import { Avatar, Badge, Dropdown, Menu, MenuProps } from 'antd'
import { CommentOutlined, TeamOutlined, LogoutOutlined, SkinOutlined, ProjectOutlined, SettingOutlined, UserOutlined, PictureOutlined } from '@ant-design/icons'

import { useUser } from 'app/auth'
import { authProvider } from 'app/auth/auth-provider'
import { useConversation } from 'app/chat/context/context'

import { AppRoutes } from '../Router'
import { connector, PropsFromRedux } from './container'

import styles from './SiderMenuManagers.module.scss'

const items: MenuProps['items'] = [
  {
    key: 'settings',
    label: <Link to={`./${AppRoutes.profile}`}>Profile</Link>
  },
  {
    key: 'switchInterface',
    label: <Link to="/">Switch interface</Link>
  }
]

type Props = PropsFromRedux & {
  collapsed: boolean
}

type MenuItem = Required<MenuProps>['items'][number]

const getItem = (label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem => {
  return {
    key,
    icon,
    children,
    label
  } as MenuItem
}

const iconStyle = { fontSize: 20, color: 'rgba(255, 255, 255, 0.85)' }

let menuItems: MenuItem[] = [
  getItem('Customers', AppRoutes.customers, <TeamOutlined style={iconStyle} />, [
    getItem(<Link to={`./${AppRoutes.customers}`}>List</Link>, AppRoutes.customers),
    getItem(<Link to={`./${AppRoutes.customerReport}`}>Report</Link>, AppRoutes.customerReport)
  ]),
  getItem(<Link to={`./${AppRoutes.ecomProducts}`}>Ecom Products</Link>, AppRoutes.ecomProducts, <SkinOutlined style={iconStyle} />),
  getItem(<Link to={`./${AppRoutes.designs}`}>Designs</Link>, AppRoutes.designs, <SkinOutlined style={iconStyle} />),
  getItem(<Link to={`./${AppRoutes.designRequest}`}>Design Requests</Link>, AppRoutes.designRequest, <PictureOutlined style={iconStyle} />),
  getItem(<Link to={`./${AppRoutes.projects}`}>Projects</Link>, AppRoutes.projects, <ProjectOutlined style={iconStyle} />),
  getItem(<Link to={`./${AppRoutes.chats}`}>Chats</Link>, AppRoutes.chats, <CommentOutlined style={iconStyle} />)
]

const SiderMenuManagers = ({ collapsed, appVersion }: Props) => {
  const location = useLocation()
  const [user] = useUser()

  const { totalUnreadMessages } = useConversation()

  return (
    <div className={styles.container}>
      <div className={styles.upper}>
        <div className={styles.userSection}>
          <div className={styles.avatar}>
            <Badge count={totalUnreadMessages}>
              <Avatar
                size={40}
                style={{ backgroundColor: '#87d068', border: 0 }}
                src={`/images/user-avatars/${user?.id || ''}?width=200&height=200`}
                shape="square"
                icon={<UserOutlined />}
              />
            </Badge>
          </div>
          {!collapsed && (
            <div className={styles.userInfo}>
              <div className={styles.textWrapper}>
                <div className={styles.name}>{`${user?.firstName || ''} ${user?.lastName || ''}`}</div>
                <div className={styles.email}>{user?.email}</div>
              </div>

              <Dropdown menu={{ items }} placement="bottomLeft">
                <SettingOutlined style={iconStyle} />
              </Dropdown>
            </div>
          )}
        </div>
        <Menu selectedKeys={[location.pathname]} mode="inline" theme="dark" items={menuItems} />
      </div>

      <div className={styles.logoutBox}>
        <div className={styles.logout} onClick={() => authProvider.signOut()}>
          <LogoutOutlined style={{ color: 'rgba(255, 255, 255, 0.85)' }} />
          {!collapsed && <p className={styles.logoutText}>Logout</p>}
        </div>
        {!collapsed && <p className={styles.version}> Version {appVersion}</p>}
      </div>
    </div>
  )
}

export default connector(SiderMenuManagers)
