import { useMemo } from 'react'
import { Subject, asyncScheduler } from 'rxjs'
import { throttleTime } from 'rxjs/operators'

import { IConversationService } from '../conversation-service'
import { SendTypingServiceParams } from '../types'

export const useThrottledSendTyping = (service: IConversationService, time: number): ((params: SendTypingServiceParams) => void) =>
  useMemo(() => {
    const subject = new Subject<SendTypingServiceParams>()
    subject.pipe(throttleTime(time, asyncScheduler, { leading: true, trailing: true })).subscribe((params) => {
      service.sendTyping(params)
    })

    return (params: SendTypingServiceParams): void => subject.next(params)
  }, [time])
