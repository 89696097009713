import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { IConversationStorage } from '../conversation-storage'
import { ConversationId, UserId } from '../types'

interface TypingDebounceParams {
  conversationId: ConversationId
  userId: UserId
}

export class TypingDebouncer {
  private readonly _duration: number
  private readonly _storage: IConversationStorage
  private readonly _updateState?: () => void

  private _debouncers: Map<ConversationId, Map<UserId, Subject<TypingDebounceParams>>> = new Map<ConversationId, Map<UserId, Subject<TypingDebounceParams>>>()

  constructor(storage: IConversationStorage, updateState?: () => void, duration: number = 900) {
    this._duration = duration
    this._storage = storage
    this._updateState = updateState
  }

  debounce(conversationId: ConversationId, userId: UserId) {
    const conversationItem = this._debouncers.get(conversationId)

    if (conversationItem) {
      // Conversation exists - searching for user

      const userItem = conversationItem.get(userId)

      if (userItem) {
        // User found - debounce

        userItem.next({ conversationId, userId })
      } else {
        // User not found - create a debouncer

        const subject = this.createDebouncer()

        conversationItem.set(userId, subject)
      }
    } else {
      // Conversation not found - create new and a deboucer

      const subject = this.createDebouncer()

      const newEntry = new Map()
      newEntry.set(userId, subject)
      this._debouncers.set(conversationId, newEntry)
      subject.next({ conversationId, userId })
    }
  }

  private createDebouncer() {
    const subject = new Subject<TypingDebounceParams>()
    subject.pipe(debounceTime(this._duration)).subscribe(({ conversationId, userId }) => {
      // Stop subject
      subject.complete()

      // Remove debouncer from collection
      const conversationItem = this._debouncers.get(conversationId)

      if (conversationItem) {
        conversationItem.delete(userId)
        // Cleanup. Remove conversation if it doesn't contain any users
        if (conversationItem.size === 0) {
          this._debouncers.delete(conversationId)
        }
      }

      // Remove typing user from conversation
      const conversation = this._storage.getConversation(conversationId)
      if (conversation) {
        const idx = conversation.typingUsers.findIndex((item) => item === userId)
        if (idx > -1) {
          conversation.typingUsers = conversation.typingUsers.slice(0, idx).concat(conversation.typingUsers.slice(idx + 1))
          this._updateState && this._updateState()
        }
      }
    })

    return subject
  }
}
