enum AppRoutes {
  // ADMIN ROUTES

  dashboard = '/',
  customers = 'customers',
  customerReport = 'customers/report',
  supplierProducts = 'supplier-products',
  ecomProducts = 'ecom-products',
  projects = 'projects',
  fulfillment = 'fulfillment',
  designRequest = 'design-requests',
  design = 'designs',
  invoices = 'invoices',
  shipping = 'shipping',
  settings = 'settings',
  designTemplates = 'design-templates',
  profile = 'profile',
  processingTemplates = 'processing-templates'
}

export default AppRoutes
