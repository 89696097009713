import { useState } from 'react'
import { AutoComplete } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import useDebounce from 'helpers/useDebounce'
import { CustomerOption } from 'shared/types'
import { useCustomerOptionsQuery } from 'app/api/customers-api'

type Props = {
  defaultValue?: string
  placeholder?: string
  disabled?: boolean
  className?: string
  value?: CustomerOption
  size?: SizeType

  onChange?: (value?: CustomerOption) => void
}

const CustomerOptions = (props: Props) => {
  const { placeholder, disabled, className, value: selectedCustomer, size, onChange } = props

  const [searchText, setSearchText] = useState<string>('')
  const debouncedSearchText = useDebounce(searchText, 500)

  const { data: customerOptions } = useCustomerOptionsQuery({ searchText: debouncedSearchText })

  const handleSelect = (_value: string, option: CustomerOption) => {
    onChange && onChange(option)
  }

  const handleSearch = (value: string) => {
    setSearchText(value)
  }

  const handleClear = () => {
    onChange && onChange(undefined)
    setSearchText('')
  }

  return (
    <AutoComplete
      options={customerOptions?.map((customer) => ({ ...customer, value: customer.id, label: `${customer.firstName} ${customer.lastName} (${customer.email})` } as CustomerOption))}
      className={className}
      value={selectedCustomer?.value}
      searchValue={searchText}
      disabled={disabled}
      onSelect={handleSelect}
      onSearch={handleSearch}
      placeholder={placeholder}
      size={size}
      allowClear
      onClear={handleClear}
    />
  )
}

export default CustomerOptions
