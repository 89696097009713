import { useRef } from 'react'
import { CheckCircleOutlined, FileDoneOutlined, PictureOutlined, VideoCameraOutlined } from '@ant-design/icons'
import { useHandleClickOutside } from 'helpers'
import styles from './AttachmentMenu.module.scss'

type Props = {
  isDesignerUser?: boolean
  onClose: () => void
  onImageClicked?: () => void
  onVideoClicked?: () => void
  onDocumentClicked?: () => void
  onDesignApproveClicked?: () => void
}

export const AttachmentMenu = ({ isDesignerUser = false, onClose, onImageClicked, onVideoClicked, onDocumentClicked, onDesignApproveClicked }: Props) => {
  const clickOutsideRef = useRef(null)
  useHandleClickOutside(clickOutsideRef, onClose)

  return (
    <div className={styles.attachmentMenu} ref={clickOutsideRef}>
      <div className={styles.menuItem} onClick={onImageClicked}>
        <PictureOutlined />
        Image
      </div>
      {/* <div className={styles.menuItem} onClick={onVideoClicked}>
        <VideoCameraOutlined />
        Video
      </div>
      <div className={styles.menuItem} onClick={onDocumentClicked}>
        <FileDoneOutlined />
        Document
      </div>
      {isDesignerUser && (
        <div className={styles.menuItem} onClick={onDesignApproveClicked}>
          <CheckCircleOutlined />
          Design Approve
        </div>
      )} */}
    </div>
  )
}
