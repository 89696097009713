import { Button, Result } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from './NotFoundPage.module.scss'

const NotFoundPage = () => {
  let location = useLocation()
  let navigate = useNavigate()

  return (
    <div id="error-page" className={styles.container}>
      <Result
        status="404"
        title="404"
        subTitle={`Sorry, the page "${location.pathname}" does not exist.`}
        extra={
          <Button type="primary" onClick={() => navigate('/')}>
            Back Home
          </Button>
        }
      />
    </div>
  )
}

export default NotFoundPage
