import { forwardRef, useImperativeHandle, useState } from 'react'
import { toast } from 'react-toastify'
import { Pto } from '@merchx-v3/pto'
import { Switch } from 'antd'
import { FieldComponentProps } from '../types'
import ErrorContainer from '../ErrorContainer/ErrorContainer'

type RefProps = {
  validate: () => boolean
}

const BooleanField = forwardRef<RefProps, FieldComponentProps<Pto.Plugins.ValueType.Boolean>>(
  ({ field, onChange, targetId, pluginInfo: _pluginInfo, supplierId: _supplierId, ...props }, forwardedRef) => {
    const [isShowError, setIsShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useImperativeHandle(forwardedRef, () => ({
      validate: () => {
        if (field.value === undefined && field.isRequired) {
          toast.error(`Boolean field ${field.displayName} for ${targetId} should be either true or false`)
          setIsShowError(true)
          setErrorMessage(`Boolean field ${field.displayName} for ${targetId} should be either true or false`)
          return false
        }

        return true
      }
    }))

    const handleChange = (value: boolean) => {
      onChange(value)
      setErrorMessage('')
      setIsShowError(false)
    }

    return (
      <ErrorContainer isShowError={isShowError} errorMessage={errorMessage}>
        <Switch disabled={props.disabled} checked={field.value} onChange={handleChange} />
      </ErrorContainer>
    )
  }
)

export default BooleanField
