import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Pto } from '@merchx-v3/pto'
import { WebSocket } from '@merchx-v3/web-socket'
import { Plugins } from '@merchx-v3/plugins'

import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { store } from 'app/store'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

type GetProductFieldsArgs = {
  supplierId: string
  productId: string
  pluginId: string
  category?: string
  subcategory?: string
}

type GetShippingSettingFieldsArgs = {
  supplierId: string
  pluginId: string
  shippingSettingId: string
}

type GetShippingTaskFieldsArgs = {
  supplierId: string
  pluginId: string
  shippingTaskId: string
}

type GetProjectFieldsArgs = {
  projectId: string
}

type GetSupplierSelectOptionsArgs = {
  supplierSelectId: string
  pluginId: string
  supplierId: string
  searchText?: string
}

type GetDependencyFieldOptionsArgs = {
  field: Pto.Plugins.Fields.IField<Pto.Plugins.ValueType.Dependency>
  targetField: string
  pluginId: string
  supplierId: string
}

type GetSupplierPluginOptionsArgs = {
  supplierId: string
  category?: string
  subcategory?: string
  pluginType?: Pto.Plugins.PluginType
}

type GetSupplierPluginListArgs = {
  supplierId: string
  page?: number
  pageSize?: number
  pluginType?: Pto.Plugins.PluginType
}

type GetSupplierFulfillmentPluginArgs = {
  supplierId: string
  pluginId: string
  pluginType: Pto.Plugins.PluginType
}

type SaveTargetFieldsValues = {
  supplierId: string
  pluginId: string
  pluginType: Pto.Plugins.PluginType
  targetFieldsValues: Pto.Plugins.Fields.KeyFields[]
}

type GetSupplierSelectRecordListArgs = {
  supplierSelectId: string
  supplierId: string
  page?: number
  size?: number
  searchText?: string
}

type GetPluginList = {
  searchText: string
  size: number
  page?: number
}

type GetPluginOptions = {
  pluginType: Pto.Plugins.PluginType
  searchText: string
  size?: number
  page?: number
}

// type UploadPluginArtworkArgs = {
//   file: UploadFile<RcFile>
//   uploadArtwork: Pto.Images.UploadArtwork
// }

export const pluginsApiV2 = createApi({
  reducerPath: 'pluginsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['Plugins'],

  endpoints: (builder) => ({
    shippingPluginList: builder.query<Pto.Plugins.List, GetPluginList>({
      query: ({ searchText, page, size }) => ({
        url: 'plugins/metadata/shipping',
        params: { searchText, page, size }
      }),
      providesTags: (shippingPluginListData, _error, _args) =>
        getProvidedTags(
          'Plugins',
          'SHIPPING_LIST',
          shippingPluginListData?.items.map((item) => item.id)
        )
    }),
    pluginMeta: builder.query<Pto.Plugins.IPluginMeta, string>({
      query: (pluginId) => ({
        url: `plugins/metadata/${pluginId}`
      }),
      providesTags: (_result, _error, pluginId) => getProvidedTags('Plugins', pluginId)
    }),
    pluginOptions: builder.query<Pto.Option[], GetPluginOptions>({
      query: ({ searchText, size, page, pluginType }) => ({
        url: `plugins/metadata/options`,
        params: { searchText, size, page, pluginType }
      }),
      providesTags: (_result, _error, _args) => getProvidedTags('Plugins', 'OPTIONS')
    }),
    // TODO - удалить после миграции на саплаер продукты
    fulfillmentProductPluginFields: builder.query<Pto.Plugins.ProductPluginFields, GetProductFieldsArgs>({
      query: ({ supplierId, pluginId, productId, category, subcategory }) => ({
        url: `plugins/supplier-plugins/${supplierId}/fulfillment/${pluginId}/product/${productId}`,
        params: { category, subcategory }
      }),
      providesTags: (_result, _error, _args) => getProvidedTags('Plugins', 'PRODUCT_LIST')
    }),
    supplierProductPluginFields: builder.query<Pto.Plugins.ProductPluginFields, GetProductFieldsArgs>({
      query: ({ supplierId, pluginId, productId, category, subcategory }) => ({
        url: `plugins/supplier-plugins/${supplierId}/fulfillment/${pluginId}/supplier-product/${productId}`,
        params: { category, subcategory }
      }),
      providesTags: (_result, _error, _args) => getProvidedTags('Plugins', 'PRODUCT_LIST')
    }),
    fulfillmentProjectPluginFields: builder.query<Pto.Plugins.Fields.KeyFields[], GetProjectFieldsArgs>({
      query: ({ projectId }) => ({
        url: `plugins/supplier-plugins/fulfillment/project/${projectId}`
      }),
      providesTags: (_result, _error, _args) => getProvidedTags('Plugins', 'PROJECT_LIST')
    }),
    supplierSelectOptions: builder.query<Pto.Option[], GetSupplierSelectOptionsArgs>({
      query: ({ supplierSelectId, pluginId, supplierId, searchText = '' }) => ({
        url: '/plugins/supplier-selects/options',
        params: { supplierSelectId, pluginId, supplierId, searchText }
      }),
      providesTags: (_result, _error, _args) => getProvidedTags('Plugins', 'OPTIONS')
    }),
    dependencyFieldOptions: builder.query<Pto.Option[], GetDependencyFieldOptionsArgs>({
      query: ({ supplierId, pluginId, ...body }) => ({
        url: `/plugins/supplier-plugins/${supplierId}/${pluginId}/get-dependency-options`,
        body,
        method: 'POST'
      }),
      providesTags: (_result, _error, _args) => getProvidedTags('Plugins', 'OPTIONS')
    }),
    supplierPluginOptions: builder.query<Pto.Option[], GetSupplierPluginOptionsArgs>({
      query: ({ supplierId, category, subcategory, pluginType }) => ({
        url: `plugins/supplier-plugins/${supplierId}/options`,
        params: { category, subcategory, pluginType }
      }),
      providesTags: (_result, _error, _args) => getProvidedTags('Plugins', 'OPTIONS')
    }),
    supplierPluginList: builder.query<Pto.Plugins.SupplierPluginList, GetSupplierPluginListArgs>({
      query: ({ supplierId, page, pageSize, pluginType }) => ({
        url: `plugins/supplier-plugins/${supplierId}`,
        params: { pageSize, page, pluginType }
      }),
      providesTags: (_result, _error, _args) => getProvidedTags('Plugins', 'SUPPLIER_LIST')
    }),
    supplierPlugin: builder.query<Pto.Plugins.ISupplierPlugin, GetSupplierFulfillmentPluginArgs>({
      query: ({ supplierId, pluginId, pluginType }) => ({
        url: `plugins/supplier-plugins/${supplierId}/${pluginType}/${pluginId}`
      }),
      providesTags: (_result, _error, { pluginId }) => getProvidedTags('Plugins', 'SUPPLIER_PLUGIN', [pluginId])
    }),
    supplierSelectRecordList: builder.query<Pto.Plugins.SupplierSelectObject[], GetSupplierSelectRecordListArgs>({
      query: ({ supplierSelectId, supplierId, page, size, searchText }) => ({
        url: 'plugins/supplier-selects',
        params: { supplierSelectId, supplierId, page, size, searchText }
      }),
      providesTags: (_record, _error, _args) => getProvidedTags('Plugins', 'SUPPLIER_SELECT_RECORD_LIST')
    }),
    createSupplierSelectRecord: builder.mutation<void, Plugins.Dto.CreateUpdateSupplierSelectRecord>({
      query: (args) => ({
        url: `plugins/supplier-selects`,
        body: args,
        method: 'POST'
      }),
      invalidatesTags: [{ type: 'Plugins', id: 'SUPPLIER_SELECT_RECORD_LIST' }]
    }),
    updateSupplierSelectRecord: builder.mutation<void, Plugins.Dto.CreateUpdateSupplierSelectRecord>({
      query: (args) => ({
        url: `plugins/supplier-selects/update`,
        body: args,
        method: 'POST'
      }),
      invalidatesTags: [{ type: 'Plugins', id: 'SUPPLIER_SELECT_RECORD_LIST' }]
    }),
    registerSupplierPlugin: builder.mutation<void, Plugins.Dto.CreateUpdateSupplierPlugin>({
      query: (args) => ({
        url: `plugins/supplier-plugins/${args.supplierId}/${args.pluginId}`,
        body: args,
        method: 'POST'
      }),
      invalidatesTags: [
        { type: 'Plugins', id: 'SUPPLIER_LIST' },
        { type: 'Plugins', id: 'SUPPLIER_FULFILLMENT' }
      ]
    }),
    saveTargetFieldsValues: builder.mutation<void, SaveTargetFieldsValues>({
      query: ({ supplierId, pluginId, pluginType, targetFieldsValues }) => ({
        url: `plugins/supplier-plugins/${supplierId}/${pluginType}/${pluginId}`,
        body: targetFieldsValues,
        method: 'POST'
      }),
      invalidatesTags: [
        { type: 'Plugins', id: 'PRODUCT_LIST' },
        { type: 'Plugins', id: 'PROJECT_LIST' }
      ]
    }),
    addPlugin: builder.mutation<void, Plugins.Dto.Metadata.Plugin>({
      query: (data) => ({
        url: 'plugins/metadata',
        body: data,
        method: 'POST'
      })
    }),
    updatePlugin: builder.mutation<void, Plugins.Dto.Metadata.Plugin>({
      query: (data) => ({
        url: 'plugins/metadata/update',
        body: data,
        method: 'POST'
      }),
      invalidatesTags: [{ type: 'Plugins', id: 'META' }]
    }),
    updatePluginSupplierSelect: builder.mutation<void, { pluginId: string; data: Plugins.Dto.Metadata.SupplierSelect }>({
      query: ({ pluginId, data }) => ({
        url: `plugins/metadata/${pluginId}/supplier-select`,
        body: data,
        method: 'POST'
      }),
      invalidatesTags: [{ type: 'Plugins', id: 'META' }]
    }),
    shippingSettingPluginFields: builder.query<Pto.Plugins.ShippingSettingPluginFields, GetShippingSettingFieldsArgs>({
      query: ({ supplierId, pluginId, shippingSettingId }) => ({
        url: `plugins/supplier-plugins/${supplierId}/shipping/${pluginId}/shipping-setting/${shippingSettingId}`
      }),
      providesTags: (_result, _error, { shippingSettingId }) => getProvidedTags('Plugins', 'SHIPPING_SETTING', [shippingSettingId])
    }),
    shippingTaskPluginFields: builder.query<Pto.Plugins.ShippingTaskPluginFields, GetShippingTaskFieldsArgs>({
      query: ({ supplierId, pluginId, shippingTaskId }) => ({
        url: `plugins/supplier-plugins/${supplierId}/shipping/${pluginId}/shipping-task/${shippingTaskId}`
      }),
      providesTags: (_result, _error, _args) => getProvidedTags('Plugins', 'SHIPPING_TASK_LIST')
    }),
    getMaxArtworkFilesLength: builder.query<number, string>({
      query: (projectProductItemId) => ({
        url: `plugins/supplier-plugins/fulfillment/max-artwork-files-length/${projectProductItemId}`
      })
    })
    // uploadPluginArtwork: builder.mutation<string, UploadPluginArtworkArgs>({
    //   query: ({ file, uploadArtwork }) => {
    //     const formData = new FormData()
    //     formData.append('file', file.originFileObj as Blob)
    //     formData.append('printSide', uploadArtwork.printSide)
    //     formData.append('targetId', uploadArtwork.targetId)
    //     formData.append('pluginFieldId', uploadArtwork.pluginFieldId)

    //     return {
    //       url: '/images/upload-artwork',
    //       body: formData,
    //       method: 'POST',
    //       responseHandler: 'text'
    //     }
    //   }
    // })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Plugin') {
    store.dispatch(pluginsApiV2.util.invalidateTags([{ type: 'Plugins', id: payload.entityId }]))
  }
}

export const subscribeToPluginEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromPluginEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const {
  useShippingPluginListQuery,
  usePluginMetaQuery,
  useLazyPluginMetaQuery,
  useFulfillmentProductPluginFieldsQuery,
  useFulfillmentProjectPluginFieldsQuery,
  useSupplierSelectOptionsQuery,
  useSupplierPluginOptionsQuery,
  useDependencyFieldOptionsQuery,
  useSupplierPluginListQuery,
  useSupplierPluginQuery,
  useSupplierProductPluginFieldsQuery,
  usePluginOptionsQuery,
  useSupplierSelectRecordListQuery,
  useCreateSupplierSelectRecordMutation,
  useUpdateSupplierSelectRecordMutation,
  useRegisterSupplierPluginMutation,
  useLazyFulfillmentProjectPluginFieldsQuery,
  useAddPluginMutation,
  useUpdatePluginMutation,
  useUpdatePluginSupplierSelectMutation,
  useSaveTargetFieldsValuesMutation,
  useShippingSettingPluginFieldsQuery,
  useShippingTaskPluginFieldsQuery,
  useGetMaxArtworkFilesLengthQuery
  // useUploadPluginArtworkMutation
} = pluginsApiV2
