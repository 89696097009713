import React from 'react'
import { Form, Input, InputNumber } from 'antd'

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean
  dataIndex: string
  title: any
  inputType: 'number' | 'text'
  index: number
  children: React.ReactNode
  isRequired?: boolean
  precision?: number
  prefix?: string
  hidden?: boolean
  defaultValue?: any
  min?: number
  max?: number
}

const EditableCell: React.FC<EditableCellProps> = ({
  isRequired = true,
  precision = 0,
  min,
  max,
  prefix,
  editing,
  dataIndex,
  title,
  inputType,
  index,
  children,
  hidden,
  defaultValue,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber precision={precision} prefix={prefix} min={min} max={max} /> : <Input prefix={prefix} />

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          hidden={hidden}
          name={dataIndex}
          initialValue={defaultValue}
          style={{ margin: 0 }}
          rules={[
            {
              required: isRequired,
              message: `Please Input ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

export default EditableCell
