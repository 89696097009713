import { useEffect } from 'react'
import { Pto } from '@merchx-v3/pto'
import { Conversation, ConversationList } from '@chatscope/chat-ui-kit-react'

import { AvatarLocal } from '../AvatarLocal'
import { useConversation } from 'app/chat/context/context'
import { conversationService } from 'app/chat/conversation-service'

type Props = {
  defaultConversationId?: string
  height: string
}

const ConversationListComponent = ({ defaultConversationId, height }: Props) => {
  const { isOnline, isConversationsListLoaded, activeConversation, setActiveConversation, conversations } = useConversation()

  useEffect(() => {
    if (isOnline && isConversationsListLoaded && defaultConversationId) {
      void conversationService.loadConversation(defaultConversationId)

      setActiveConversation(defaultConversationId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline, isConversationsListLoaded, defaultConversationId])

  const handleConversationClick = async (conversationId: string) => {
    if (activeConversation?.id === conversationId) {
      setActiveConversation('')
    } else {
      setActiveConversation('')
      await conversationService.loadConversation(conversationId)
      setActiveConversation(conversationId)
    }
  }

  return (
    <ConversationList style={{ height }}>
      {conversations
        .sort((a, b) => (a.lastActivityAt > b.lastActivityAt ? -1 : 1))
        .map((c) => (
          <Conversation
            key={c.id}
            name={c.name}
            info={
              c.draft
                ? `Draft: ${c.draft.replace(/<br>/g, '\n').replace(/&nbsp;/g, ' ')}`
                : c.lastMessage && c.lastMessage.type === Pto.Conversations.MessageContentType.Text
                ? (c.lastMessage.content as unknown as string)
                : 'Attachment'
            }
            active={activeConversation?.id === c.id}
            unreadCnt={c.unreadMessages}
            onClick={() => handleConversationClick(c.id)}
          >
            {/* @ts-expect-error Property AS doesn't exist */}
            <AvatarLocal as="Avatar" image={c.data?.avatar} name={c.data?.name ? c.data.name[0] : 'C'} online={c.hasOnlineUsers} />
          </Conversation>
        ))}
    </ConversationList>
  )
}

export default ConversationListComponent
