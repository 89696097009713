import { forwardRef, useState, useImperativeHandle } from 'react'
import { toast } from 'react-toastify'
import { Select } from 'antd'
import { Pto } from '@merchx-v3/pto'
import { useSupplierSelectOptionsQuery } from 'app/api/plugins-api'
import useDebounce from 'helpers/useDebounce'
import { FieldComponentProps } from '../types'
import ErrorContainer from '../ErrorContainer'

type RefProps = {
  validate: () => boolean
}

const SupplierSelectField = forwardRef<RefProps, FieldComponentProps<Pto.Plugins.ValueType.SupplierSelect>>(
  ({ pluginInfo, onChange, supplierId, field, targetId, ...props }, forwardedRef) => {
    // const [selectOptions, setSelectOptions] = useState<Pto.Option[]>([])
    const [isDropdownOpened, setIsDropdownVisible] = useState<boolean>(false)
    const [isShowError, setIsShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [searchText, setSearchText] = useState<string>('')
    const debouncedSearchText = useDebounce(searchText, 500)

    const { data: selectOptions = [] } = useSupplierSelectOptionsQuery(
      {
        supplierSelectId: field.value.supplierSelectId,
        pluginId: pluginInfo.value,
        supplierId: supplierId!,
        searchText: debouncedSearchText
      },
      { skip: !supplierId || !isDropdownOpened }
    )

    useImperativeHandle(forwardedRef, () => ({
      validate: () => {
        if (!field.isRequired) return true

        if (!field.value) {
          toast.error(`Supplier Select field ${field.displayName} for ${targetId} should be defined`)
          setErrorMessage(`Supplier Select field ${field.displayName} for ${targetId} should be defined`)
          setIsShowError(true)
          return false
        }

        if (!field.value.supplierSelectId) {
          toast.error(`Supplier Select field ${field.displayName} supplier select ID for ${targetId} is empty`)
          setErrorMessage(`Supplier Select field ${field.displayName} supplier select ID for ${targetId} is empty`)
          setIsShowError(true)
          return false
        }

        if (!field.value.recordId) {
          toast.error(`Supplier Select field ${field.displayName} for ${targetId} should be select`)
          setErrorMessage(`Supplier Select field ${field.displayName} for ${targetId} should be select`)
          setIsShowError(true)
          return false
        }

        if (!field.value.recordLabel) {
          toast.error(`Supplier Select field ${field.displayName} record label for ${targetId} is empty`)
          setErrorMessage(`Supplier Select field ${field.displayName} record label for ${targetId} is empty`)
          setIsShowError(true)
          return false
        }

        return true
      }
    }))

    const handleRecordChanged = (recordId: string) => {
      const selectedOption = selectOptions.find((item) => item.value === recordId)
      if (selectedOption) {
        onChange({
          supplierSelectId: field.value.supplierSelectId,
          recordId: selectedOption.value,
          recordLabel: selectedOption.label
        })
        setErrorMessage('')
        setIsShowError(false)
      }
    }

    return (
      <ErrorContainer isShowError={isShowError} errorMessage={errorMessage}>
        <Select
          status={isShowError ? 'error' : undefined}
          style={{ width: '100%' }}
          {...props}
          value={field.value.recordId}
          onSearch={setSearchText}
          onDropdownVisibleChange={(isVisible) => setIsDropdownVisible(isVisible)}
          showSearch
          optionFilterProp="label"
          onChange={handleRecordChanged}
          options={
            selectOptions.length
              ? selectOptions.map((item) => ({ value: item.value, label: item.label }))
              : field.value
              ? [{ value: field.value.recordId, label: field.value.recordLabel }]
              : []
          }
          placeholder={field.displayName}
        />
      </ErrorContainer>
    )
  }
)

export default SupplierSelectField
