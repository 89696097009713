import { Pto } from '@merchx-v3/pto'
import { WebSocket } from '@merchx-v3/web-socket'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { store } from 'app/store'
import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

type GetSagaListQuery = {
  size: number
  page: number
  searchText?: string
  status?: Pto.SagasV2.Status
  startDate?: Date
  endDate?: Date
}

type GetSagaEventQuery = {
  sagaId: string
  eventId: string
}

export const sagasApi = createApi({
  reducerPath: 'sagasApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['Sagas'],

  endpoints: (builder) => ({
    sagaList: builder.query<Pto.SagasV2.List, GetSagaListQuery>({
      query: (params) => ({
        url: '/sagas',
        params
      }),
      providesTags: (sagaListData, _error, _args) =>
        getProvidedTags(
          'Sagas',
          'LIST',
          sagaListData?.items.map((item) => item.id)
        )
    }),
    saga: builder.query<Pto.SagasV2.Saga, string>({
      query: (sagaId) => ({
        url: `/sagas/${sagaId}`
      }),
      providesTags: (_sagaListData, _error, sagaId) => getProvidedTags('Sagas', sagaId)
    }),
    sagaEvent: builder.query<Pto.Event, GetSagaEventQuery>({
      query: ({ sagaId, eventId }) => ({
        url: `/sagas/${sagaId}/event/${eventId}`
      }),
      providesTags: (_sagaListData, _error, args) => getProvidedTags('Sagas', `EVENT_${args.eventId}`)
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Saga') {
    store.dispatch(sagasApi.util.invalidateTags([{ type: 'Sagas', id: payload.entityId }]))
  }
}

export const subscribeToSagaEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromSagaEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const { useSagaListQuery, useSagaQuery, useLazySagaEventQuery } = sagasApi
