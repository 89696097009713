import { Pto } from '@merchx-v3/pto'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'

const { protocol, domain } = settings.site

export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['Notifications'],
  endpoints: (builder) => ({
    userSettings: builder.query<Pto.Notifications.NotificationSetting[], string>({
      query: (userId) => ({
        url: `/notifications/get-notification-settings/${userId}`
      })
    }),
    availableChannels: builder.query<Pto.Notifications.AutoresponderAvailableChannels[], any>({
      query: () => ({
        url: '/notifications/get-autoresponders-available-channels'
      })
    }),
    updateNotificationSettings: builder.mutation<void, Pto.Notifications.UpdateNotificationSettings>({
      query: (body) => ({
        url: '/notifications/update',
        body,
        method: 'PATCH'
      })
    })
  })
})

export const { useUserSettingsQuery, useAvailableChannelsQuery, useUpdateNotificationSettingsMutation } = notificationsApi
