import { Plugins } from '@merchx-v3/plugins'
import { Pto } from '@merchx-v3/pto'
import axios from 'app/axios'

export const fetchFulfillmentPluginList = async (page?: number, size?: number) => {
  const response = await axios.get<Pto.Plugins.List>('/plugins/metadata/fulfillment', { params: { page, size } })
  return response.data
}

export const fetchFulfillmentPluginOptions = async (searchText?: string, page?: number, size?: number) => {
  const response = await axios.get<Pto.Option[]>('/plugins/metadata/fulfillment/options', { params: { searchText, page, size } })
  return response.data
}

export const fetchFulfillmentPlugin = async (fulfillmentPluginId: string) => {
  const respone = await axios.get<Pto.Plugins.IPluginMeta>(`/plugins/metadata/${fulfillmentPluginId}`)
  return respone.data
}

export const fetchProductFields = async (supplierId: string, productId: string, category?: string, subcategory?: string) => {
  let queryUrl = ''
  if (productId) {
    queryUrl += `?productId=${productId}`
  }

  if (category) {
    queryUrl += queryUrl ? `&category=${category}` : `?category=${category}`
  }

  if (subcategory) {
    queryUrl += `&subcategory=${subcategory}`
  }

  const response = await axios.get<any[]>(`/plugins/fields/product/${supplierId}${queryUrl}`)
  return response.data
}

export const fetchProjectProductItemsFields = async (projectProductItems: any[]) => {
  const response = await axios.post<any[]>('plugins/fields/project-product-items/get-fields', projectProductItems)
  return response.data
}

export const fetchProductVariantsFields = async (productVariants: any[]) => {
  const response = await axios.post<any[]>('plugins/fields/product-variants/get-fields', productVariants)
  return response.data
}

export const saveProductFields = async (saveProductFieldsPayload: any[]) => {
  const response = await axios.post<void>('/plugins/fields/product', saveProductFieldsPayload)
  return response.data
}

export const saveProjectProductItemsFields = async (saveProjectProductItemsFieldsPayload: any[]) => {
  const response = await axios.post<void>('/plugins/fields/project-product-items', saveProjectProductItemsFieldsPayload)
  return response.data
}

export const saveProductVariantsFields = async (saveProductVariantsFieldsPayload: any[]) => {
  const response = await axios.post<void>('/plugins/fields/product-variants', saveProductVariantsFieldsPayload)
  return response.data
}

export const fetchTargetSettings = async (pluginId: string, targetId: string) => {
  const response = await axios.get<Pto.Plugins.Fields.ISetting[]>(`/plugins/settings/${pluginId}/${targetId}`)
  return response.data
}

export const registerSupplierFulfillmentPlugin = async (args: any) => {
  const response = await axios.post<boolean>('/plugins/suppliers/fulfillment/register', args)
  return response.data
}

export const saveTargetSettings = async (pluginId: string, supplierId: string, settings: Plugins.Dto.Values.Setting[]) => {
  const response = await axios.post<boolean>(`/plugins/settings/${pluginId}/${supplierId}`, settings)
  return response.data
}

export const fetchSupplierFulfillmentPlugins = async (supplierId: string) => {
  const response = await axios.get<any[]>(`/plugins/suppliers/${supplierId}/fulfillment`)
  return response.data
}

export const fetchSupplierFulfillmentPluginDetails = async (supplierId: string, pluginId: string) => {
  const response = await axios.get<any>(`/plugins/suppliers/${supplierId}/fulfillment/${pluginId}`)
  return response.data
}

export const fetchSupplierSelectRecordList = async (supplierSelectId: string, supplierId: string, page?: number, size?: number, searchText?: string) => {
  const response = await axios.get<any[]>('/plugins/supplier-selects', { params: { supplierSelectId, supplierId, page, size, searchText } })
  return response.data
}

export const createSupplierSelectRecord = async (args: any) => {
  const response = await axios.post<boolean>('/plugins/supplier-selects', args)
  return response.data
}

export const updateSupplierSelectRecord = async (args: any) => {
  const response = await axios.patch<boolean>('/plugins/supplier-selects', args)
  return response.data
}

export const createPluginSupplierSelect = async (args: any) => {
  const response = await axios.post<boolean>('/plugins/metadata/supplier-select', args)
  return response.data
}
