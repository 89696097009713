enum AppRoutes {
  // ADMIN ROUTES

  dashboard = '/',
  customers = 'customers',
  customerReport = 'customers/report',
  chats = 'chats',
  supplierProducts = 'supplier-products',
  ecomProducts = 'ecom-products',
  projects = 'projects',
  invoices = 'invoices',
  payments = 'payments',
  orders = 'orders',
  fulfillment = 'fulfillment',
  designRequest = 'design-requests',
  design = 'designs',
  suppliers = 'suppliers',
  shipping = 'shipping',
  settings = 'settings',
  plugins = 'settings/plugins',
  pluginFulfillmentList = 'settings/plugins/fulfillment-list',
  pluginFulfillment = 'settings/plugins/fulfillment',
  profile = 'profile',
  newPluginFulfillment = 'settings/plugins/fulfillment/new',
  pluginShippingList = 'settings/plugins/shipping-list',
  pluginShipping = 'settings/plugins/shipping',
  sagas = 'sagas',
  newPluginShipping = 'settings/plugins/shipping/new',
  users = 'settings/users',
  createUser = 'settings/users/create'
}

export default AppRoutes
