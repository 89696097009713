import { useEffect, useState } from 'react'

import { AuthProviderStateChanged, UserChangedEvent, UserSignInEvent, UserSignOutEvent } from './auth-provider.types'
import { authProvider } from './auth-provider'
import { Pto } from '@merchx-v3/pto'
import { toast } from 'react-toastify'

export const useUser = () => {
  const [user, setUser] = useState<Pto.Users.User | undefined>(authProvider.getUser())
  const [isLoading, setIsLoading] = useState<boolean>(authProvider.state !== 'READY' && authProvider.state !== 'ERROR')

  useEffect(() => {
    const providerStateChanged = async (event: AuthProviderStateChanged) => {
      console.log('Auth provider: state changed', event.detail)
      const newState = event.detail
      setIsLoading(newState !== 'READY' && newState !== 'ERROR')

      if (newState === 'ERROR') {
        toast.error(authProvider.error)
      }
    }

    const signInListener = async (event: UserSignInEvent) => {
      setUser(event.detail)
    }

    const signOutListener = async (event: UserSignOutEvent) => {
      setUser(undefined)
    }

    const userChangedListener = async (event: UserChangedEvent) => {
      setUser(event.detail)
    }

    authProvider.addEventListener('state-changed', providerStateChanged)
    authProvider.addEventListener('user-sign-in', signInListener)
    authProvider.addEventListener('user-sign-out', signOutListener)
    authProvider.addEventListener('user-changed', userChangedListener)

    return () => {
      authProvider.removeEventListener('state-changed', providerStateChanged)
      authProvider.removeEventListener('user-sign-in', signInListener)
      authProvider.removeEventListener('user-sign-out', signOutListener)
      authProvider.removeEventListener('user-changed', userChangedListener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })

  const result: [Pto.Users.User | undefined, boolean] = [user, isLoading]
  return result
}
