import { useState } from 'react'
import { Button, Input } from 'antd'
import { toast } from 'react-toastify'

import styles from './ResetPassword.module.scss'
import { useRequestResetPasswordMutation } from 'app/api/users-api'
import { EmailSent } from './EmailSent'

type Props = {}

export const ResetPassword = (_props: Props) => {
  const [email, setEmail] = useState<string>()
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false)

  const [requestResetPassword, { isLoading }] = useRequestResetPasswordMutation()

  const handleChangeEmail: React.ChangeEventHandler<HTMLInputElement> = ({ currentTarget }) => setEmail(currentTarget.value)

  const handleSubmit: React.FormEventHandler<HTMLElement> = async (e) => {
    e.preventDefault()
    if (!isLoading && email) {
      await requestResetPassword({ email })
        .unwrap()
        .then(() => {
          setIsEmailSent(true)
        })
        .catch((error) => toast.error(error))
    }
  }

  if (isEmailSent) return <EmailSent />

  return (
    <div className={styles.container}>
      <form className={styles.content} onSubmit={handleSubmit}>
        <div className={styles.header}>
          <h1>Reset password</h1>
          <div className={styles.logo} />
        </div>
        <Input autoFocus={true} value={email} onChange={handleChangeEmail} placeholder="Email" />
        <Button type="primary" htmlType="submit" className={styles.submitButton} loading={isLoading} disabled={isLoading}>
          Reset password
        </Button>
      </form>
    </div>
  )
}
