import { lazy } from 'react'
import { Pto, Role } from '@merchx-v3/pto'
import { Navigate, Route, Routes } from 'react-router-dom'

import { useUser } from 'app/auth'
import PrivateRoute from 'components/PrivateRoute'
import NotFoundPage from 'components/NotFoundPage'
import Layout from '../Layout'
import AppRoutes from './AppRoutes'
import AnonymousRoute from 'components/AnonymousRoute'
import GuestLogin from '../GuestLogin'
import { DesktopChat } from 'interfaces/sharedComponents/Chat'

// CUSTOMERS

// from shared pages
const Dashboard = lazy(() => import('interfaces/customers/pages/Dashboard'))
const Invoices = lazy(() => import('interfaces/customers/pages/Invoices'))
const ProfilePage = lazy(() => import('interfaces/sharedPages/ProfilePage'))

const Router = () => {
  const [user] = useUser()

  if (
    user &&
    !user.roles.includes(Role.SystemAdmin) &&
    !user.claims.some((claim) => claim.claim === Pto.Auth.Claim.CustomerPortal && [Pto.Auth.AccessLevel.AllowAll, Pto.Auth.AccessLevel.LimitedAccess].includes(claim.accessLevel))
  ) {
    return <Navigate to="/" />
  }

  // if (!user && !isLoading) return <Navigate to="/login" />

  return (
    <Layout>
      <Routes>
        <Route
          path={AppRoutes.conversations}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.Customer, Role.SystemAdmin]}>
              <DesktopChat />
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoutes.orders}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.Customer, Role.SystemAdmin]}>
              <Dashboard />
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoutes.invoices}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.Customer]}>
              <Invoices />
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoutes.inviteLink}
          element={
            <AnonymousRoute>
              <GuestLogin />
            </AnonymousRoute>
          }
        />
        <Route
          path={`${AppRoutes.profile}`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ProfilePage />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Layout>
  )
}

export default Router
