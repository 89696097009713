import { useEffect, useState } from 'react'
import { waitForElementToExist } from './waitForElementToExist'

export const useIsVisible = (id: string) => {
  const [isIntersecting, setIntersecting] = useState(false)
  const [element, setElement] = useState<Element | null>(null)

  useEffect(() => {
    waitForElementToExist(`#${id}`).then((elementRef) => {
      setElement(elementRef)
    })

    return () => {
      setElement(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))
    if (element) {
      observer.observe(element)
    }
    return () => {
      observer.disconnect()
    }
  }, [element])

  return isIntersecting
}
