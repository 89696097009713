import React from 'react'
import { Select } from 'antd'
import useDebounce from 'helpers/useDebounce'
import { useSubcategoriesOptionsQuery } from 'app/api/supplier-products-api'

type Props = {
  category?: string
  defaultValue?: string
  disabled?: boolean
  className?: string
  placeholder?: string
  value?: string

  onChange?: (value: string) => void
}

const SubcategoryOptions = (props: Props) => {
  const { category, disabled, className, placeholder = 'Subcategories', value, onChange } = props

  const [searchText, setSearchText] = React.useState<string>('')
  const debouncedSearchText = useDebounce(searchText, 500)

  const { data: subcategoryOptions } = useSubcategoriesOptionsQuery({ searchText: debouncedSearchText, category: category!, size: 20 }, { skip: !category })

  const handleSearch = (value: string) => {
    setSearchText(value)
  }

  const handleChange = (value: string) => {
    onChange && onChange(value)
    setSearchText('')
  }

  if (true) {
    return (
      <Select
        mode="tags"
        className={className}
        placeholder={placeholder || ''}
        style={{ width: '100%' }}
        onChange={handleChange}
        onSearch={handleSearch}
        showSearch
        value={value}
        searchValue={searchText}
        disabled={disabled}
        options={subcategoryOptions?.map((subcategory) => ({ value: subcategory }))}
      />
    )
  }
}

export default SubcategoryOptions
