import React from 'react'
import { Pto } from '@merchx-v3/pto'
import { Avatar, Badge, Dropdown, Menu, MenuProps } from 'antd'
import { useLocation, Link } from 'react-router-dom'
import { authProvider } from 'app/auth/auth-provider'
import { BgColorsOutlined, LogoutOutlined, RocketOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons'
import { AppRoutes } from '../Router'

import { useConversation } from 'app/chat/context/context'
import { useUser } from 'app/auth'

import { connector, PropsFromRedux } from './container'

import styles from './SiderMenuSuppliers.module.scss'

const items: MenuProps['items'] = [
  {
    key: 'settings',
    label: <Link to={`./${AppRoutes.profile}`}>Profile</Link>
  },
  {
    key: 'switchInterface',
    label: <Link to="/">Switch interface</Link>
  }
]

type Props = PropsFromRedux & {
  collapsed: boolean
  selectedSupplierCenter?: Pto.Suppliers.SupplierCenter
}

type MenuItem = Required<MenuProps>['items'][number]

const getItem = (label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem => {
  return {
    key,
    icon,
    children,
    label
  } as MenuItem
}

const iconStyle = { fontSize: 20, color: 'rgba(255, 255, 255, 0.85)' }

let menuItems: MenuItem[] = [
  getItem(<Link to={`./${AppRoutes.filfillment}`}>Fulfillment</Link>, AppRoutes.filfillment, <BgColorsOutlined style={iconStyle} />),
  getItem(<Link to={`./${AppRoutes.shipping}`}>Shipping</Link>, AppRoutes.shipping, <RocketOutlined style={iconStyle} />),
  getItem(<Link to={`./${AppRoutes.settings}`}>Settings</Link>, AppRoutes.settings, <SettingOutlined style={iconStyle} />),
  getItem(<Link to={`./${AppRoutes.supplierProducts}`}>Supplier Products</Link>, AppRoutes.supplierProducts, <RocketOutlined style={iconStyle} />)
]

const SiderMenuSuppliers = ({ collapsed, selectedSupplierCenter, appVersion }: Props) => {
  const location = useLocation()
  const [user] = useUser()

  const { totalUnreadMessages } = useConversation()

  return (
    <div className={styles.container}>
      <div className={styles.upper}>
        <div className={styles.userSection}>
          <div className={styles.avatar}>
            <Badge count={totalUnreadMessages}>
              <Avatar
                size={40}
                style={{ backgroundColor: '#87d068', border: 0 }}
                src={`/images/user-avatars/${user?.id || ''}?width=200&height=200`}
                shape="square"
                icon={<UserOutlined />}
              />
            </Badge>
          </div>
          {!collapsed && (
            <div className={styles.userInfo}>
              <div className={styles.textWrapper}>
                <div className={styles.name}>{`${user?.firstName || ''} ${user?.lastName || ''}`}</div>
                <div className={styles.email}>{user?.email}</div>
              </div>

              <Dropdown menu={{ items }} placement="bottomLeft">
                <SettingOutlined style={iconStyle} />
              </Dropdown>
            </div>
          )}
        </div>
        {!collapsed && <div className={styles.supplierCenter}>{selectedSupplierCenter ? selectedSupplierCenter.name : ''}</div>}
        <Menu selectedKeys={[location.pathname]} mode="inline" theme="dark" items={menuItems} />
      </div>

      <div className={styles.logoutBox}>
        <div className={styles.logout} onClick={() => authProvider.signOut()}>
          <LogoutOutlined style={{ color: 'rgba(255, 255, 255, 0.85)' }} />
          {!collapsed && <p className={styles.logoutText}>Logout</p>}
        </div>
        {!collapsed && <p className={styles.version}> Version {appVersion}</p>}
      </div>
    </div>
  )
}

export default connector(SiderMenuSuppliers)
