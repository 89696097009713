import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { WebSocket } from '@merchx-v3/web-socket'
import { Pto } from '@merchx-v3/pto'

import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { store } from 'app/store'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

type ShipmentId = string

type GetProjectShipmentsArgs = {
  projectId: string
  excludeCanceledItems?: boolean
}

type GetShippingTaskArgs = {
  shippingTaskId: string
  excludeCanceledItems?: boolean
}

type GetRateListArgs = {
  supplierCenterId: string
  args: Pto.Shipments.GetRates
}

type GetShippingListArgs = {
  page: number
  size: number
  filters: {
    startDate?: string
    endDate?: string
    supplierId?: string
    supplierCenterId?: string
    searchText?: string
    pluginId?: string
    statuses?: Pto.Shipments.ShippingTaskStatus[]
    sortingBy?: Pto.Shipments.ShippingTaskListSortingBy
  }
}

export const shipmentsApi = createApi({
  reducerPath: 'shipmentsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['Shipments', 'ShippingTasks', 'Projects'],
  endpoints: (builder) => ({
    sendToShipping: builder.mutation<Pto.Sagas.SagaWithSteps, Pto.Shipments.SendToShipping>({
      query: ({ shipmentId, shippingTaskId }) => ({
        url: `sagas/shipments/${shipmentId}/send-to-shipping/${shippingTaskId}`,
        method: 'POST'
      })
    }),
    holdShipping: builder.mutation<Pto.Sagas.SagaWithSteps, Pto.Shipments.HoldShipping>({
      query: ({ shipmentId, shippingTaskId, reason }) => ({
        url: `sagas/shipments/${shipmentId}/hold/${shippingTaskId}?reason=${reason}`,
        method: 'POST'
      })
    }),
    unholdShipping: builder.mutation<Pto.Sagas.SagaWithSteps, Pto.Shipments.UnholdShipping>({
      query: ({ shipmentId, shippingTaskId }) => ({
        url: `sagas/shipments/${shipmentId}/unhold/${shippingTaskId}`,
        method: 'POST'
      })
    }),
    cancelShipping: builder.mutation<Pto.Sagas.SagaWithSteps, Pto.Shipments.CancelShipping>({
      query: ({ shipmentId, shippingTaskId, reason }) => ({
        url: `sagas/shipments/${shipmentId}/cancel/${shippingTaskId}?reason=${reason}`,
        method: 'POST'
      })
    }),
    updateSkipPlugin: builder.mutation<void, Pto.Shipments.UpdateSkipPlugin>({
      query: ({ shipmentId, shippingTaskId, skipPlugin }) => ({
        url: `shipments/${shipmentId}/update-skip-plugin/${shippingTaskId}?skipPlugin=${skipPlugin}`,
        method: 'PATCH'
      })
    }),
    updateIsManualShippingPrice: builder.mutation<void, Pto.Shipments.UpdateIsManualShippingPrice & { shipmentId: string }>({
      query: ({ shipmentId, shippingTaskId, isManualShippingPrice }) => ({
        url: `shipments/${shipmentId}/update-is-manual-shipping-price`,
        method: 'PATCH',
        body: {
          shippingTaskId,
          isManualShippingPrice
        }
      })
    }),
    updateShippingPrice: builder.mutation<void, Pto.Shipments.UpdateShippingPrice & { shipmentId: string }>({
      query: ({ shipmentId, shippingTaskId, shippingPrice }) => ({
        url: `shipments/${shipmentId}/update-shipping-price`,
        method: 'PATCH',
        body: {
          shippingTaskId,
          shippingPrice
        }
      })
    }),
    changeTrackingNumber: builder.mutation<Pto.Sagas.SagaWithSteps, Pto.Shipments.UpdateTrackingNumber>({
      query: ({ shipmentId, shippingTaskId, trackingNumber }) => ({
        url: `shipments/${shipmentId}/update-tracking-number/${shippingTaskId}?tracking-number=${trackingNumber}`,
        method: 'PATCH'
      })
    }),
    voidTrackingNumber: builder.mutation<Pto.Sagas.SagaWithSteps, Pto.Shipments.VoidTrackingNumber>({
      query: ({ shipmentId, shippingTaskId }) => ({
        url: `shipments/${shipmentId}/void-tracking-number/${shippingTaskId}`,
        method: 'PATCH'
      })
    }),
    markShippingDelivered: builder.mutation<Pto.Sagas.SagaWithSteps, Pto.Shipments.DeliverShipping>({
      query: ({ shipmentId, shippingTaskId }) => ({
        url: `sagas/shipments/${shipmentId}/deliver/${shippingTaskId}`,
        method: 'POST'
      })
    }),
    markShippingShipped: builder.mutation<Pto.Sagas.SagaWithSteps, Pto.Shipments.ShipShipping>({
      query: ({ shipmentId, shippingTaskId }) => ({
        url: `sagas/shipments/${shipmentId}/ship/${shippingTaskId}`,
        method: 'POST'
      })
    }),
    shipmentList: builder.query<Pto.Shipments.Shipment[], GetProjectShipmentsArgs>({
      query: ({ projectId, excludeCanceledItems }) => ({
        url: `shipments/project/${projectId}?excludeCanceledItems=${excludeCanceledItems}`,
        method: 'GET'
      }),
      providesTags: (shipmentListData, _error, args) => [
        ...getProvidedTags(
          'Shipments',
          'LIST',
          shipmentListData?.map((item) => item.id)
        ),
        { type: 'Projects', id: args.projectId }
      ]
    }),
    shippingTasksDashboard: builder.query<Pto.Shipments.ShippingTasksDashboard, void>({
      query: () => ({
        url: 'shipments/shippings/dashboard',
        method: 'GET'
      })
    }),
    updateShippingTaskInfo: builder.mutation<Pto.Sagas.SagaWithSteps, Pto.Sagas.UpdateShippingTaskInfo>({
      query: (body) => ({
        url: 'sagas/shipments/update-shipping-task-info',
        method: 'POST',
        body
      })
    }),
    getRatesSelectOptions: builder.query<Pto.Shipments.RatesSelectOptions, string>({
      query: (supplierCenterId) => ({
        url: `plugins/supplier-plugins/shipping/supplier-centers/${supplierCenterId}/get-rates-select-options`,
        method: 'GET'
      }),
      providesTags: (ratesSelectOptionsData, _error, _args) => getProvidedTags('Shipments', 'RATES_OPTIONS')
    }),
    getRateList: builder.query<Pto.Shipments.ShippingRate[], GetRateListArgs>({
      query: ({ supplierCenterId, args }) => ({
        url: `plugins/supplier-plugins/shipping/supplier-centers/${supplierCenterId}/get-rate-list`,
        method: 'POST',
        body: args
      }),
      providesTags: (rateListData, _error, _args) => getProvidedTags('Shipments', 'RATES_LIST')
    }),
    shippingList: builder.query<Pto.Shipments.ShippingTaskList, GetShippingListArgs>({
      query: ({ page, size, filters }) => ({
        url: `shipments/shippings/list`,
        params: { page, size, ...filters },
        method: 'GET'
      }),
      providesTags: (shippingListData, _error, _args) =>
        getProvidedTags(
          'Shipments',
          'LIST',
          shippingListData?.items.map((item) => item.id)
        )
    }),
    shippingTask: builder.query<Pto.Shipments.ShippingTask, GetShippingTaskArgs>({
      query: ({ shippingTaskId, excludeCanceledItems }) => ({
        url: `shipments/shipping/${shippingTaskId}?excludeCanceledItems=${excludeCanceledItems}`,
        method: 'GET'
      }),
      providesTags: (_result, _error, { shippingTaskId }) => getProvidedTags('ShippingTasks', shippingTaskId)
    }),
    shipment: builder.query<Pto.Shipments.Shipment, ShipmentId>({
      query: (shipmentId) => ({
        url: `shipments/${shipmentId}`,
        method: 'GET'
      }),
      providesTags: (_result, _error, shipmentId) => getProvidedTags('Shipments', shipmentId)
    }),
    generateFulfillmentTask: builder.mutation<Pto.Fulfillments.CreateFulfillmentTask[], string>({
      query: (projectId: string) => ({
        url: `shipments/${projectId}/generate-fulfillment-tasks`,
        method: 'POST'
      })
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Shipment') {
    store.dispatch(shipmentsApi.util.invalidateTags([{ type: 'Shipments', id: payload.entityId }, { type: 'ShippingTasks' }]))
  }

  if (payload.entityType === 'Shipping task') {
    store.dispatch(shipmentsApi.util.invalidateTags([{ type: 'ShippingTasks', id: payload.entityId }]))
  }
}

export const subscribeToShipmentEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromShipmentEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const {
  useSendToShippingMutation,
  useHoldShippingMutation,
  useUnholdShippingMutation,
  useCancelShippingMutation,
  useUpdateSkipPluginMutation,
  useChangeTrackingNumberMutation,
  useVoidTrackingNumberMutation,
  useMarkShippingDeliveredMutation,
  useMarkShippingShippedMutation,
  useShipmentListQuery,
  useShippingTasksDashboardQuery,
  useUpdateShippingTaskInfoMutation,
  useLazyGetRateListQuery,
  useGetRatesSelectOptionsQuery,
  useShippingListQuery,
  useShippingTaskQuery,
  useShipmentQuery,
  useGenerateFulfillmentTaskMutation,
  useUpdateIsManualShippingPriceMutation,
  useUpdateShippingPriceMutation
} = shipmentsApi
