import { Pto } from '@merchx-v3/pto'
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { IConversationService } from '../conversation-service'
import { IConversationStorage } from '../conversation-storage'
import { ConversationId, MessageId } from '../types'

interface SetLastReadMessageDebouncerParams {
  conversationId: ConversationId
  lastReadMessage: Pto.Conversations.Message
}

export class SetLastReadMessageDebouncer {
  private readonly _duration: number
  private readonly _service: IConversationService
  private readonly _storage: IConversationStorage
  private readonly _updateState?: () => void

  private _debouncers: Map<ConversationId, Subject<SetLastReadMessageDebouncerParams>> = new Map<ConversationId, Subject<SetLastReadMessageDebouncerParams>>()

  constructor(service: IConversationService, storage: IConversationStorage, updateState?: () => void, duration: number = 900) {
    this._duration = duration
    this._service = service
    this._storage = storage
    this._updateState = updateState
  }

  debounce(conversationId: ConversationId, lastReadMessage: Pto.Conversations.Message) {
    const conversationItem = this._debouncers.get(conversationId)

    if (conversationItem) {
      // Conversation exists - update debouncer
      conversationItem.next({ conversationId, lastReadMessage })
    } else {
      // Conversation not found - create new and a deboucer
      const subject = this.createDebouncer()
      this._debouncers.set(conversationId, subject)

      subject.next({ conversationId, lastReadMessage })
    }
  }

  private createDebouncer() {
    const subject = new Subject<SetLastReadMessageDebouncerParams>()
    subject.pipe(debounceTime(this._duration)).subscribe(({ conversationId, lastReadMessage }) => {
      // Stop subject
      subject.complete()

      // Remove debouncer from collection
      const conversationItem = this._debouncers.get(conversationId)

      if (conversationItem) {
        // conversationItem.delete(userId)
        // Cleanup. Remove conversation if it doesn't contain any users
        // if (conversationItem.size === 0) {
        this._debouncers.delete(conversationId)
        // }
      }

      this._service.setLastReadMessage(conversationId, lastReadMessage)
      const conversation = this._storage.conversations[conversationId]
      if (conversation) {
        conversation.lastReadMessage = lastReadMessage
      }

      this._updateState && this._updateState()

      // Remove typing user from conversation
      // const conversation = this._storage.getConversation(conversationId)
      // if (conversation) {
      //   const idx = conversation.typingUsers.findIndex((item) => item === userId)
      //   if (idx > -1) {
      //     conversation.typingUsers = conversation.typingUsers.slice(0, idx).concat(conversation.typingUsers.slice(idx + 1))
      //     this._updateState && this._updateState()
      //   }
      // }
    })

    return subject
  }
}
