import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { Button } from 'antd'
import { generateBarcode } from './generateBarcode'

pdfMake.vfs = pdfFonts.pdfMake.vfs

type LabelProps = {
  ecomProductName: string
  compositeProductVariant: string
  fulfillmentTaskId: string
  quantity: number
  printAreas: string
  sku: string
  barcode: string
  externalTaskItemId: string
  itemDescription: string
  unitCt: number
}

type Props = {
  filename: string
  labels: LabelProps[]
}

const PrintBarcodes = ({ labels, filename }: Props) => {
  const downloadPDF = () => {
    const docDefinition = generateBarcode(labels)
    pdfMake.createPdf(docDefinition).download(`${filename}.pdf`)
  }

  return <div>{labels.length > 0 && <Button onClick={downloadPDF}>Download PDF</Button>}</div>
}

export default PrintBarcodes
