import { forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import { toast } from 'react-toastify'
import { Select } from 'antd'
import { Pto } from '@merchx-v3/pto'
import { useDependencyFieldOptionsQuery } from 'app/api/plugins-api'
import { FieldComponentProps } from '../types'
import ErrorContainer from '../ErrorContainer'
import styles from './DependencyField.module.scss'

type RefProps = {
  validate: () => boolean
}

const DependencyField = forwardRef<RefProps, FieldComponentProps<Pto.Plugins.ValueType.Dependency>>(
  ({ pluginInfo, onChange, supplierId, field, targetId, ...props }, forwardedRef) => {
    // const [selectOptions, setSelectOptions] = useState<Pto.Option[]>([])
    const [targetField, setTargetField] = useState<string>()
    const [isDropdownOpened, setIsDropdownVisible] = useState<boolean>(false)
    const [isShowError, setIsShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const [innerField, setInnerField] = useState(field)

    useEffect(() => {
      setInnerField(field)
    }, [field])

    const { currentData: selectOptions = [], isLoading } = useDependencyFieldOptionsQuery(
      {
        field: { ...innerField, isRequired: false },
        targetField: targetField!,
        pluginId: pluginInfo.value,
        supplierId: supplierId!
      },
      { skip: !supplierId || !isDropdownOpened || !targetField }
    )

    useImperativeHandle(forwardedRef, () => ({
      validate: () => {
        if (!field.isRequired) return true

        const isValueMissed = field.value.fields.reduce((accum, item) => accum || !item.value, false)
        if (isValueMissed) {
          toast.error(`Dependency field ${field.displayName} for ${targetId} should be selected`)
          setErrorMessage(`Dependency field ${field.displayName} for ${targetId} should be selected`)
          setIsShowError(true)
          return false
        }

        return true
      }
    }))

    const handleRecordChanged = (innerFieldKey: string, recordId: string) => {
      const selectedOption = selectOptions.find((item) => item.value === recordId)
      if (selectedOption) {
        const data = JSON.parse(JSON.stringify(innerField.value.fields)) as Pto.Plugins.Fields.DependencyValue['fields']

        let targetFieldIndex = data.findIndex((item) => item.key === innerFieldKey)

        data[targetFieldIndex].value = selectedOption.value
        data[targetFieldIndex].label = selectedOption.label

        targetFieldIndex++
        while (targetFieldIndex < data.length) {
          data[targetFieldIndex].value = ''
          data[targetFieldIndex].label = ''

          targetFieldIndex++
        }

        setInnerField((innerField) => ({
          ...innerField,
          fields: data
        }))

        onChange({
          fields: data
        })
        setErrorMessage('')
        setIsShowError(false)
      }
    }

    const handleRecordClear = (innerFieldKey: string) => {
      const data = JSON.parse(JSON.stringify(innerField.value.fields)) as Pto.Plugins.Fields.DependencyValue['fields']

      let targetFieldIndex = data.findIndex((item) => item.key === innerFieldKey)

      data[targetFieldIndex].value = ''
      data[targetFieldIndex].label = ''

      targetFieldIndex++
      while (targetFieldIndex < data.length) {
        data[targetFieldIndex].value = ''
        data[targetFieldIndex].label = ''

        targetFieldIndex++
      }

      setInnerField((innerField) => ({
        ...innerField,
        fields: data
      }))

      onChange({
        fields: data
      })
      setErrorMessage('')
      setIsShowError(false)
    }

    const handleDropdownVisible = (innerFieldKey: string, isVisible: boolean) => {
      setTargetField(innerFieldKey)
      setIsDropdownVisible(isVisible)
    }

    return (
      <ErrorContainer isShowError={isShowError} errorMessage={errorMessage}>
        {innerField.value.fields.map((dependencyField) => (
          <Select
            key={dependencyField.key}
            status={isShowError ? 'error' : undefined}
            style={{ width: '100%' }}
            className={styles.select}
            {...props}
            loading={isLoading}
            value={dependencyField.value || undefined}
            onDropdownVisibleChange={(isVisible) => handleDropdownVisible(dependencyField.key, isVisible)}
            onChange={(recordId) => handleRecordChanged(dependencyField.key, recordId)}
            onClear={() => handleRecordClear(dependencyField.key)}
            onFocus={(e) => console.log(dependencyField.key, ' changed focus ', e)}
            disabled={props.disabled || (!!targetField && isDropdownOpened && targetField !== dependencyField.key)}
            allowClear
            options={
              isLoading
                ? []
                : selectOptions.length
                ? selectOptions.map((item) => ({ value: item.value, label: item.label }))
                : dependencyField.value
                ? [{ value: dependencyField.value, label: dependencyField.label }]
                : []
            }
            placeholder={dependencyField.key}
          />
        ))}
      </ErrorContainer>
    )
  }
)

export default DependencyField
