import axios from 'axios'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { WebSocket } from '@merchx-v3/web-socket'
import { Pto } from '@merchx-v3/pto'

import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { store } from 'app/store'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

type CancelFulfillmentTaskArgs = {
  fulfillmentTaskId: string
  cancelFulfillmentTask: Pto.Fulfillments.CancelFulfillmentTask
}

type CancelFulfillmentTaskItemArgs = {
  fulfillmentTaskId: string
  fulfillmentTaskItemId: string
  reason: string
}

type HoldFulfillmentTaskArgs = {
  fulfillmentTaskId: string
  reason: string
}

type ShipFulfillmentTaskArgs = {
  fulfillmentTaskId: string
  trackingNumber: string
}

type UpdateSkipPluginArgs = {
  fulfillmentTaskId: string
  skipPlugin: boolean
}

export const getFulfillmentTasksCsv = async (listArgs: Pto.Fulfillments.ListArgs) => {
  const accessToken = await tokenProvider.getAccessToken()
  const { data } = await axios.get(`${protocol}://${domain}/api/fulfillment-tasks/csv`, {
    headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
    params: listArgs,
    responseType: 'stream'
  })

  const url = window.URL.createObjectURL(new Blob([data]))

  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', 'fulfillment-tasks.csv')

  document.body.appendChild(link)
  link.click()

  link.remove()
  window.URL.revokeObjectURL(url)
}

export const fulfillmentTasksApi = createApi({
  reducerPath: 'fulfillmentTasksApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['FulfillmentTasks', 'FulfillmentTaskItems'],
  endpoints: (builder) => ({
    fulfillmentTask: builder.query<Pto.Fulfillments.FulfillmentTaskWithDesignRequest, string>({
      query: (fulfillmentTaskId: string) => ({
        url: `fulfillment-tasks/${fulfillmentTaskId}`,
        method: 'GET'
      }),
      providesTags: (_result, _error, fulfillmentTaskId) => getProvidedTags('FulfillmentTasks', fulfillmentTaskId)
    }),
    projectFulfillmentTasks: builder.query<Pto.Fulfillments.FulfillmentTask[], string>({
      query: (projectId: string) => ({
        url: `fulfillment-tasks/project/${projectId}`,
        method: 'GET'
      }),
      providesTags: (projectFulfillmentTaskListData, _error, _args) =>
        getProvidedTags(
          'FulfillmentTasks',
          'PROJECTS_LIST',
          projectFulfillmentTaskListData?.map((item) => item.id)
        )
    }),
    fulfillmentTasks: builder.query<Pto.Fulfillments.List, Pto.Fulfillments.ListArgs>({
      query: (listArgs) => ({
        url: `fulfillment-tasks`,
        params: listArgs,
        method: 'GET'
      }),
      providesTags: (fulfillmentTaskListData, _error, _args) =>
        getProvidedTags(
          'FulfillmentTasks',
          'LIST',
          fulfillmentTaskListData?.items.map((item) => item.id)
        )
    }),
    getFulfillmentTasksDashboard: builder.query<Pto.Fulfillments.FulfillmentTasksDashboard, void>({
      query: () => ({
        url: 'fulfillment-tasks/dashboard',
        method: 'GET'
      })
    }),
    fulfillmentItemsForBarcodes: builder.query<Pto.Fulfillments.FulfillmentItemListForBarcodes, Pto.Fulfillments.FulfillmentItemListForBarcodesArgs>({
      query: (queryArgs) => ({
        url: `fulfillment-tasks/item-list-for-barcodes`,
        params: queryArgs,
        method: 'GET'
      }),
      providesTags: (fulfillmentItemsForBarcodesData, _error, _args) =>
        getProvidedTags(
          'FulfillmentTaskItems',
          'BARCODES',
          fulfillmentItemsForBarcodesData?.items.map((item) => item.id)
        )
    }),
    createFulfillmentTasks: builder.mutation<Pto.Sagas.SagaWithSteps, Pto.SagasV2.CreateFulfillmentTasks.StartSaga>({
      query: (body) => ({
        url: 'sagas/create-fulfillment-tasks',
        body,
        method: 'POST'
      })
    }),
    cancelFulfillmentTask: builder.mutation<Pto.Sagas.SagaWithSteps, CancelFulfillmentTaskArgs>({
      query: ({ fulfillmentTaskId, cancelFulfillmentTask }) => ({
        url: `sagas/fulfillment-tasks/${fulfillmentTaskId}/cancel`,
        params: { reason: cancelFulfillmentTask.reason },
        method: 'POST'
      })
    }),
    holdFulfillmentTask: builder.mutation<Pto.Sagas.SagaWithSteps, HoldFulfillmentTaskArgs>({
      query: ({ fulfillmentTaskId, reason }) => ({
        url: `sagas/fulfillment-tasks/${fulfillmentTaskId}/hold`,
        params: { reason },
        method: 'POST'
      })
    }),
    shipFulfillmentTask: builder.mutation<void, ShipFulfillmentTaskArgs>({
      query: ({ fulfillmentTaskId, trackingNumber }) => ({
        url: `fulfillment-tasks/${fulfillmentTaskId}/ship`,
        params: { trackingNumber },
        method: 'POST'
      })
    }),
    completeFulfillmentTask: builder.mutation<Pto.Sagas.SagaWithSteps, Pto.Fulfillments.CompleteFulfillmentTask>({
      query: (body) => ({
        url: `sagas/fulfillment-tasks/${body.fulfillmentTaskId}/complete`,
        method: 'POST'
      })
    }),
    updateSkipPlugin: builder.mutation<void, UpdateSkipPluginArgs>({
      query: ({ fulfillmentTaskId, ...body }) => ({
        url: `fulfillment-tasks/${fulfillmentTaskId}/update-skip-plugin`,
        method: 'PATCH',
        body
      })
    }),
    sendTaskToFulfillment: builder.mutation<Pto.Sagas.SagaWithSteps, string>({
      query: (fulfillmentTaskId) => ({
        url: `sagas/fulfillment-tasks/send-to-fulfillment/${fulfillmentTaskId}`,
        method: 'POST'
      })
    }),
    processFulfillmentTask: builder.mutation<void, string>({
      query: (fulfillmentTaskId) => ({
        url: `fulfillment-tasks/${fulfillmentTaskId}/process`,
        method: 'POST'
      })
    }),
    cancelFulfillmentTaskItem: builder.mutation<Pto.Sagas.SagaWithSteps, CancelFulfillmentTaskItemArgs>({
      query: ({ fulfillmentTaskId, fulfillmentTaskItemId, reason }) => ({
        url: `sagas/fulfillment-tasks/${fulfillmentTaskId}/${fulfillmentTaskItemId}/cancel-item`,
        params: { reason },
        method: 'POST'
      })
    }),
    completeFulfillmentTaskItem: builder.mutation<Pto.Sagas.SagaWithSteps, Pto.Fulfillments.CompleteFulfillmentTaskItem>({
      query: (body) => ({
        url: `sagas/fulfillment-tasks/${body.fulfillmentTaskId}/${body.fulfillmentTaskItemId}/complete-item`,
        body,
        method: 'POST'
      })
    }),
    holdFulfillmentTaskItem: builder.mutation<Pto.Sagas.SagaWithSteps, Pto.Fulfillments.HoldFulfillmentTaskItem>({
      query: (body) => ({
        url: `sagas/fulfillment-tasks/${body.fulfillmentTaskId}/${body.fulfillmentTaskItemId}/hold-item`,
        params: { reason: body.holdReason },
        method: 'POST'
      })
    }),
    unholdFulfillmentTaskItem: builder.mutation<Pto.Sagas.SagaWithSteps, Pto.Fulfillments.UnholdFulfillmentTaskItem>({
      query: (body) => ({
        url: `sagas/fulfillment-tasks/${body.fulfillmentTaskId}/${body.fulfillmentTaskItemId}/unhold-item`,
        method: 'POST'
      })
    }),
    setTags: builder.mutation<void, Pto.Fulfillments.SetTagsForFulfillmentTask>({
      query: (body) => ({
        url: `fulfillment-tasks/set-tags`,
        body,
        method: 'POST'
      })
    }),
    tagOptions: builder.query<Pto.Option[], Pto.Fulfillments.FulfillmentTagsQuery>({
      query: (params) => ({
        url: 'fulfillment-tasks/tag-options',
        params
      }),
      providesTags: (_tagOptionsData, _error, _args) => getProvidedTags('FulfillmentTasks', 'TAGS')
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Project') {
    store.dispatch(fulfillmentTasksApi.util.invalidateTags([{ type: 'FulfillmentTasks', id: 'PROJECTS_LIST' }]))
  }

  if (payload.entityType === 'Fulfillment task') {
    store.dispatch(
      fulfillmentTasksApi.util.invalidateTags([
        { type: 'FulfillmentTasks', id: payload.entityId },
        { type: 'FulfillmentTaskItems', id: 'BARCODES' }
      ])
    )
  }
}

export const subscribeToFulfillmentTaskEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromFulfillmentTaskEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const {
  useFulfillmentTaskQuery,
  useFulfillmentTasksQuery,
  useFulfillmentItemsForBarcodesQuery,
  useGetFulfillmentTasksDashboardQuery,
  useProjectFulfillmentTasksQuery,
  useProcessFulfillmentTaskMutation,
  useHoldFulfillmentTaskMutation,
  useShipFulfillmentTaskMutation,
  useSendTaskToFulfillmentMutation,
  useCreateFulfillmentTasksMutation,
  useCancelFulfillmentTaskMutation,
  useCompleteFulfillmentTaskMutation,
  useUpdateSkipPluginMutation,
  useCancelFulfillmentTaskItemMutation,
  useCompleteFulfillmentTaskItemMutation,
  useHoldFulfillmentTaskItemMutation,
  useUnholdFulfillmentTaskItemMutation,
  useSetTagsMutation,
  useTagOptionsQuery
} = fulfillmentTasksApi
