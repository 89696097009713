import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import { Avatar, Badge, Dropdown, Menu, MenuProps } from 'antd'
import { CommentOutlined, DollarOutlined, LogoutOutlined, SettingOutlined, SkinOutlined, UserOutlined } from '@ant-design/icons'
import { AppRoutes } from '../Router'

import { useUser } from 'app/auth'
import { authProvider } from 'app/auth/auth-provider'
import { useConversation } from 'app/chat/context/context'

import { connector, PropsFromRedux } from './container'

import styles from './SiderMenuCustomers.module.scss'

const items: MenuProps['items'] = [
  {
    key: 'settings',
    label: <Link to={`./${AppRoutes.profile}`}>Profile</Link>
  },
  {
    key: 'switchInterface',
    label: <Link to="/">Switch interface</Link>
  }
]

type Props = PropsFromRedux & {
  collapsed: boolean
}

type MenuItem = Required<MenuProps>['items'][number]

const getItem = (label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem => {
  return {
    key,
    icon,
    children,
    label
  } as MenuItem
}

const iconStyle = { fontSize: 20, color: 'rgba(255, 255, 255, 0.85)' }

const SiderMenuCustomers = ({ collapsed, appVersion }: Props) => {
  const location = useLocation()
  const [user] = useUser()

  const { totalUnreadMessages } = useConversation()

  let menuItems: MenuItem[] = [
    getItem(<Link to={`./`}>Orders</Link>, AppRoutes.orders, <SkinOutlined style={iconStyle} />),
    getItem(<Link to={`./${AppRoutes.conversations}`}>Conversations</Link>, AppRoutes.conversations, <CommentOutlined style={iconStyle} />),
    getItem(<Link to={`./${AppRoutes.invoices}`}>Invoices</Link>, AppRoutes.invoices, <DollarOutlined style={iconStyle} />)
  ]

  return (
    <div className={styles.container}>
      <div className={styles.upper}>
        <div className={styles.userSection}>
          <div className={styles.avatar}>
            <Badge count={totalUnreadMessages}>
              <Avatar
                size={40}
                style={{ backgroundColor: '#87d068', border: 0 }}
                src={`/images/user-avatars/${user?.id || ''}?width=200&height=200`}
                shape="square"
                icon={<UserOutlined />}
              />
            </Badge>
          </div>
          {!collapsed && (
            <div className={styles.userInfo}>
              <div className={styles.textWrapper}>
                <div className={styles.name}>{`${user?.firstName || ''} ${user?.lastName || ''}`}</div>
                <div className={styles.email}>{user?.email}</div>
              </div>

              <Dropdown menu={{ items }} placement="bottomLeft">
                <SettingOutlined style={iconStyle} />
              </Dropdown>
            </div>
          )}
        </div>
        <Menu selectedKeys={[location.pathname]} mode="inline" theme="dark" items={menuItems} />
      </div>

      <div className={styles.logoutBox}>
        <div className={styles.logout} onClick={() => authProvider.signOut()}>
          <LogoutOutlined style={{ color: 'rgba(255, 255, 255, 0.85)' }} />
          {!collapsed && <p className={styles.logoutText}>Logout</p>}
        </div>
        {!collapsed && <p className={styles.version}> Version {appVersion}</p>}
      </div>
    </div>
  )
}

export default connector(SiderMenuCustomers)
