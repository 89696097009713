import { Pto } from '@merchx-v3/pto'
import { WebSocket } from '@merchx-v3/web-socket'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { store } from 'app/store'
import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

export const suppliersApi = createApi({
  reducerPath: 'suppliersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['Suppliers', 'SupplierCenters'],
  endpoints: (builder) => ({
    supplier: builder.query<Pto.Suppliers.Supplier, string | undefined>({
      query: (supplierId) => ({
        url: `/suppliers/${supplierId}`
      }),
      providesTags: (_result, _error, supplierId) => getProvidedTags('Suppliers', supplierId)
    }),
    supplierList: builder.query<Pto.Suppliers.List, Pto.Suppliers.SupplierListQuery>({
      query: ({ searchText, page = 1, size = 30 }) => ({
        url: 'suppliers',
        params: { searchText, page, size }
      }),
      providesTags: (supplierListData, _error, _args) =>
        getProvidedTags(
          'Suppliers',
          'LIST',
          supplierListData?.items.map((item) => item.id)
        )
    }),
    supplierOptions: builder.query<Pto.Suppliers.Supplier[], Pto.Suppliers.SupplierOptionsQuery>({
      query: ({ searchText, page = 1, size = 30 }) => ({
        url: 'suppliers/options',
        params: { searchText, page, size }
      }),
      providesTags: (supplierOptionsData, _error, _args) =>
        getProvidedTags(
          'Suppliers',
          'OPTIONS',
          supplierOptionsData?.map((item) => item.id)
        )
    }),
    createSupplier: builder.mutation<string, Pto.Suppliers.CreateUpdateSupplier>({
      query: (createSupplierDto) => ({
        url: 'suppliers',
        body: createSupplierDto,
        method: 'POST'
      })
    }),
    updateSupplier: builder.mutation<boolean, Pto.Suppliers.CreateUpdateSupplier>({
      query: (updateSupplierDto) => ({
        url: 'suppliers',
        body: updateSupplierDto,
        method: 'PATCH'
      })
    }),
    createSupplierCenter: builder.mutation<string, Pto.Suppliers.CreateUpdateSupplierCenter>({
      query: (createSupplierCenterDto) => ({
        url: 'suppliers/supplier-center',
        body: createSupplierCenterDto,
        method: 'POST'
      }),
      invalidatesTags: (_first, error, args) => [{ type: 'Suppliers', id: `CENTERS_BY_SUPPLIER_${args.supplierId}` }]
    }),
    updateSupplierCenter: builder.mutation<string, Pto.Suppliers.CreateUpdateSupplierCenter>({
      query: (updateSupplierCenterDto) => ({
        url: 'suppliers/supplier-center',
        body: updateSupplierCenterDto,
        method: 'PATCH'
      })
    }),
    supplierCenter: builder.query<Pto.Suppliers.SupplierCenter, string>({
      query: (supplierCenterId) => ({
        url: `/suppliers/supplier-centers/${supplierCenterId}`
      }),
      providesTags: (_result, _error, supplierCenterId) => getProvidedTags('SupplierCenters', supplierCenterId)
    }),
    supplierCentersBySupplier: builder.query<Pto.Suppliers.SupplierCenter[], string>({
      query: (supplierId) => ({
        url: `/suppliers/supplier-centers-by-supplier/${supplierId}`
      }),
      providesTags: (supplierCentersBySupplierData, _error, _args) =>
        getProvidedTags(
          'SupplierCenters',
          'LIST',
          supplierCentersBySupplierData?.map((item) => item.id)
        )
    }),
    createShippingSetting: builder.mutation<void, Pto.Suppliers.CreateUpdateShippingSetting>({
      query: (body) => ({
        url: `/suppliers/shipping-setting`,
        body,
        method: 'POST'
      })
    }),
    updateShippingSetting: builder.mutation<void, Pto.Suppliers.CreateUpdateShippingSetting>({
      query: (body) => ({
        url: `/suppliers/shipping-setting`,
        body,
        method: 'PATCH'
      })
    }),
    removeShippingSetting: builder.mutation<void, Pto.Suppliers.RemoveShippingSetting>({
      query: (body) => ({
        url: `/suppliers/shipping-setting`,
        body,
        method: 'DELETE'
      })
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Supplier center') {
    store.dispatch(suppliersApi.util.invalidateTags([{ type: 'SupplierCenters', id: payload.entityId }]))
  }
}

export const subscribeToSupplierEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromSupplierEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const {
  useSupplierQuery,
  useSupplierListQuery,
  useSupplierOptionsQuery,
  useCreateSupplierMutation,
  useCreateSupplierCenterMutation,
  useUpdateSupplierCenterMutation,
  useUpdateSupplierMutation,
  useSupplierCenterQuery,
  useLazySupplierCenterQuery,
  useSupplierCentersBySupplierQuery,
  useCreateShippingSettingMutation,
  useUpdateShippingSettingMutation,
  useRemoveShippingSettingMutation
} = suppliersApi
