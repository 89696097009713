import { Pto } from '@merchx-v3/pto'
import { DesignRequestArtworkCard } from './DesignRequestArtworkCard'

import styles from './DesignRequestArtworksCard.module.scss'

type Props = {
  designRequest: Pto.DesignRequests.DesignRequest
  onArtworkUploaded: (artwork: Pto.DesignRequests.UpdateArtworkFiles) => Promise<boolean>
  onArtworkMockupUrlUploaded: (mockup: Pto.DesignRequests.UploadArtworkMockupUrl) => Promise<boolean>
}

const DesignRequestArtworksCard = (props: Props) => {
  const { designRequest, onArtworkUploaded, onArtworkMockupUrlUploaded } = props

  return (
    <div className={styles.container}>
      {designRequest.products.map((product) => (
        <DesignRequestArtworkCard
          key={product.projectProductItemId}
          designRequest={designRequest}
          product={product}
          onArtworkUploaded={onArtworkUploaded}
          onArtworkMockupUrlUploaded={onArtworkMockupUrlUploaded}
        />
      ))}
    </div>
  )
}

export default DesignRequestArtworksCard
