import { useState } from 'react'
import { Pto } from '@merchx-v3/pto'
import { RcFile } from 'antd/es/upload'
import { Modal, UploadFile } from 'antd'
import { throttle } from '@merchx-v3/shared-functions'
import { MessageInput } from '@chatscope/chat-ui-kit-react'
import { useConversation } from 'app/chat/context/context'
import { conversationService } from 'app/chat/conversation-service'
import { UploadFilesV2 } from 'components/UploadFilesV2'

type Props = {
  conversationId: string
  isOpened: boolean
  onClose: () => void
}

export const AttachmentModal = (props: Props) => {
  const { isOpened, conversationId, onClose } = props

  const [uploadedImages, setUploadedImages] = useState<UploadFile<RcFile>[]>([])
  const { activeConversation, currentUser, sendTyping } = useConversation()

  const [messageText, setMessageText] = useState('')
  const [messageSource, setMessageSource] = useState('')

  const handleMessageChanged = (text: string, html: string) => {
    setMessageText(text)
    setMessageSource(html)

    if (activeConversation && currentUser) {
      sendTyping({
        conversationId: activeConversation?.id,
        isTyping: true,
        userId: currentUser.id,
        content: '', // Note! Most often you don't want to send what the user types, as this can violate his privacy!
        throttle: true
      })
    }
  }

  const handleSend = throttle(() => {
    if (!activeConversation) return
    if (uploadedImages.length === 0) {
      // TEXT MESSAGE
      const doc = new DOMParser().parseFromString(messageSource, 'text/html')
      const isHtml = Array.from(doc.body.childNodes).some((node) => node.nodeType === 1)

      const messageType = isHtml ? Pto.Conversations.MessageContentType.Html : Pto.Conversations.MessageContentType.Text

      conversationService.postMessage(activeConversation.id, messageType, messageSource)
    } else if (uploadedImages.length === 1) {
      // IMAGE MESSAGE
      conversationService.postMessage(activeConversation.id, Pto.Conversations.MessageContentType.Image, {
        url: uploadedImages[0].url!,
        altName: uploadedImages[0].fileName || '',
        message: messageSource
      })
    } else if (uploadedImages.length > 1) {
      // GALLERY MESSAGE
      const messageContent: Pto.Conversations.MessageContentTypes.GalleryContent = {
        message: messageSource,
        images: uploadedImages
          .filter((item) => item.url)
          .map((item) => ({
            url: item.url!,
            altName: item.fileName || ''
          }))
      }

      conversationService.postMessage(activeConversation.id, Pto.Conversations.MessageContentType.Gallery, messageContent)
    }

    handleClose()
  }, 500)

  const handleClose = () => {
    setMessageText('')
    setMessageSource('')
    setUploadedImages([])
    onClose()
  }

  return (
    <Modal open={isOpened} onCancel={handleClose} footer={false}>
      <UploadFilesV2
        owner={Pto.Files.Owner.Conversation}
        ownerId={conversationId}
        type={Pto.Files.Type.ConversationAttachment}
        value={uploadedImages}
        onChange={(imageList) => setUploadedImages(imageList)}
      />
      <MessageInput attachButton={false} value={messageText} onSend={handleSend} onChange={handleMessageChanged}></MessageInput>
    </Modal>
  )
}
