import dayjs from 'dayjs'
import { Pto } from '@merchx-v3/pto'
import { Link } from 'react-router-dom'
import { MenuProps } from 'antd'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useCallback, useEffect, useState } from 'react'
import { PresentationAPI } from '@merchx-v3/shared-functions'
import { ChatContainer, ConversationHeader, MessageInput, MessageList, TypingIndicator } from '@chatscope/chat-ui-kit-react'

import { useConversation } from 'app/chat/context/context'
import { AppRoutes } from 'interfaces/admin/components/Router'
import { conversationService } from 'app/chat/conversation-service'
import { ConversationMessage as ConversationMessageType } from 'app/chat/types'
import { waitForElementToExist } from 'helpers'

import { AvatarLocal } from '../AvatarLocal'
import { AttachmentMenu, AttachmentModal } from './Attachments'
import { ConversationMessageGroup } from '../ConversationMessage'

type Props = {}

const MessageListComponent = (props: Props) => {
  const [isAutoscrollToBottom, setIsAutoscrollToBottom] = useState<boolean>(false)
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false)
  const [loadMorePosition, setLoadMorePosition] = useState<'top' | 'bottom'>('bottom')

  const [isAttachmentModalOpened, setIsAttachmentModalOpened] = useState(false)
  const [isAttachmentMenuVisible, setIsAttachmentMenuVisible] = useState<boolean>(false)

  const { activeConversation, currentUser, currentMessage, currentMessages, getUser, setCurrentMessage, sendTyping } = useConversation()

  dayjs.extend(relativeTime)

  useEffect(() => {
    if (activeConversation?.lastReadMessage?.id) {
      waitForElementToExist(`#${activeConversation.lastReadMessage.id}`).then((elementRef) => {
        elementRef?.scrollIntoView({ block: 'center' })
      })

      setIsAutoscrollToBottom(activeConversation.lastReadMessage.id === activeConversation.lastMessage?.id)
    }
  }, [activeConversation])

  const getTypingIndicator = useCallback(() => {
    if (activeConversation) {
      const typingUsers = activeConversation.typingUsers

      if (typingUsers.length > 0) {
        const typingUserId = typingUsers[0]

        // Check if typing user participates in the conversation
        const typingParticipant = activeConversation.participants.find((item) => item.userId === typingUserId)
        if (typingParticipant) {
          const typingUser = getUser(typingParticipant.userId)
          if (typingUser) {
            return <TypingIndicator content={`${PresentationAPI.getChatUserPreview(typingUser)} is typing`} />
          }
        }
      }
    }

    return undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeConversation])

  const handleChange = (message: string) => {
    setCurrentMessage(message)

    if (activeConversation && currentUser) {
      sendTyping({
        conversationId: activeConversation?.id,
        isTyping: true,
        userId: currentUser.id,
        content: '', // Note! Most often you don't want to send what the user types, as this can violate his privacy!
        throttle: true
      })
    }
  }

  const handleSend = (content: string) => {
    if (!activeConversation) return

    const doc = new DOMParser().parseFromString(content, 'text/html')
    const isHtml = Array.from(doc.body.childNodes).some((node) => node.nodeType === 1)

    const messageType = isHtml ? Pto.Conversations.MessageContentType.Html : Pto.Conversations.MessageContentType.Text

    conversationService.postMessage(activeConversation.id, messageType, content)
    setCurrentMessage('')
  }

  const handleLoadOlderMessages = async () => {
    if (isLoadingMore) return
    if (!activeConversation?.hasPrevMessages) return

    setLoadMorePosition('top')
    setIsLoadingMore(true)

    console.log('Handle load older messages', isLoadingMore, activeConversation?.hasPrevMessages)
    conversationService.loadConversationMessages(activeConversation?.id, Pto.Conversations.ReadMessageDirection.Backward, () => {
      setIsLoadingMore(false)
    })
  }

  const handleLoadNewerMessages = async () => {
    if (isLoadingMore) return
    if (!activeConversation?.hasNextMessages) return

    setLoadMorePosition('bottom')
    setIsLoadingMore(true)

    conversationService.loadConversationMessages(activeConversation?.id, Pto.Conversations.ReadMessageDirection.Forward, () => {
      setIsLoadingMore(false)
    })
  }

  const handleMessageBecameVisible = (message: ConversationMessageType<Pto.Conversations.MessageContentType>) => {
    if (activeConversation) {
      conversationService.messageBecameVisible(activeConversation.id, message)
      if (activeConversation.lastMessage?.id === message.id) {
        setIsAutoscrollToBottom(true)
      }
    }
  }

  const handleAttachmentMenuImageClicked = () => {
    console.log('image attachment')
    setIsAttachmentModalOpened(true)
    setIsAttachmentMenuVisible(false)
  }

  const handleAttachmentMenuVideoClicked = () => {
    console.log('video attachment')
    setIsAttachmentMenuVisible(false)
  }

  const handleAttachmentMenuDocumentClicked = () => {
    console.log('document attachment')
    setIsAttachmentMenuVisible(false)
  }

  const handleAttachmentMenuDesignApproveClicked = () => {
    console.log('design approve attachment')
    setIsAttachmentMenuVisible(false)
  }

  if (!activeConversation) return null

  const designRequestIdMeta = activeConversation.meta.find((item) => item.key === Pto.Conversations.ConversationMeta.DesignRequestId)
  const designRequestNumberMeta = activeConversation.meta.find((item) => item.key === Pto.Conversations.ConversationMeta.DesignRequestNumber)
  const projectIdMeta = activeConversation.meta.find((item) => item.key === Pto.Conversations.ConversationMeta.ProjectId)
  const projectNumberMeta = activeConversation.meta.find((item) => item.key === Pto.Conversations.ConversationMeta.ProjectNumber)

  const menuItems: MenuProps['items'] = []

  if (designRequestIdMeta) {
    menuItems.push({
      label: <Link to={`../${AppRoutes.designRequest}/${designRequestIdMeta.value}`}>Design request #{designRequestNumberMeta?.value}</Link>,
      key: designRequestIdMeta.value
    })
  }

  if (projectIdMeta) {
    menuItems.push({
      label: <Link to={`../${AppRoutes.projects}/${projectIdMeta.value}`}>Project #{projectNumberMeta?.value}</Link>,
      key: projectIdMeta.value
    })
  }

  return (
    <>
      <ChatContainer style={{ position: 'relative' }}>
        <ConversationHeader>
          <AvatarLocal
            /* @ts-expect-error Property AS doesn't exist */
            as="Avatar"
            name={activeConversation.name ? activeConversation.name[0] : 'C'}
            online={activeConversation.hasOnlineUsers}
            image={activeConversation.avatarUrl}
          />
          <ConversationHeader.Content userName={activeConversation?.name} info={`Active ${dayjs(activeConversation?.lastMessage?.createdAt).fromNow()}`} />
        </ConversationHeader>
        <MessageList
          autoScrollToBottom={!activeConversation?.hasNextMessages && isAutoscrollToBottom}
          autoScrollToBottomOnMount={false}
          onYReachStart={handleLoadOlderMessages}
          onYReachEnd={handleLoadNewerMessages}
          disableOnYReachWhenNoScroll={true}
          loadingMore={isLoadingMore}
          loadingMorePosition={loadMorePosition}
          typingIndicator={getTypingIndicator()}
        >
          {currentMessages.map((group) => {
            const user = getUser(group.senderId)

            return (
              //  @ts-expect-error Property AS doesn't exist
              <ConversationMessageGroup as="MessageGroup" group={group} user={user} onBecameVisible={handleMessageBecameVisible} key={group.id} />
            )
          })}
        </MessageList>
        <MessageInput
          placeholder="Type message here"
          value={currentMessage}
          disabled={!activeConversation || activeConversation.role === Pto.Conversations.Role.Guest}
          onChange={handleChange}
          onSend={(_text, content) => handleSend(content)}
          onAttachClick={() => setIsAttachmentMenuVisible(true)}
        />
        {isAttachmentMenuVisible && (
          <AttachmentMenu
            //  @ts-expect-error Property AS doesn't exist
            as="InputToolbox"
            onClose={() => setIsAttachmentMenuVisible(false)}
            onImageClicked={handleAttachmentMenuImageClicked}
            onVideoClicked={handleAttachmentMenuVideoClicked}
            onDocumentClicked={handleAttachmentMenuDocumentClicked}
            onDesignApproveClicked={handleAttachmentMenuDesignApproveClicked}
          />
        )}
      </ChatContainer>

      {activeConversation && <AttachmentModal conversationId={activeConversation.id} isOpened={isAttachmentModalOpened} onClose={() => setIsAttachmentModalOpened(false)} />}
    </>
  )
}

export default MessageListComponent
