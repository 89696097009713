import { Pto } from '@merchx-v3/pto'

import { store } from 'app/store'
import { usersApi } from 'app/api/users-api'
import { TokenProvider, tokenProvider } from './token-provider'
import { AuthProviderEventTarget, AuthProviderState, AuthProviderStateChanged, IAuthProvider, UserChangedEvent, UserSignInEvent, UserSignOutEvent } from './auth-provider.types'
import { AccessTokenChangedEvent, TokenProviderInitializedEvent } from './token-provider.types'
import { JwtSession } from './types'

class AuthProvider extends AuthProviderEventTarget implements IAuthProvider {
  private readonly _tokenProvider: TokenProvider
  private _accessToken: string | undefined
  private _user: Pto.Users.User | undefined

  private _state: AuthProviderState = 'INIT'
  private _error?: string

  constructor() {
    super()

    this._tokenProvider = tokenProvider

    this._tokenProvider.addEventListener('token-provider-initialized', this.onTokenProviderInitialized)
  }

  get error(): string | undefined {
    return this._error
  }

  get state(): AuthProviderState {
    return this._state
  }

  get isLoggedIn(): boolean {
    return !!this._user
  }

  private async setAccessToken(newAccessToken: string | undefined) {
    this._accessToken = newAccessToken

    const isUserSigningIn = newAccessToken && !this._user
    const isUserSigningOut = !newAccessToken && this._user
    const isUserUpdating = newAccessToken && this._user

    try {
      this._state = 'PROCESSING'
      this.dispatchEvent(new AuthProviderStateChanged(this._state))

      // Юзер входит в систему
      if (isUserSigningIn) {
        await this.handleUserSignIn(newAccessToken)
      }

      // Юзер выходит из системы
      if (isUserSigningOut) {
        await this.handleUserSignOut()
      }

      // Данные юзера поменялись
      if (isUserUpdating) {
        await this.handleUserUpdated(newAccessToken)
      }

      this._state = 'READY'
      this.dispatchEvent(new AuthProviderStateChanged(this._state))
    } catch (exception) {
      this._state = 'ERROR'
      this._error = (exception as Error).message
      console.log('Auth provider: error', this._error)
      this.dispatchEvent(new AuthProviderStateChanged(this._state))
    }
  }

  getUser = () => {
    return this._user
  }

  // #start-section(collapsed) signIn(accessToken, refreshToken, isRememberMe)
  signIn = (accessToken: string, refreshToken: string, isRememberMe: boolean = false) => {
    this._tokenProvider.setAccessToken(accessToken)
    this._tokenProvider.setIsRememberMe(isRememberMe)
    this._tokenProvider.setRefreshToken(refreshToken)
    store.dispatch(usersApi.util.invalidateTags([{ type: 'Users', id: 'CLAIMS' }]))
  }
  // #end-section

  // #start-section(collapsed) signOut()
  signOut = () => {
    this._tokenProvider.setRefreshToken()
    this._tokenProvider.setAccessToken()
    this._tokenProvider.setIsRememberMe(false)
    store.dispatch(usersApi.util.invalidateTags([{ type: 'Users', id: 'CLAIMS' }]))
  }
  // #end-section

  // #start-section(collapsed) ON TOKEN PROVIDER INITIALIZED
  private onTokenProviderInitialized = async (event: TokenProviderInitializedEvent) => {
    this._tokenProvider.addEventListener('access-token-changed', this.onAccessTokenChanged)
    console.log('Auth provider: initialization started')

    await this.setAccessToken(event.detail)
    // if (event.detail) {
    //   this.handleUserSignIn(event.detail)
    // }
  }
  // #end-section

  // #start-section(collapsed) ON ACCESS TOKEN CHANGED
  private onAccessTokenChanged = async (event: AccessTokenChangedEvent) => {
    if (this._accessToken !== event.detail) {
      console.log('Auth provider: on access token changed')
      // const newAccessToken = event.detail
      await this.setAccessToken(event.detail)
    }
  }
  // #end-section

  // #start-section(collapsed) HANDLE USER SIGN IN
  private handleUserSignIn = async (accessToken: string) => {
    console.log('Auth provider: handle user sign in started')

    const sessionUser = this.decodeJwtSession(accessToken)

    const claims = await this.getUserClaims()

    this._user = {
      id: sessionUser.userId,
      firstName: sessionUser.firstName,
      lastName: sessionUser.lastName,
      number: sessionUser.userNumber,
      avatarUrl: sessionUser.avatarUrl,
      email: sessionUser.email,
      roles: sessionUser.roles,
      claims
    }

    this.dispatchEvent(new UserSignInEvent(this._user))

    console.log('Auth provider: handle user sign in completed')
  }
  // #end-section

  // #start-section(collapsed) HANDLE USER UPDATED
  private handleUserUpdated = async (accessToken: string) => {
    if (!this._user) return

    const sessionUser = this.decodeJwtSession(accessToken)

    if (this._user.id === sessionUser.userId) return // Это тот же юзер, нам нечего делать с ним

    await this.handleUserSignOut()
    await this.handleUserSignIn(accessToken)
  }
  // #end-section

  // #start-section(collapsed) HANDLE USER SIGN OUT
  private handleUserSignOut() {
    if (!this._user) return

    console.log('Auth provider: handle user sign out started')

    this.dispatchEvent(new UserSignOutEvent(this._user))
    this._user = undefined

    console.log('Auth provider: handle user sign out completed')
  }
  // #end-section

  // #start-section(collapsed) getUserClaims
  private getUserClaims = async () => {
    let result: Pto.Auth.ActionClaim[] = []
    const accessToken = await tokenProvider.getAccessToken()

    try {
      result =
        ((await fetch('/api/users/get-claims', {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
          method: 'GET'
        }).then((res) => res.json())) as Pto.Auth.ActionClaim[]) || []
    } catch (exception) {
      console.error('Get user claims', exception)
    }

    return result
  }
  // #end-section

  // #start-section(collapsed) decodeJwtSession
  private decodeJwtSession = (accessToken: string) => {
    return JSON.parse(atob(accessToken.split('.')[1])) as JwtSession
  }
  // #end-section
}

const authProvider = new AuthProvider()

export { authProvider }
