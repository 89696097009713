import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { WebSocket } from '@merchx-v3/web-socket'
import { Pto } from '@merchx-v3/pto'

import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { store } from 'app/store'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

export const ecomProductsApi = createApi({
  reducerPath: 'ecomProductsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['EcomProducts'],

  endpoints: (builder) => ({
    ecomProduct: builder.query<Pto.EcomProducts.EcomProduct, string | undefined>({
      query: (productId) => `ecom-products/${productId}`,
      providesTags: (result) => [{ type: 'EcomProducts', id: result?.id }]
    }),
    createEcomProduct: builder.mutation<void, Pto.EcomProducts.CreateUpdate>({
      query: (createEcomProductDto) => {
        return {
          url: '/ecom-products',
          body: createEcomProductDto,
          method: 'POST'
        }
      }
    }),
    updateEcomProduct: builder.mutation<void, Pto.EcomProducts.CreateUpdate>({
      query: (updateEcomProductDto) => {
        return {
          url: '/ecom-products',
          body: updateEcomProductDto,
          method: 'PATCH'
        }
      }
    }),
    ecomProductList: builder.query<Pto.EcomProducts.List, Pto.EcomProducts.EcomProductListQuery>({
      query: ({ searchText, page, size }) => ({
        url: 'ecom-products',
        params: { searchText, page, size }
      }),
      providesTags: (ecomProductListData, _error, _args) =>
        getProvidedTags(
          'EcomProducts',
          'LIST',
          ecomProductListData?.items.map((item) => item.id)
        )
    }),
    ecomProductOptions: builder.query<Pto.Option[], Pto.EcomProducts.EcomProductOptionsQuery>({
      query: ({ searchText, page, size }) => ({
        url: '/ecom-products/options',
        params: { searchText, page, size }
      }),
      providesTags: (ecomProductOptionsData, _error, _args) =>
        getProvidedTags(
          'EcomProducts',
          'OPTIONS',
          ecomProductOptionsData?.map((item) => item.value)
        )
    }),
    ecomTagsOptions: builder.query<string[], Pto.EcomProducts.EcomProductTagsQuery>({
      query: (searchText) => ({
        url: '/ecom-products/tags',
        params: { searchText, size: 20 }
      }),
      providesTags: (ecomTagsOptionsData, _error, _args) =>
        getProvidedTags(
          'EcomProducts',
          'TAGS',
          ecomTagsOptionsData?.map((item) => item)
        )
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Ecom product') {
    store.dispatch(ecomProductsApi.util.invalidateTags([{ type: 'EcomProducts', id: payload.entityId }]))
  }
}

export const subscribeToEcomProductEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromEcomProductEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const {
  useEcomProductQuery,
  useLazyEcomProductQuery,
  useCreateEcomProductMutation,
  useUpdateEcomProductMutation,
  useEcomProductListQuery,
  useEcomProductOptionsQuery,
  useEcomTagsOptionsQuery
} = ecomProductsApi
