import { lazy } from 'react'
import { Pto, Role } from '@merchx-v3/pto'
import { Navigate, Route, Routes } from 'react-router-dom'

import { useUser } from 'app/auth'
import PrivateRoute from 'components/PrivateRoute'
import NotFoundPage from 'components/NotFoundPage'
import Layout from '../Layout'
import AppRoutes from './AppRoutes'

// SUPPLIERS

const SupplierSettingsPage = lazy(() => import('interfaces/suppliers/features/settings/pages/SupplierSettingsPage'))
const SupplierProductList = lazy(() => import('interfaces/suppliers/features/supplierProducts/pages/SupplierProductList'))
const SupplierProduct = lazy(() => import('interfaces/suppliers/features/supplierProducts/pages/SupplierProduct'))

// from shared pages
const EditDesignTemplate = lazy(() => import('interfaces/sharedPages/EditDesignTemplate'))
const EditProcessingTemplate = lazy(() => import('interfaces/suppliers/features/processingTemplates/pages/EditProcessingTemplate'))
const EditSupplierPluginDetails = lazy(() => import('interfaces/sharedPages/EditPluginDetails'))
const FulfillmentTask = lazy(() => import('interfaces/sharedPages/FulfillmentTask'))
const FulfillmentTaskHistory = lazy(() => import('interfaces/sharedPages/FulfillmentHistory'))
const ProfilePage = lazy(() => import('interfaces/sharedPages/ProfilePage'))
const ShippingTask = lazy(() => import('interfaces/sharedPages/ShippingTask'))
const ShippingHistory = lazy(() => import('interfaces/sharedPages/ShippingHistory'))
const SuppliersFulfillmentList = lazy(() => import('interfaces/sharedPages/FulfillmentList'))
const SuppliersShippingList = lazy(() => import('interfaces/sharedPages/ShippingList'))

const Router = () => {
  const [user, isLoading] = useUser()

  if (
    user &&
    !user.roles.includes(Role.SystemAdmin) &&
    !user.claims.some((claim) => claim.claim === Pto.Auth.Claim.SupplierPortal && [Pto.Auth.AccessLevel.AllowAll, Pto.Auth.AccessLevel.LimitedAccess].includes(claim.accessLevel))
  ) {
    return <Navigate to="/" />
  }

  if (!user && !isLoading) return <Navigate to="/login" />

  return (
    <Layout shouldWaitUser>
      <Routes>
        <Route path={AppRoutes.main} element={<PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}></PrivateRoute>} />
        <Route
          path={AppRoutes.filfillment}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SuppliersFulfillmentList />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.filfillment}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SuppliersFulfillmentList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.filfillment}/:fulfillmentTaskId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <FulfillmentTask />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.filfillment}/:fulfillmentTaskId/history`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <FulfillmentTaskHistory />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.main}/:supplierId/${AppRoutes.designTemplates}/:templateId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <EditDesignTemplate />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.main}/:supplierId/${AppRoutes.processingTemplates}/:templateId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <EditProcessingTemplate />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.main}/:supplierId/:pluginType/create`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <EditSupplierPluginDetails isNew />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.main}/:supplierId/:pluginType/:pluginId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <EditSupplierPluginDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.shipping}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SuppliersShippingList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.shipping}/:shippingTaskId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ShippingTask />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.shipping}/:shippingTaskId/history`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ShippingHistory />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.settings}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierSettingsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.supplierProducts}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierProductList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.supplierProducts}/:supplierProductId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierProduct />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.profile}`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ProfilePage />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Layout>
  )
}

export default Router
