import { InMemoryStorage, LocalStorage, SessionStorage } from './storage'
import { AccessTokenChangedEvent, ITokenProvider, RefreshTokenChangedEvent, TokenProviderEventTarget, TokenProviderInitializedEvent } from './token-provider.types'
import { IAuthStorage, JwtDates } from './types'

export class TokenProvider extends TokenProviderEventTarget implements ITokenProvider {
  private _state: 'INIT' | 'READY'
  private readonly _storage: IAuthStorage
  private readonly _sessionStorage: IAuthStorage
  private readonly _localStorage: IAuthStorage
  private _refreshTimeout: NodeJS.Timeout | undefined = undefined

  get state(): 'INIT' | 'READY' {
    return this._state
  }

  private get _accessToken(): string | undefined {
    return this._storage.getItem('access-token')
  }

  private get _refreshToken(): string | undefined {
    const storage = this._isRememberMe ? this._localStorage : this._sessionStorage
    return storage.getItem('refresh-token')
  }

  private get _isRememberMe(): boolean {
    return this._localStorage.getItem('is-remember-me') === 'true'
  }

  constructor() {
    super()
    this._state = 'INIT'
    this._storage = new InMemoryStorage()
    this._sessionStorage = new SessionStorage()
    this._localStorage = new LocalStorage()

    this.restore().then(() => {
      console.log(`Token provider: restore completed`)

      this._state = 'READY'
      this.dispatchEvent(new TokenProviderInitializedEvent(this._accessToken))
    })
  }

  // #start-section(collapsed) restore()
  private async restore() {
    console.log(`Token provider: restore started`)

    await this.updateTokens()
  }
  // #end-section

  // ACCESS TOKEN

  // #start-section(collapsed) getAccessToken()
  public getAccessToken = async () => {
    if (!this._accessToken && !this._refreshToken) return undefined

    if (!this._accessToken && this._refreshToken) {
      await this.updateTokens()
    }

    if (this.isExpired(this.getExpirationDate(this._accessToken))) {
      await this.updateTokens()
    }

    return this._accessToken
  }
  // #end-section

  // #start-section(collapsed) setAccessToken(newAcessToken)
  public setAccessToken = (newAccessToken?: string) => {
    if (this._refreshTimeout) clearTimeout(this._refreshTimeout)

    if (newAccessToken && newAccessToken !== this._accessToken) {
      const newTokenExpireDates = this.getExpirationDate(newAccessToken)
      const startTime = Date.now()

      if (newTokenExpireDates?.exp && newTokenExpireDates.exp - 30000 > startTime) {
        const timeoutTillRefreshToken = newTokenExpireDates.exp - 30000 - startTime
        this._refreshTimeout = setTimeout(this.updateTokens, timeoutTillRefreshToken)
      }
      this._storage.setItem('access-token', newAccessToken)
    } else {
      this._storage.removeItem('access-token')
    }

    if (this._state === 'READY') {
      console.log(`Token provider: dispatch access token changed`)
      this.dispatchEvent(new AccessTokenChangedEvent(newAccessToken))
    }
  }
  // #end-section

  // REFRESH TOKEN

  // #start-section(collapsed) getRefreshToken()
  public getRefreshToken = () => {
    return this._refreshToken
  }
  // #end-section

  // #start-section(collapsed) setRefreshToken(newRefreshToken)
  public setRefreshToken = (newRefreshToken?: string) => {
    this._sessionStorage.removeItem('refresh-token')
    this._localStorage.removeItem('refresh-token')

    const storage = this._isRememberMe ? this._localStorage : this._sessionStorage
    if (newRefreshToken) {
      storage.setItem('refresh-token', newRefreshToken)
    }

    if (this._state === 'READY') {
      console.log(`Token provider: dispatch refresh token changed`)
      this.dispatchEvent(new RefreshTokenChangedEvent(newRefreshToken))
    }
  }
  // #end-section

  // PUBLIC HELPERS

  // #start-section(collapsed) setIsRememberMe(isRememberMe)
  public setIsRememberMe = (isRememberMe: boolean = false) => {
    this._localStorage.setItem('is-remember-me', `${isRememberMe}`)
  }
  // #end-section

  // #start-section(collapsed) prepareHeaders()
  prepareHeaders = async () => {
    const headers = new Headers()
    const accessToken = await this.getAccessToken()
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`)
      return headers
    }

    return headers
  }
  // #end-section

  // PRIVATE HELPERS

  // #start-section(collapsed) updateTokens()
  private updateTokens: () => Promise<void> = async () => {
    if (this._refreshToken) {
      try {
        const data = (await fetch('/api/auth/refresh-token', {
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ refreshToken: this._refreshToken }),
          method: 'POST'
        }).then((res) => res.json())) as { token: string; refreshToken: string }
        if (data.refreshToken) this.setRefreshToken(data.refreshToken)
        if (data.token) this.setAccessToken(data.token)
      } catch (exception) {
        console.log(`Token provider: refresh token error`, exception)
      }
    }
  }
  // #end-section

  // #start-section(collapsed) getExpirationDate(jwtToken)
  private getExpirationDate(jwtToken?: string): JwtDates | undefined {
    let expirationDates: JwtDates | undefined

    if (!jwtToken) return undefined

    try {
      const jwt = JSON.parse(atob(jwtToken.split('.')[1])) as JwtDates

      if (!jwt?.exp) return undefined

      expirationDates = {
        iat: jwt.iat * 1000,
        exp: jwt.exp * 1000
      }
    } catch (exception) {
      console.error('Cannot parse jwt token')
    }

    return expirationDates
  }
  // #end-section

  // #start-section(collapsed) isExpired(jwtDates)
  private isExpired(jwtDates?: JwtDates): boolean {
    let isExpired = false

    if (jwtDates) {
      const currentDate = Date.now()
      isExpired = currentDate > jwtDates.exp - 30000 && currentDate > jwtDates.iat
    }

    return isExpired
  }
  // #end-section
}

const tokenProvider = new TokenProvider()
export { tokenProvider }
