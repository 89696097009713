import { toast } from 'react-toastify'
import { Form, Input, Modal, Typography } from 'antd'
import { Pto } from '@merchx-v3/pto'
import { useUnbanUserMutation } from 'app/api/users-api'

type Props = {
  user: Pto.Users.ListItem
  isModalOpened: boolean
  onClose: () => void
}

type FormType = {
  reason: string
}

const UnbanUserModal = ({ user, isModalOpened, onClose }: Props) => {
  const [form] = Form.useForm<FormType>()

  const [unbanUser, { isLoading }] = useUnbanUserMutation()

  const handleUnban = ({ reason }: FormType) => {
    unbanUser({ userId: user.id, reason })
    toast.success('User unbaned successfully!')
    onClose()
  }

  return (
    <Modal forceRender open={isModalOpened} onCancel={onClose} centered title="Unban User" okText="Unban" onOk={form.submit} confirmLoading={isLoading}>
      <Form name="unbanInfo" layout="vertical" form={form} onFinish={handleUnban}>
        <Typography style={{ fontWeight: 'bold', marginBottom: 8 }}> User Email: {user.email}</Typography>
        <Form.Item label="Reason" name="reason" rules={[{ required: true, message: 'Please input Unban Reason!' }]}>
          <Input placeholder="Reason" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default UnbanUserModal
