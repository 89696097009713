import { Pto } from '@merchx-v3/pto'
import { WebSocket } from '@merchx-v3/web-socket'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { store } from 'app/store'
import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

export const processingTemplatesApi = createApi({
  reducerPath: 'processingTemplatesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['ProcessingTemplates', 'RushOptions'],
  endpoints: (builder) => ({
    processingTemplate: builder.query<Pto.ProcessingTemplates.ProcessingTemplate, string>({
      query: (templateId) => `/processing-templates/${templateId}`,
      providesTags: (_result, _error, templateId) => getProvidedTags('ProcessingTemplates', templateId)
    }),
    processingTemplateList: builder.query<Pto.ProcessingTemplates.List, Pto.ProcessingTemplates.GetListArgs>({
      query: (params) => ({
        url: '/processing-templates',
        params
      }),
      providesTags: (processingTemplateListData, _error, _args) =>
        getProvidedTags(
          'ProcessingTemplates',
          'LIST',
          processingTemplateListData?.items.map((item) => item.id)
        )
    }),
    processingTemplateOptions: builder.query<Pto.Option[], Pto.ProcessingTemplates.GetOptions>({
      query: (params) => ({
        url: '/processing-templates/options',
        params
      }),
      providesTags: (processingTemplateOptionsData, _error, _args) =>
        getProvidedTags(
          'ProcessingTemplates',
          'OPTIONS',
          processingTemplateOptionsData?.map((item) => item.value)
        )
    }),
    createProcessingTemplate: builder.mutation<void, Pto.ProcessingTemplates.CreateUpdateProcessingTemplate>({
      query: (createTemplateDto) => ({
        url: '/processing-templates',
        body: createTemplateDto,
        method: 'POST'
      })
    }),
    updateProcessingTemplate: builder.mutation<string, Pto.ProcessingTemplates.CreateUpdateProcessingTemplate>({
      query: (updateTemplateDto) => ({
        url: '/processing-templates',
        body: updateTemplateDto,
        method: 'PATCH'
      })
    }),
    rushOptions: builder.query<Pto.Option[], Pto.ProcessingTemplates.GetRushOptions>({
      query: (params) => ({
        url: `/processing-templates/rush-options`,
        params
      }),
      providesTags: (rushOptionsData, _error, _args) =>
        getProvidedTags(
          'RushOptions',
          'OPTIONS',
          rushOptionsData?.map((item) => item.value)
        )
    }),
    getUniqRushOptions: builder.query<string[], string>({
      query: (searchText) => ({
        url: '/processing-templates/uniq-rush-options',
        params: { searchText }
      })
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Processing template') {
    store.dispatch(processingTemplatesApi.util.invalidateTags([{ type: 'ProcessingTemplates', id: payload.entityId }]))
  }
}

export const subscribeToProcessingTemplateEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromProcessingTemplateEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const {
  useProcessingTemplateQuery,
  useLazyProcessingTemplateQuery,
  useProcessingTemplateOptionsQuery,
  useProcessingTemplateListQuery,
  useCreateProcessingTemplateMutation,
  useUpdateProcessingTemplateMutation,
  useRushOptionsQuery,
  useGetUniqRushOptionsQuery
} = processingTemplatesApi
