import { Pto } from '@merchx-v3/pto'

const artworkFieldToPreview = (field: Pto.Plugins.Fields.IField<Pto.Plugins.ValueType.Artworks>): Pto.Option[] => {
  return [
    { label: field.displayName + ' Front side', value: field.value.frontSide },
    { label: field.displayName + ' Back side', value: field.value.backSide }
  ]
}

const booleanFieldToPreview = (field: Pto.Plugins.Fields.IField<Pto.Plugins.ValueType.Boolean>): Pto.Option[] => {
  return [{ label: field.displayName, value: field.value + '' }]
}

const dependencyFieldToPreview = (field: Pto.Plugins.Fields.IField<Pto.Plugins.ValueType.Dependency>): Pto.Option[] => {
  return field.value.fields.map((fieldItem) => ({ label: fieldItem.key, value: fieldItem.label }))
}

const digitsFieldToPreview = (field: Pto.Plugins.Fields.IField<Pto.Plugins.ValueType.Float | Pto.Plugins.ValueType.Integer>): Pto.Option[] => {
  return [{ label: field.displayName, value: field.value + '' }]
}

const stringFieldToPreview = (field: Pto.Plugins.Fields.IField<Pto.Plugins.ValueType.String>): Pto.Option[] => {
  return [{ label: field.displayName, value: field.value }]
}

const selectFieldToPreview = (field: Pto.Plugins.Fields.IField<Pto.Plugins.ValueType.Select>): Pto.Option[] => {
  return [{ label: field.displayName, value: field.value.value }]
}

const supplierSelectFieldToPreview = (field: Pto.Plugins.Fields.IField<Pto.Plugins.ValueType.SupplierSelect>): Pto.Option[] => {
  return [{ label: field.displayName, value: field.value.recordLabel }]
}

const mapper: Record<Pto.Plugins.ValueType, Function> = {
  [Pto.Plugins.ValueType.Artworks]: artworkFieldToPreview,
  [Pto.Plugins.ValueType.Boolean]: booleanFieldToPreview,
  [Pto.Plugins.ValueType.Dependency]: dependencyFieldToPreview,
  [Pto.Plugins.ValueType.Float]: digitsFieldToPreview,
  [Pto.Plugins.ValueType.Integer]: digitsFieldToPreview,
  [Pto.Plugins.ValueType.JSON]: stringFieldToPreview,
  [Pto.Plugins.ValueType.Select]: selectFieldToPreview,
  [Pto.Plugins.ValueType.String]: stringFieldToPreview,
  [Pto.Plugins.ValueType.SupplierSelect]: supplierSelectFieldToPreview
}

export const pluginFieldToPreview = <T extends Pto.Plugins.ValueType>(field: Pto.Plugins.Fields.IField<T>): Pto.Option[] => {
  return mapper[field.valueType](field)
}
