import React from 'react'
import { Select } from 'antd'
import useDebounce from 'helpers/useDebounce'
import { useTagOptionsQuery } from 'app/api/fulfillment-tasks-api'

type Props = {
  placeholder?: string[]
  value?: string[]

  onChange?: (value: string[]) => void
}

const FulfillmentTagsMultipleOptions = (props: Props) => {
  const { placeholder = 'Tags', value, onChange } = props

  const [searchText, setSearchText] = React.useState<string>('')
  const debouncedSearchText = useDebounce(searchText, 500)

  const { data: tagOptions } = useTagOptionsQuery({ searchText: debouncedSearchText, size: 30 })

  const handleSearch = (value: string) => {
    setSearchText(value)
  }

  const handleChange = (value: string) => {
    onChange && onChange(value as unknown as string[])
    setSearchText('')
  }

  if (true) {
    return (
      <Select
        mode="multiple"
        placeholder={placeholder || ''}
        style={{ width: '100%' }}
        onChange={handleChange}
        onSearch={handleSearch}
        showSearch
        value={value as unknown as string}
        searchValue={searchText}
        options={tagOptions}
      />
    )
  }
}

export default FulfillmentTagsMultipleOptions
