import { useRef } from 'react'
import type { MutableRefObject } from 'react'

import { IConversationService } from '../conversation-service'
import { IConversationStorage } from '../conversation-storage'
import { ConversationServiceFactory, UpdateState } from './types'

export function useLazyServiceFactoryRef(serviceFactory: ConversationServiceFactory, storage: IConversationStorage, update: UpdateState) {
  const ref = useRef<IConversationService>()

  if (typeof ref.current === 'undefined') {
    ref.current = serviceFactory(storage, update)
  }

  return ref as MutableRefObject<IConversationService>
}
