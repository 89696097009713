import { lazy } from 'react'
import { Pto, Role } from '@merchx-v3/pto'
import { Navigate, Route, Routes } from 'react-router-dom'

import { useUser } from 'app/auth'
import PrivateRoute from 'components/PrivateRoute'
import NotFoundPage from 'components/NotFoundPage'
import Layout from '../Layout'
import AppRoutes from './AppRoutes'

// MANAGERS

// from shared pages
const Chats = lazy(() => import('interfaces/sharedComponents/Chat/DesktopChat'))
const CustomerList = lazy(() => import('interfaces/sharedPages/CustomerList'))
const CustomersReport = lazy(() => import('interfaces/sharedPages/CustomersReport'))
const CustomerDetails = lazy(() => import('interfaces/sharedPages/CustomerDetails'))
const ProjectList = lazy(() => import('interfaces/sharedPages/ProjectList'))
const EditProject = lazy(() => import('interfaces/sharedPages/EditProject'))
const EcomProductList = lazy(() => import('interfaces/sharedPages/EcomProductList'))
const EcomProduct = lazy(() => import('interfaces/sharedPages/EcomProduct'))
const DesignRequest = lazy(() => import('interfaces/sharedPages/DesignRequest'))
const DesignRequestHistory = lazy(() => import('interfaces/sharedPages/DesignRequestHistory'))
const DesignRequestList = lazy(() => import('interfaces/sharedPages/DesignRequestList'))
const Design = lazy(() => import('interfaces/sharedPages/Design'))
const DesignList = lazy(() => import('interfaces/sharedPages/DesignList'))
const InvoiceDetails = lazy(() => import('interfaces/sharedPages/InvoiceDetails'))
const FulfillmentTask = lazy(() => import('interfaces/sharedPages/FulfillmentTask'))
const FulfillmentTaskHistory = lazy(() => import('interfaces/sharedPages/FulfillmentHistory'))
const ProjectHistory = lazy(() => import('interfaces/sharedPages/ProjectHistory'))
const ProfilePage = lazy(() => import('interfaces/sharedPages/ProfilePage'))

const Router = () => {
  const [user, isLoading] = useUser()

  if (
    user &&
    !user.roles.includes(Role.SystemAdmin) &&
    !user.claims.some((claim) => claim.claim === Pto.Auth.Claim.ManagerPortal && [Pto.Auth.AccessLevel.AllowAll, Pto.Auth.AccessLevel.LimitedAccess].includes(claim.accessLevel))
  ) {
    return <Navigate to="/" />
  }

  if (!user && !isLoading) return <Navigate to="/login" />

  return (
    <Layout shouldWaitUser>
      <Routes>
        <Route path={AppRoutes.main} element={<PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}></PrivateRoute>} />
        <Route
          path={AppRoutes.customerReport}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <CustomersReport />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.customers}/:customerId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <CustomerDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.customers}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <CustomerList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.ecomProducts}/:ecomProductId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <EcomProduct />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.ecomProducts}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <EcomProductList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.profile}`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ProfilePage />
            </PrivateRoute>
          }
        />{' '}
        <Route
          path={`${AppRoutes.projects}/:projectId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <EditProject />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.projects}/:projectId/history`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ProjectHistory />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.projects}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ProjectList />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.designs}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <DesignList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.designs}/:designId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <Design />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.designRequest}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <DesignRequestList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.designRequest}/:designRequestId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <DesignRequest />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.designRequest}/:designRequestId/history`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <DesignRequestHistory />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.invoices}/:invoiceId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <InvoiceDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.fulfillment}/:fulfillmentTaskId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <FulfillmentTask />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.fulfillment}/:fulfillmentTaskId/history`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <FulfillmentTaskHistory />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.chats}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <Chats />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Layout>
  )
}

export default Router
