import { TagDescription } from '@reduxjs/toolkit/dist/query'
import { FullTagDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions'

export const getProvidedTags = <T extends TagDescription<string>>(tagName: T, sharedValue?: string, objectIds = [] as (string | number)[]) => {
  const result: FullTagDescription<T>[] = []

  if (sharedValue) {
    result.push({ type: tagName, id: sharedValue })
  }

  for (const id of objectIds) {
    result.push({ type: tagName, id })
  }

  return result
}
