import { useEffect, useState } from 'react'
import { FloatButton, Tooltip, Typography } from 'antd'
import { CloseOutlined, CommentOutlined, PlusOutlined } from '@ant-design/icons'
import { ConversationList } from './ConversationList'
import { MessageList } from './MessageList'
import styles from './FloatChat.module.scss'
import { useLocation } from 'react-router-dom'
import { useConversation } from 'app/chat/context/context'
import { AddNewConversationModal } from './AddNewConversationModal'

export const FloatChat = () => {
  const location = useLocation()

  const [isAddNewChatModalVisible, setAddNewChatModalVisible] = useState<boolean>(false)

  const shouldHideFloatChatButton =
    location.pathname.includes('designers/chats') ||
    location.pathname.includes('managers/chats') ||
    location.pathname.includes('customers/conversations') ||
    !!location.pathname.match(/designers\/design-requests\/(.*)/)

  const [isFloabButtonVisible, setIsFloatButtonVisible] = useState<boolean>(true)
  const [isConversationListVisible, setIsConversationListVisible] = useState<boolean>(false)

  const { isOnline, isConversationsListLoaded, activeConversation, setActiveConversation, totalUnreadMessages } = useConversation()

  useEffect(() => {
    if (shouldHideFloatChatButton && isFloabButtonVisible) {
      setIsFloatButtonVisible(false)
    } else {
      setActiveConversation('')
      setIsConversationListVisible(false)
      setIsFloatButtonVisible(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldHideFloatChatButton])

  const handleCloseChat = () => {
    setActiveConversation('')
    setIsConversationListVisible(!isConversationListVisible)
  }

  if (!isFloabButtonVisible || !isOnline || !isConversationsListLoaded) return null

  return (
    <div className={styles.container}>
      {<FloatButton type="primary" icon={<CommentOutlined />} onClick={handleCloseChat} style={{ width: 60, height: 60 }} badge={{ count: totalUnreadMessages }} />}

      {isConversationListVisible && (
        <div className={styles.listBox}>
          <div className={styles.titleBox}>
            <Typography className={styles.title}>Conversations</Typography>
            <div className={styles.titleIcons}>
              <Tooltip title="New Chat">
                <PlusOutlined style={{ color: '#1677FF' }} onClick={() => setAddNewChatModalVisible(true)} />
              </Tooltip>
              <CloseOutlined onClick={handleCloseChat} />
            </div>
          </div>
          <ConversationList height="438px" />
        </div>
      )}

      {isConversationListVisible && activeConversation && (
        <div className={styles.chatBox}>
          <CloseOutlined className={styles.closeIcon} onClick={() => setActiveConversation('')} />
          <MessageList />
        </div>
      )}
      <AddNewConversationModal isOpen={isAddNewChatModalVisible} onClose={() => setAddNewChatModalVisible(false)} />
    </div>
  )
}
