import { Pto } from '@merchx-v3/pto'
import { Typography, UploadFile } from 'antd'
import { Column, Row } from 'components'
import React, { useEffect, useState } from 'react'
import { UploadFilesV2 } from 'components/UploadFilesV2'
import { RcFile } from 'antd/es/upload'

import { useGetMaxArtworkFilesLengthQuery } from 'app/api/plugins-api'

type Props = {
  designRequest: Pto.DesignRequests.DesignRequest
  artwork: Pto.DesignRequests.Artwork
  onArtworkUploaded: (artwork: Pto.DesignRequests.UpdateArtworkFiles) => Promise<boolean>
  onArtworkMockupUrlUploaded: (mockup: Pto.DesignRequests.UploadArtworkMockupUrl) => Promise<boolean>
}

export const DesignRequestArtworkCardItem: React.FunctionComponent<Props> = (props) => {
  const { designRequest, artwork, onArtworkUploaded, onArtworkMockupUrlUploaded } = props

  const { printArea, projectProductItemId, artworkUrls, mockupUrl } = artwork

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [artworks, setArtworks] = useState<UploadFile<RcFile>[]>([])
  const [mockupUrls, setMockupUrls] = useState<UploadFile<RcFile>[]>([])

  const { data: maxArtworkFilesLength } = useGetMaxArtworkFilesLengthQuery(projectProductItemId)

  useEffect(() => {
    if (artworkUrls.length) {
      const newArtworks = artworkUrls.map((artworkUrl) => ({
        uid: artworkUrl,
        name: printArea,
        url: artworkUrl,
        thumbUrl: `/api/images/${artworkUrl}?width=200&height=200`
      }))
      setArtworks(newArtworks)
    } else {
      setArtworks([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artworkUrls])

  useEffect(() => {
    if (mockupUrl) {
      setMockupUrls([{ uid: mockupUrl, name: printArea, url: mockupUrl, thumbUrl: `/api/images/${mockupUrl}?width=200&height=200` }])
    } else {
      setMockupUrls([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mockupUrl])

  const handleUploadArtworkFile = async (artworkFiles: Pto.DesignRequests.UpdateArtworkFiles) => {
    setIsLoading(true)
    if (artworkFiles.artworkUrls.length) {
      const newArtworks = artworkFiles.artworkUrls.map((artworkUrl) => ({
        uid: artworkUrl,
        name: printArea,
        url: artworkUrl,
        thumbUrl: `/api/images/${artworkUrl}?width=200&height=200`
      }))
      setArtworks(newArtworks)
    }

    const isUploaded = await onArtworkUploaded(artworkFiles)
    if (!isUploaded) {
      if (artworkUrls.length) {
        const newArtworks = artworkUrls.map((artworkUrl) => ({
          uid: artworkUrl,
          name: printArea,
          url: artworkUrl,
          thumbUrl: `/api/images/${artworkUrl}?width=200&height=200`
        }))
        setArtworks(newArtworks)
      } else {
        setArtworks([])
      }
    }
    setIsLoading(false)

    return isUploaded
  }

  const handleUploadArtworkMockupUrl = async (mockup: Pto.DesignRequests.UploadArtworkMockupUrl) => {
    setIsLoading(true)
    if (mockup.mockupUrl) {
      setMockupUrls([{ uid: mockup.mockupUrl, name: mockup.printArea, url: mockup.mockupUrl, thumbUrl: `/api/images/${mockup.mockupUrl}?width=200&height=200` }])
    }

    const isUploaded = await onArtworkMockupUrlUploaded(mockup)
    if (!isUploaded) {
      if (mockupUrl) {
        setMockupUrls([{ uid: mockupUrl, name: printArea, url: mockupUrl, thumbUrl: `/api/images/${mockupUrl}?width=200&height=200` }])
      } else {
        setMockupUrls([])
      }
    }
    setIsLoading(false)

    return isUploaded
  }

  return (
    <Row>
      <Column>
        <Typography>{printArea} Mockup</Typography>
        <UploadFilesV2
          owner={Pto.Files.Owner.DesignRequest}
          ownerId={designRequest.id}
          type={Pto.Files.Type.Mockup}
          value={mockupUrls}
          isRemoveConfirm={true}
          disabled={isLoading}
          onChange={(imageList) => {
            handleUploadArtworkMockupUrl({
              projectProductItemId,
              printArea,
              mockupUrl: imageList[imageList.length - 1]?.url || ''
            })
          }}
        />
      </Column>
      <Column>
        <Typography>{printArea} Artwork</Typography>
        <UploadFilesV2
          owner={Pto.Files.Owner.DesignRequest}
          ownerId={designRequest.id}
          type={Pto.Files.Type.Artwork}
          value={artworks}
          isRemoveConfirm={true}
          maxAllowedFilesLength={maxArtworkFilesLength}
          disabled={isLoading}
          onChange={(imageList) => {
            handleUploadArtworkFile({
              projectProductItemId,
              printArea,
              artworkUrls: imageList?.map((item) => item.url || '') || []
            })
          }}
        />
      </Column>
    </Row>
  )
}
