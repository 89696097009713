import { connect, ConnectedProps } from 'react-redux'
import { RootState } from 'app/store'

const appVersion = `3.1.81`

const mapStateToProps = (state: RootState) => {
  return {
    appVersion
  }
}

const mapDispatchToProps = {}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromRedux = ConnectedProps<typeof connector>
