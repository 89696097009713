import React from 'react'
import { sha1 } from 'object-hash'
import { Pto } from '@merchx-v3/pto'
import { Col, Row, Space, Typography } from 'antd'
import { useEffect, useState, forwardRef, useImperativeHandle, useRef, ElementRef } from 'react'
import { RequireClaim } from 'components'
import ArtworkField from './ArtworkField'
import BooleanField from './BooleanField'
import FloatField from './FloatField'
import IntegerField from './IntegerField'
import JSONField from './JSONField'
import SelectField from './SelectField'
import StringField from './StringField'
import SupplierSelectField from './SupplierSelectField'
import DependencyField from './DependencyField'
import { FieldComponentProps } from './types'

type Props = {
  pluginInfo: Pto.Option

  targetId?: string
  showTitle?: boolean
  supplierId?: string
  disabled?: boolean

  fields: Pto.Plugins.Fields.IField[]
  onChange: (updatedFields: Pto.Plugins.Fields.IField[]) => void
}

type RefProps = {
  validate: () => boolean
}

const mapValueType: Record<Pto.Plugins.ValueType, React.ForwardRefExoticComponent<FieldComponentProps<any> & React.RefAttributes<any>>> = {
  Artworks: ArtworkField,
  Boolean: BooleanField,
  String: StringField,
  Float: FloatField,
  Integer: IntegerField,
  JSON: JSONField,
  Select: SelectField,
  Dependency: DependencyField,
  [Pto.Plugins.ValueType.SupplierSelect]: SupplierSelectField
}

const PluginFields = forwardRef<RefProps, Props>((props, forwardedRef) => {
  const { pluginInfo, fields = [], showTitle = false, supplierId, targetId, disabled = false, onChange } = props

  const [localFields, setLocalFields] = useState<Pto.Plugins.Fields.IField[]>(fields)

  const pluginFieldsRefs = useRef<
    Array<
      ElementRef<
        | typeof ArtworkField
        | typeof BooleanField
        | typeof DependencyField
        | typeof StringField
        | typeof FloatField
        | typeof IntegerField
        | typeof JSONField
        | typeof SelectField
        | typeof SupplierSelectField
      >
    >
  >([])

  useImperativeHandle(forwardedRef, () => ({
    validate: () => {
      const isValid = pluginFieldsRefs.current.reduce((accum, field) => {
        return accum && field.validate()
      }, true)

      return isValid
    }
  }))

  useEffect(() => {
    if (sha1(fields) !== sha1(localFields)) {
      setLocalFields(fields)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sha1(fields)])

  const handleFieldValueChanged = (field: Pto.Plugins.Fields.IField, newValue: Pto.Plugins.Fields.FieldValue) => {
    const newListOfFields = fields.map((existField) => ({ ...existField, value: existField.id === field.id ? newValue : existField.value }))
    setLocalFields(newListOfFields)
    onChange(newListOfFields)
  }

  return (
    <Space size="large" direction="vertical" style={{ width: '100%' }}>
      {showTitle && <p>{pluginInfo.label}</p>}

      <Row gutter={[16, 16]}>
        {localFields.map((pluginField, index) => {
          const Component = mapValueType[pluginField.valueType]

          return (
            <Col span={10} key={pluginField.fieldKey} style={{ width: '100%' }}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Typography.Text>{pluginField.displayName}</Typography.Text>
                <Component
                  ref={(ref) => {
                    if (ref) {
                      pluginFieldsRefs.current[index] = ref
                    }
                  }}
                  disabled={disabled}
                  pluginInfo={pluginInfo}
                  field={pluginField}
                  supplierId={supplierId}
                  targetId={targetId}
                  onChange={(newValue) => handleFieldValueChanged(pluginField, newValue)}
                />
              </Space>
            </Col>
          )
        })}
      </Row>
    </Space>
  )
})

export default RequireClaim<Props, RefProps>(PluginFields, Pto.Auth.Claim.SaveSupplierPluginFieldsValues)
