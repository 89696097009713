import { ElementRef, useRef, useState } from 'react'
import { Button, Modal, Table, Typography, TableColumnsType, Popconfirm } from 'antd'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { Pto } from '@merchx-v3/pto'
import { transformUppercase } from 'helpers'
import AddModal from '../AddModal'
import styles from './SettingsTab.module.scss'

type Props = {
  data: Pto.Plugins.Fields.ISetting[]
  onCreateField: (productField: Pto.Plugins.Fields.ISetting) => void
  onUpdateField: (productField: Pto.Plugins.Fields.ISetting) => void
  onRemoveField: (fieldId: string) => void
  supplierSelectOptions: Pto.Option[]
  isLoading: boolean
}

const columns: (onRemove: (id: string) => void, isLoading: boolean) => TableColumnsType<Pto.Plugins.Fields.ISetting> = (onRemove, isLoading) => [
  {
    title: 'ID',
    dataIndex: 'id',
    render: (text: string, record, index: number) => index + 1
  },
  {
    title: 'Field Key ',
    dataIndex: 'fieldKey'
  },
  {
    title: 'Display Name',
    dataIndex: 'displayName'
  },
  {
    title: 'Type',
    dataIndex: 'valueType'
  },
  {
    title: 'Default',
    dataIndex: 'value'
  },
  {
    title: 'Is Required',
    dataIndex: 'isRequired',
    render: (text: string) => transformUppercase(text.toString())
  },
  {
    title: 'Action',
    width: '75px',
    render: (_, record) => (
      <Popconfirm
        disabled={isLoading}
        title="Sure to remove?"
        onConfirm={(e) => {
          e?.stopPropagation()
          onRemove(record.id)
        }}
        onCancel={(e) => e?.stopPropagation()}
      >
        <Typography.Link className={styles.removeLink} onClick={(e) => e?.stopPropagation()}>
          Remove
        </Typography.Link>
      </Popconfirm>
    )
  }
]

const SettingsTab = ({ data, onCreateField, onRemoveField, onUpdateField, isLoading, supplierSelectOptions }: Props) => {
  const [fieldToEdit, setFieldToEdit] = useState<Pto.Plugins.Fields.ISetting>()
  const [isAddItemModalVisible, setIsAddItemModalVisible] = useState<boolean>(false)

  const formRef = useRef<ElementRef<typeof AddModal>>(null)

  const handleCreateField = (field: Pto.Plugins.Fields.IField) => {
    onCreateField(field)
    setIsAddItemModalVisible(false)
  }
  const handleRecordEdited = (field: Pto.Plugins.Fields.IField) => {
    onUpdateField(field)
    setIsAddItemModalVisible(false)
    setFieldToEdit(undefined)
  }

  const handleRecordToEdit = (field: Pto.Plugins.Fields.ISetting) => {
    setFieldToEdit(field)
    setIsAddItemModalVisible(true)
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.btn}>
          <Button type="primary" icon={<PlusOutlined />} loading={isLoading} onClick={() => setIsAddItemModalVisible(true)}>
            ADD
          </Button>
        </div>
        <Table
          dataSource={data}
          rowKey={(row) => row.id}
          onRow={(record) => {
            return {
              onClick: () => handleRecordToEdit(record)
            }
          }}
          columns={columns(onRemoveField, isLoading)}
          pagination={false}
          bordered
          size="small"
          className={styles.table}
        />
      </div>

      <Modal
        open={isAddItemModalVisible}
        okButtonProps={{ loading: isLoading }}
        cancelButtonProps={{ loading: isLoading }}
        onCancel={() => setIsAddItemModalVisible(false)}
        okText="Save"
        onOk={() => formRef.current?.submit()}
      >
        <Typography.Title level={5}>Add Setting</Typography.Title>
        <AddModal
          supplierSelectOptions={supplierSelectOptions}
          field={fieldToEdit}
          ref={formRef}
          fieldRole={Pto.Plugins.Fields.FieldRole.Setting}
          onUpdateField={handleRecordEdited}
          onCreateField={handleCreateField}
        />
      </Modal>
    </div>
  )
}

export default SettingsTab
