import { Image } from 'antd'
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons'
import { Pto } from '@merchx-v3/pto'
import { Message } from '@chatscope/chat-ui-kit-react'

import { ConversationMessage } from 'app/chat/types'
import { MessagePosition } from './helpers'

import styles from './ImageMessage.module.scss'

type Props = {
  message: ConversationMessage<Pto.Conversations.MessageContentType.Image>
  position: MessagePosition
  user?: Pto.Conversations.User
}

export const ImageMessage = (props: Props) => {
  const { message, position } = props

  const onDownload = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, imgUrl: string) => {
    e.stopPropagation()
    fetch(imgUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]))
        const link = document.createElement<'a'>('a')
        link.href = url
        link.download = 'image.png'
        document.body.appendChild(link)
        link.click()
        URL.revokeObjectURL(url)
        link.remove()
      })
  }

  const mask = (
    <div className={styles.preview}>
      <EyeOutlined />
      <DownloadOutlined onClick={(e) => onDownload(e, `/images/${message.content.url}`)} />
    </div>
  )

  return (
    <Message
      id={message.id}
      type="custom"
      model={{
        direction: message.direction,
        position
      }}
      avatarSpacer={true}
    >
      <Message.CustomContent style={{ position: 'relative' }} className={styles.content}>
        <Image
          height={200}
          src={`/images/${message.content.url}?width=200&height=200`}
          preview={{ src: `/images/${message.content.url}`, mask }}
          alt={message.content.altName}
          style={{ marginBottom: 8 }}
        />
        <Message.HtmlContent html={message.content.message} />
      </Message.CustomContent>
    </Message>
  )
}
