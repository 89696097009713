import { useEffect, useState } from 'react'
import { authProvider } from './auth-provider'
import { UserSignInEvent, UserSignOutEvent } from './auth-provider.types'

export const useIsLoggedIn = () => {
  const [isLogged, setIsLogged] = useState(authProvider.isLoggedIn)

  useEffect(() => {
    const signInListener = async (event: UserSignInEvent) => {
      setIsLogged(true)
    }

    const signOutListener = async (event: UserSignOutEvent) => {
      setIsLogged(false)
    }

    authProvider.addEventListener('user-sign-in', signInListener)
    authProvider.addEventListener('user-sign-out', signOutListener)

    return () => {
      authProvider.removeEventListener('user-sign-in', signInListener)
      authProvider.removeEventListener('user-sign-out', signOutListener)
    }
  }, [])

  return [isLogged]
}
