import { nanoid } from 'nanoid'
import { Pto } from '@merchx-v3/pto'
import { WebSocket } from '@merchx-v3/web-socket'
import { debounce, PresentationAPI } from '@merchx-v3/shared-functions'

import { appWebSocket } from 'app/websocket'
import { UpdateState } from './context/types'
import { authProvider } from 'app/auth/auth-provider'
import { TypingDebouncer } from './context/typing-debouncer'
import { conversationStorage, IConversationStorage } from './conversation-storage'
import { SetLastReadMessageDebouncer } from './context/set-last-read-message-debouncer'
import { UserChangedEvent, UserSignInEvent, UserSignOutEvent } from 'app/auth/auth-provider.types'
import {
  ConversationET,
  ConversationListLoadedEvent,
  ConversationServiceConnected,
  ConversationServiceDisconnected,
  ConversationsEventTarget,
  SendTypingServiceParams,
  UserId
} from './types'
import { toast } from 'react-toastify'

export const generateNanoId = () => `ID${nanoid()}`

type LoadCompletedCallback = () => void
type ChannelState = 'DISCONNECTING' | 'DISCONNECTED' | 'CONNECTING' | 'CONNECTED'

// Работа сервиса - выполнять обмен данными между локальным хранилищем и бекендом через вебсокет
export interface IConversationService extends ConversationET {
  get isWebSocketConnected(): boolean
  get isConversationsConnected(): boolean

  get user(): Pto.Conversations.User | undefined

  factory(_storage: IConversationStorage, updateState: UpdateState): IConversationService

  on: typeof appWebSocket.on
  off: typeof appWebSocket.off

  getUser(userId: UserId): Pto.Conversations.User | undefined
  setActiveConversation(conversationId: string): void

  postMessage<T extends Pto.Conversations.MessageContentType>(conversationId: string, type: T, content: Pto.Conversations.MessageContentTypesMap[T]): void
  sendTyping(params: SendTypingServiceParams): void
  setLastReadMessage(conversationId: string, message: Pto.Conversations.Message): void

  loadConversationList(): Promise<void>
  loadConversation(conversationId: string): Promise<void>
}

export class ConversationService extends ConversationsEventTarget implements IConversationService {
  private readonly _webSocket = appWebSocket
  private _channels: Record<string, ChannelState>
  private _isWebSocketConnected: boolean = false
  private _isConversationsConnected: boolean = false
  private _user?: Pto.Conversations.User
  private _storage: IConversationStorage
  private _updateState?: UpdateState
  private _typingDebouncer?: TypingDebouncer
  private _setLastReadMessageDebouncer?: SetLastReadMessageDebouncer

  get isWebSocketConnected(): boolean {
    return this._isWebSocketConnected
  }

  get user(): Pto.Conversations.User | undefined {
    return this._user
  }

  get isConversationsConnected() {
    return this._isConversationsConnected
  }

  constructor() {
    super()

    this._channels = {}

    this._storage = conversationStorage
    this._webSocket = appWebSocket

    this._webSocket.addEventListener('web-socket-connected', this.onWebSocketConnected)
    this._webSocket.addEventListener('web-socket-error', this.onWebSocketError)
    this._webSocket.addEventListener('web-socket-disconnecting', this.onWebSocketDisconnecting)

    authProvider.addEventListener('user-sign-in', this.onUserSignedIn)
    authProvider.addEventListener('user-sign-out', this.onUserSignedOut)
    authProvider.addEventListener('user-changed', this.onUserChanged)
  }

  dispose() {
    authProvider.removeEventListener('user-sign-in', this.onUserSignedIn)
    authProvider.removeEventListener('user-sign-out', this.onUserSignedOut)
    authProvider.removeEventListener('user-changed', this.onUserChanged)

    this._webSocket.removeEventListener('web-socket-connected', this.onWebSocketConnected)
    this._webSocket.removeEventListener('web-socket-disconnecting', this.onWebSocketDisconnecting)
    this._webSocket.removeEventListener('web-socket-error', this.onWebSocketError)
  }

  on = appWebSocket.on
  off = appWebSocket.off

  getUser = (userId: UserId) => {
    return this._storage.getUser(userId)
  }

  setActiveConversation = (conversationId: string): void => {
    const { currentMessage } = this._storage.getState()

    this._storage.setDraft(currentMessage)

    this._storage.setActiveConversation(conversationId)

    const activeConversation = this._storage.getConversation(conversationId)

    if (activeConversation?.draft) {
      this._storage.setCurrentMessage(activeConversation.draft)
      activeConversation.draft = ''
    }

    this._updateState && this._updateState()
  }

  /* ////////////////////////////////////////////////////////////////////////////////////////////////////
  // COMMAND HANDLERS
  /////////////////////////////////////////////////////////////////////////////////////////////////////// */

  // #start-section(collapsed) createConversation
  async createConversation(name: string, participants: Pto.Conversations.Participant[], avatarUrl?: string): Promise<WebSocket.Shared.ApiResponse> {
    return new Promise((resolve, reject) => {
      if (participants.length === 0) reject('No participants defined')

      const timeout = this.getTimeout(reject)

      const createConversationPayload: Pto.Conversations.CreateConversation = {
        id: generateNanoId(),
        name,
        avatarUrl,
        participants
      }

      this._webSocket.send('create-conversation', createConversationPayload, (apiResponse) => {
        clearTimeout(timeout)
        resolve(apiResponse)
      })
    })
  }
  // #end-section

  // #start-section(collapsed) loadConversationMessages
  loadConversationMessages = debounce(this._loadConversationMessages, 500)

  async _loadConversationMessages(
    conversationId?: string,
    direction: Pto.Conversations.ReadMessageDirection = Pto.Conversations.ReadMessageDirection.Forward,
    cb?: LoadCompletedCallback
  ) {
    if (!this._isWebSocketConnected) return
    if (!conversationId) return

    const conversation = this._storage.conversations[conversationId]
    if (!conversation) return
    if (!conversation.isLoaded) return // для первой загрузки используется метод подключения к беседе, если флаг пустой то он не был вызван

    // Если нет последующих или предществующих сообщений, то игнорируем вызов
    if (direction === Pto.Conversations.ReadMessageDirection.Backward && !conversation.hasPrevMessages) return
    if (direction === Pto.Conversations.ReadMessageDirection.Forward && !conversation.hasNextMessages) return

    if (direction === Pto.Conversations.ReadMessageDirection.Backward) return this._loadPreviousMessages(conversationId, cb)

    return this._loadNextMessages(conversationId, cb)
  }

  async _loadPreviousMessages(conversationId: string, cb?: LoadCompletedCallback) {
    return new Promise<void>((resolve, reject) => {
      console.log('Load previous conversation messages', conversationId, this._storage.conversations[conversationId].messages[0].id)
      const connectionTimeout = this.getTimeout(reject)

      const conversation = this._storage.conversations[conversationId]
      const getPageArgs: Pto.Conversations.LoadConversationMessageList = {
        conversationId,
        cursorId: conversation.messages[0].id,
        direction: Pto.Conversations.ReadMessageDirection.Backward
      }

      appWebSocket.send('load-conversation-message-list', getPageArgs, (response) => {
        clearTimeout(connectionTimeout)
        console.log('Previous conversation message list loaded', response)

        if (!response.isSuccess) {
          toast.error(response.exception?.message || 'Unknown error')
          return resolve()
        }

        if (!response.payload) return resolve()

        const loadedMessageList = response.payload

        loadedMessageList.messages.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)).forEach((chatMessage) => this._storage.addPreviousMessage(conversationId, chatMessage))

        // Если мы загружали прошлые сообщения то нам нужно знать, есть ли еще не загруженные сообщения перед ними
        conversation.hasPrevMessages = loadedMessageList.hasPrevMessages

        this._updateState && this._updateState()

        cb && cb()

        resolve()
      })
    })
  }

  async _loadNextMessages(conversationId: string, cb?: LoadCompletedCallback) {
    return new Promise<void>((resolve, reject) => {
      console.log('Load next conversation messages', conversationId)
      const connectionTimeout = this.getTimeout(reject)

      const conversation = this._storage.conversations[conversationId]
      const getPageArgs: Pto.Conversations.LoadConversationMessageList = {
        conversationId,
        cursorId: conversation.messages[conversation.messages.length - 1].id,
        direction: Pto.Conversations.ReadMessageDirection.Forward
      }

      appWebSocket.send('load-conversation-message-list', getPageArgs, (response) => {
        clearTimeout(connectionTimeout)
        console.log('Next conversation message list loaded', response)

        if (!response.isSuccess) {
          toast.error(response.exception?.message || 'Unknown error')
          return resolve()
        }

        if (!response.payload) return resolve()

        const nextMessageList = response.payload

        nextMessageList.messages.forEach((message) => {
          this._storage.addMessage(conversationId, message)
        })

        // Если мы загружали новые сообщения то нам нужно знать, есть ли еще не загруженные сообщения после них
        conversation.hasNextMessages = nextMessageList.hasNextMessages

        cb && cb()

        this._updateState && this._updateState()

        resolve()
      })
    })
  }
  // #end-section

  // #start-section(collapsed) postMessage
  postMessage<T extends Pto.Conversations.MessageContentType>(conversationId: string, type: T, content: Pto.Conversations.MessageContentTypesMap[T]) {
    if (!this._user) return

    const socketMessage: Pto.Conversations.CreateMessage = {
      id: generateNanoId(),
      content: JSON.stringify(content),
      senderId: this._user.id,
      type
    }

    this._webSocket.send('send-message', { conversationId, message: socketMessage })
  }
  // #end-section

  // #start-section(collapsed) sendTyping
  sendTyping = debounce(this._sendTyping, 500)

  _sendTyping(params: SendTypingServiceParams) {
    const sendTyping: Pto.Conversations.SendTyping = {
      conversationId: params.conversationId,
      userId: params.userId,
      webSocketId: this._webSocket.id
    }
    this._webSocket.send('send-typing', sendTyping)
  }
  // #end-section

  // #start-section(collapsed) messageBecameVisible
  messageBecameVisible(conversationId: string, message: Pto.Conversations.Message) {
    if (!this._user) return

    const conversation = this._storage.conversations[conversationId]
    const lastReadMessage = conversation.lastReadMessage

    // Если дата увиденного сообщения больше последнего прочитанного мной, то обновляем последнее прочитанное сообщение и отправляем информацию на сервер
    if (!lastReadMessage || (lastReadMessage?.createdAt && lastReadMessage.createdAt < message.createdAt)) {
      this._setLastReadMessageDebouncer?.debounce(conversationId, message)
    }
  }
  // #end-section

  // #start-section(collapsed) setLastReadMessage
  setLastReadMessage(conversationId: string, message: Pto.Conversations.Message) {
    if (!this._user) return

    this._webSocket.send('set-last-read-message', {
      conversationId,
      lastReadMessageId: message.id,
      userId: this._user.id
    })
  }
  // #end-section

  /* ////////////////////////////////////////////////////////////////////////////////////////////////////
  // SYSTEM FUNCTIONS
  /////////////////////////////////////////////////////////////////////////////////////////////////////// */

  // #start-section(collapsed) factory
  factory = (storage: IConversationStorage, updateState: UpdateState) => {
    this._storage = storage
    this._updateState = updateState

    this._typingDebouncer = new TypingDebouncer(storage, updateState, 900)
    this._setLastReadMessageDebouncer = new SetLastReadMessageDebouncer(this, storage, updateState, 1000)

    return this
  }
  // #end-section

  // #start-section(collapsed) start
  start = async (sessionUser?: Pto.Users.User) => {
    if (!sessionUser) return

    // Если юзер есть то очищаем чат
    if (this._user) await this.stop()

    try {
      // Регистрируем нового юзера в чате
      this._user = {
        id: sessionUser.id,
        email: sessionUser.email,
        firstName: sessionUser.firstName,
        lastName: sessionUser.lastName,
        avatarUrl: sessionUser.avatarUrl,
        username: PresentationAPI.getUserPreview(sessionUser),
        presence: Pto.Conversations.Presence.Online
      }

      console.log('Chat service: set current user')
      this._storage.setActiveUser(this._user)

      // Join user channel to receive MAIN/SHARED notifications
      await this.loadConversationList()

      // Ререндерим экран
      this._updateState && this._updateState()
    } catch (exception) {
      console.log('Chat service: start exception', exception)
    }
  }
  // #end-section

  // #start-section(collapsed) stop
  stop = async () => {
    if (!this._user) return

    try {
      // Юзер изменился, надо размонтировать все чаты прошлого юзера

      this._user = undefined
      this._storage.resetState()
    } catch (exception) {
      console.log('Chat service: stop exception', exception)
    }
  }
  // #end-section

  // #start-section(collapsed) connectUser
  async connectUser(user?: Pto.Users.User) {
    if (!this._isWebSocketConnected) return // Нет соединения, ничего не делаем
    if (!user) return // нет нового юзера, оставляем все как есть или должны отключить чаты???
    if (this._user?.id === user.id && this._isWebSocketConnected) return // если юзер не изменился то ничего не делаем

    try {
      if (this._user) {
        // Юзер изменился, надо размонтировать все чаты прошлого юзера

        await this.disconnectFromConversations()

        this._storage.resetState()
      }

      // Регистрируем нового юзера в чате
      this._user = {
        id: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        avatarUrl: user.avatarUrl,
        username: PresentationAPI.getUserPreview(user),
        presence: Pto.Conversations.Presence.Online
      }

      this._storage.setActiveUser(this._user)

      // Join user channel to receive MAIN/SHARED notifications
      await this.loadConversationList()

      // Ререндерим экран
      this._updateState && this._updateState()
    } catch (exception) {
      console.log('Connect user exception', exception)
    }
  }
  // #end-section

  // #start-section(collapsed) disconnectUser()
  disconnectUser = (_user?: Pto.Users.User) => {
    console.log('Chat service: disconnect user')
    this._storage.setActiveUser()
  }
  // #end-section

  // #start-section(collapsed) loadConversationList
  async loadConversationList(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this._isWebSocketConnected) resolve()
      if (!this._user) resolve()

      console.log('Chat service: load conversation list - started')
      const connectionTimeout = this.getTimeout(reject)

      appWebSocket.send('load-conversation-list', { userId: this._user!.id }, async (response) => {
        if (!response.isSuccess) {
          toast.error(response.exception?.message || 'Unknown error')
          return resolve()
        }

        if (!response.payload) return resolve()

        this._storage.addUsers(response.payload.users)
        response.payload.conversations.forEach((conversation) => {
          this._storage.addConversation(conversation)
        })

        console.log('Chat service: load conversation list - completed')

        clearTimeout(connectionTimeout)

        this._isConversationsConnected = true
        this.dispatchEvent(new ConversationListLoadedEvent())

        resolve()
      })
    })
  }
  // #end-section

  // #start-section(collapsed) loadConversation
  async loadConversation(conversationId: string): Promise<void> {
    if (!this._user) return
    if (!this._isWebSocketConnected) return

    let conversation = this._storage.conversations[conversationId]
    if (conversation && conversation.isLoaded) return

    return new Promise((resolve, reject) => {
      const connectionTimeout = this.getTimeout(reject)

      appWebSocket.send('load-conversation', { conversationId, lastReadMessageId: conversation?.lastReadMessage?.id }, (response) => {
        clearTimeout(connectionTimeout)

        if (!response.isSuccess) {
          if (response.exception?.status === 403) {
            toast.error(`Load conversation - access denied`)
          } else {
            toast.error(response.exception?.message || 'Unknown error')
          }
          return resolve()
        }

        const conversationLoaded = response.payload
        if (!conversationLoaded) return resolve()

        const { users, hasPrevMessages, messages, hasNextMessages } = conversationLoaded
        this._storage.addUsers(users)
        this._storage.addConversation(conversationLoaded.conversation)
        conversation = this._storage.getConversation(conversationId)!
        conversation.messages = []

        messages.forEach((message) => {
          this._storage.addMessage(conversationId, message)

          if (message.id === conversation.lastReadMessage?.id && conversation.lastMessage?.id !== conversation.lastReadMessage.id) {
            const newMessagesSeparator: Pto.Conversations.Message<Pto.Conversations.MessageContentType.System> = {
              id: generateNanoId(),
              type: Pto.Conversations.MessageContentType.System,
              senderId: WebSocket.Settings.SystemSenderId,
              content: 'New messages',
              createdAt: new Date(),
              updatedAt: new Date()
            }

            this._storage.addMessage(conversationId, newMessagesSeparator)
          }
        })

        this._storage.conversations[conversationId].hasPrevMessages = hasPrevMessages
        this._storage.conversations[conversationId].hasNextMessages = hasNextMessages
        this._storage.conversations[conversationId].isLoaded = true

        this._updateState && this._updateState()
        resolve()
      })
    })
  }
  // #end-section

  // #start-section(collapsed) disconnectFromConversations
  async disconnectFromConversations() {
    console.log('Leave conversation channels')
    // TODO - Добавить логику отписки от каналов
    this._isConversationsConnected = false
  }
  // #end-section

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  // EVENT HANDLERS
  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  // #start-section(collapsed) ON WEBSOCKET CONNECTED
  onWebSocketConnected = async () => {
    console.log('Chat service: web socket connected')
    this._isWebSocketConnected = true

    this._webSocket.on('conversation-created', this.onConversationCreated)
    this._webSocket.on('message-sent', this.onMessageSent)
    this._webSocket.on('typing-sent', this.onUserTyping)
    this._webSocket.on('last-read-message-set', this.onLastReadMessageSet)
    this._webSocket.on('user-presence-changed', this.onUserPresenceChanged)

    this.dispatchEvent(new ConversationServiceConnected())

    await this.start(authProvider.getUser())
  }
  // #end-section

  // #start-section(collapsed) ON WEBSOCKET ERROR
  onWebSocketError = async (event: Event) => {
    this._isWebSocketConnected = false
  }
  // #end-section

  // #start-section(collapsed) ON WEBSOCKET DISCONNECTING
  onWebSocketDisconnecting = async (event: Event) => {
    console.log('Chat service: web socket closing connection')
    this._isWebSocketConnected = false

    this._webSocket.off('conversation-created')
    this._webSocket.off('message-sent')
    this._webSocket.off('typing-sent')
    this._webSocket.off('last-read-message-set')
    this._webSocket.off('user-presence-changed')

    this.dispatchEvent(new ConversationServiceDisconnected())

    await this.stop()
  }
  // #end-section

  // #start-section(collapsed) ON USER SIGN IN
  onUserSignedIn = async (event: UserSignInEvent) => {
    console.log('Chat service: user signed in')
    if (!this._isWebSocketConnected) return

    await this.start(event.detail)
  }
  // #end-section

  // #start-section(collapsed) ON USER SIGNED OUT
  onUserSignedOut = (event: UserSignOutEvent) => {
    console.log('Chat service: user signed out')

    this._user = undefined
    this.stop()
    this._updateState && this._updateState()
  }
  // #end-section

  // #start-section(collapsed) ON USER CHANGED
  onUserChanged = (event: UserChangedEvent) => {
    console.log('Chat service: user changed')
  }
  // #end-section

  // #start-section(collapsed) ON CONVERSATION CREATED
  private onConversationCreated: WebSocket.Conversations.Listeners.ConversationCreated = async (conversationCreated) => {
    if (!conversationCreated) return

    const { hasPrevMessages, messages, hasNextMessages, users } = conversationCreated

    this._storage.addUsers(users)
    this._storage.addConversation(conversationCreated.conversation)

    messages.forEach((message) => this._storage.addMessage(conversationCreated.conversation.id, message))

    this._storage.conversations[conversationCreated.conversation.id].hasPrevMessages = hasPrevMessages
    this._storage.conversations[conversationCreated.conversation.id].hasNextMessages = hasNextMessages

    await this.loadConversation(conversationCreated.conversation.id)
  }
  // #end-section

  // #start-section(collapsed) ON MESSAGE SENT
  private onMessageSent: WebSocket.Conversations.Listeners.MessageSent = (receivedMessage) => {
    const { conversationId, message } = receivedMessage
    const conversation = this._storage.getConversation(conversationId)

    if (conversation) {
      conversation.lastMessage = message
      conversation.lastActivityAt = new Date()

      if (!conversation?.hasNextMessages) {
        this._storage.addMessage(conversationId, message)
      }

      if (message.senderId !== this._user?.id) {
        conversation.unreadMessages++
      }

      conversation.typingUsers = conversation.typingUsers.filter((userId) => userId !== message.senderId)
    } else {
      // Неизвестная беседа, загрузим ее данные
      this.disconnectFromConversations()
      this.loadConversationList()
    }

    this._updateState && this._updateState()
  }
  // #end-section

  // #start-section(collapsed) ON USER TYPING
  private onUserTyping: WebSocket.Conversations.Listeners.TypingSent = (receivedTyping) => {
    const { sourceWebSocketId, conversationId, userId } = receivedTyping
    if (sourceWebSocketId !== this._webSocket.id) {
      const conversation = this._storage.getConversation(conversationId)
      if (conversation && !conversation.typingUsers.includes(userId)) {
        conversation.typingUsers.push(userId)

        this._updateState && this._updateState()
      }

      this._typingDebouncer?.debounce(conversationId, userId)
    }
  }
  // #end-section

  // #start-section(collapsed) ON USER PRESENCE CHANGED
  private onUserPresenceChanged: WebSocket.Conversations.Listeners.UserPresenceChanged = (payload) => {
    const { userId, presence } = payload

    console.log('Chat service: update user presence')
    if (this._storage.users[userId]) {
      this._storage.updateUserPresence(userId, presence)
      this._updateState && this._updateState()
    }
  }
  // #end-section

  // #start-section(collapsed) ON LAST READ MESSAGE SET
  private onLastReadMessageSet: WebSocket.Conversations.Listeners.LastReadMessageSet = async (lastReadMessageSet) => {
    const { conversationId, numberOfUnreadMessages } = lastReadMessageSet

    const conversation = this._storage.getConversation(conversationId)
    if (conversation) {
      conversation.unreadMessages = numberOfUnreadMessages
      this._updateState && this._updateState()
    }
  }
  // #end-section

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  // PRIVATE HELPERS
  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  // #start-section(collapsed) getTimeout
  getTimeout(reject: (reason?: string) => void, timeoutMs = 10_000): NodeJS.Timeout {
    return setTimeout(() => {
      reject('Web socket connection timeout')
    }, timeoutMs)
  }
  // #end-section
}

export const conversationService = new ConversationService()
