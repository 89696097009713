import { Button, Typography } from 'antd'
import { Pto, Role } from '@merchx-v3/pto'
import { Navigate } from 'react-router-dom'
import { useUser } from 'app/auth'
// SUPPLIERS

type PortalButton = {
  route: string
  description: string
  button: string
}

const portalMapper: Record<Pto.Auth.ClaimGroups.CRMClaims, PortalButton> = {
  [Pto.Auth.ClaimGroups.CRMClaims.AdminPortal]: { route: '/admin', description: 'Use Admin panel', button: 'Admin' },
  [Pto.Auth.ClaimGroups.CRMClaims.CustomerPortal]: { route: '/customers', description: 'Use Customer panel', button: 'Customer' },
  [Pto.Auth.ClaimGroups.CRMClaims.SupplierPortal]: { route: '/suppliers', description: 'Use Supplier panel', button: 'Supplier' },
  [Pto.Auth.ClaimGroups.CRMClaims.DesignerPortal]: { route: '/designers', description: 'Use Designer panel', button: 'Designer' },
  [Pto.Auth.ClaimGroups.CRMClaims.ManagerPortal]: { route: '/managers', description: 'Use Manager panel', button: 'Manager' }
}

const commonPortal: PortalButton = {
  route: '/common',
  description: 'Use Common panel',
  button: 'Common'
}

function getAvailablePortals(user?: Pto.Users.User): Pto.Auth.ClaimGroups.CRMClaims[] {
  const isAdmin = user?.roles?.includes(Role.SystemAdmin)

  const availablePortals = Object.keys(Pto.Auth.ClaimGroups.CRMClaims).reduce((accum, portal) => {
    const portalKey = Object.keys(Pto.Auth.ClaimGroups.CRMClaims)[Object.values(Pto.Auth.ClaimGroups.CRMClaims).indexOf(portal as Pto.Auth.ClaimGroups.CRMClaims)]

    const isPortalAvailable =
      isAdmin ||
      user?.claims.some(
        (claim) =>
          claim.claim === Pto.Auth.Claim[portalKey as keyof typeof Pto.Auth.ClaimGroups.CRMClaims] &&
          [Pto.Auth.AccessLevel.AllowAll, Pto.Auth.AccessLevel.LimitedAccess].includes(claim.accessLevel)
      )

    if (isPortalAvailable) accum.push(portal)

    return accum
  }, [] as string[])

  return availablePortals as Pto.Auth.ClaimGroups.CRMClaims[]
}

const IndexPage = () => {
  const [user] = useUser()

  const availablePortals = getAvailablePortals(user)

  // if (availablePortals.length === 1) return <Navigate to={portalMapper[availablePortals[0]].route} />

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', gap: '40px' }}>
      {availablePortals.map((portal, index) => (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', gap: '10px' }} key={index}>
          <Typography>{portalMapper[portal].description}</Typography>
          <Button href={portalMapper[portal].route}>{portalMapper[portal].button}</Button>
        </div>
      ))}
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', gap: '10px' }}>
        <Typography>{commonPortal.description}</Typography>
        <Button href={commonPortal.route}>{commonPortal.button}</Button>
      </div>
    </div>
  )
}

export default IndexPage
