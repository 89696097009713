import React, { useEffect } from 'react'
import { AutoComplete } from 'antd'
import useDebounce from 'helpers/useDebounce'
import { useCategoriesOptionsQuery } from 'app/api/supplier-products-api'

type Props = {
  defaultValue?: string
  placeholder?: string
  disabled?: boolean
  className?: string
  value?: string
  onChange?: (value: string) => void
}

const CategoryOptions = (props: Props) => {
  const { placeholder, disabled, className, value, onChange } = props

  const [searchText, setSearchText] = React.useState<string>(value || '')
  const debouncedSearchText = useDebounce(searchText, 500)

  const { data: categoryOptions } = useCategoriesOptionsQuery({ searchText: debouncedSearchText, size: 20 })

  useEffect(() => {
    if (value) setSearchText(value)
  }, [value])

  const onSearch = (value: string) => {
    setSearchText(value)

    if (onChange) {
      onChange(value)
    }
  }

  return <AutoComplete options={categoryOptions} className={className} value={searchText} disabled={disabled} onSelect={onSearch} onSearch={onSearch} placeholder={placeholder} />
}

export default CategoryOptions
