import { useParams, useNavigate } from 'react-router-dom'
import { Button, Popconfirm, Table, TableColumnsType, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { Pto } from '@merchx-v3/pto'
import { AppRoutes } from 'interfaces/admin/components/Router'
import styles from './SupplierSelectsTab.module.scss'

type Props = {
  data: Pto.Plugins.Fields.ISupplierSelect[]
  onRemoveSupplierSelect: (fieldId: string) => void
  isLoading: boolean
}

const columns: (onRemove: (id: string) => void, isLoading: boolean) => TableColumnsType<Pto.Plugins.Fields.ISupplierSelect> = (onRemove, isLoading) => [
  {
    title: 'ID',
    dataIndex: 'id',
    render: (text: string, record, index: number) => index + 1
  },
  {
    title: 'Table name',
    dataIndex: 'tableName'
  },
  {
    title: 'Display Name',
    dataIndex: 'displayName'
  },
  {
    title: 'Action',
    width: '75px',
    render: (_, record) => (
      <Popconfirm
        style={{ zIndex: 1 }}
        disabled={isLoading}
        title="Sure to remove?"
        onConfirm={(e) => {
          e?.stopPropagation()
          onRemove(record.id)
        }}
        onCancel={(e) => e?.stopPropagation()}
      >
        <Typography.Link className={styles.removeLink} onClick={(e) => e?.stopPropagation()}>
          Remove
        </Typography.Link>
      </Popconfirm>
    )
  }
]

const ProductFieldsTab = ({ data, onRemoveSupplierSelect, isLoading }: Props) => {
  const { fulfillmentPluginId, shippingPluginId } = useParams()
  const navigate = useNavigate()

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.btn}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate(`../${AppRoutes.pluginFulfillment}/${fulfillmentPluginId || shippingPluginId}/supplier-select/create`)}
          >
            ADD
          </Button>
        </div>
        <Table
          dataSource={data}
          rowKey={(row) => row.id}
          onRow={(record) => {
            return {
              onClick: () => navigate(`../${AppRoutes.pluginFulfillment}/${fulfillmentPluginId || shippingPluginId}/supplier-select/${record.id}`)
            }
          }}
          columns={columns(onRemoveSupplierSelect, isLoading)}
          pagination={false}
          bordered
          size="small"
          className={styles.table}
        />
      </div>
    </div>
  )
}

export default ProductFieldsTab
