import { Pto } from '@merchx-v3/pto'
import { Tree, TreeProps } from 'antd'
import { FormClaims } from '../../types'
import { TreeManager } from './tree-manager'

type Props = {
  value?: FormClaims
  onAccessLevelChanged?: (claims: Pto.Auth.Claim[], checked: boolean) => void
}

export const ClaimTree = (props: Props) => {
  const handleCheck: TreeProps['onCheck'] = (_, info) => {
    const targetArray: string[] = []

    for (const treeNode of info.node.children || [info.node]) {
      const pureClaim = TreeManager.extractClaimFromTreeKey(treeNode.key)

      if (pureClaim && !targetArray.includes(pureClaim)) {
        targetArray.push(pureClaim)
      }
    }

    props.onAccessLevelChanged && props.onAccessLevelChanged(targetArray as Pto.Auth.Claim[], info.checked)
  }

  return (
    <Tree defaultExpandAll checkable showLine treeData={TreeManager.getTree()} checkedKeys={TreeManager.getCheckedKeys(props.value || ({} as FormClaims))} onCheck={handleCheck} />
  )
}

export default ClaimTree
