import { Typography } from 'antd'

import styles from './ResetPassword.module.scss'

type Props = {}

export const EmailSent = (_props: Props) => {
  return (
    <div className={styles.container}>
      <form className={styles.content}>
        <div className={styles.header}>
          <h1>Reset password</h1>
          <div className={styles.logo} />
        </div>
        <div className={styles.messageContainer}>
          <Typography.Title level={4} style={{ fontWeight: 500 }}>
            Password reset sent to email
          </Typography.Title>
        </div>
      </form>
    </div>
  )
}
