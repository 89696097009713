import { Pto } from '@merchx-v3/pto'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { Form, Button, Typography, Space, Switch, Select } from 'antd'
import ClaimArgOptions from '../ClaimArgOptions'

type ArgProps = {
  title: string
  claim: Pto.Auth.Claim
}

const ClaimTreeArgs = ({ title, claim }: ArgProps) => {
  const form = Form.useFormInstance()

  const checked = Form.useWatch(['claims', claim, 'checked'], form) || false
  const isFullAccess = Form.useWatch(['claims', claim, 'isFullAccess'], form) || false

  return (
    <>
      <Space>
        <Typography>{title}</Typography>
        {checked && (
          <Form.Item noStyle name={['claims', claim, 'isFullAccess']} valuePropName="checked" initialValue={true}>
            <Switch checkedChildren="Full Access" unCheckedChildren="Limited Access" />
          </Form.Item>
        )}
      </Space>
      {checked && !isFullAccess && (
        <Form.List name={['claims', claim, 'args']} initialValue={[]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div key={key} style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                  <Space>
                    <div>
                      <Form.Item
                        initialValue={Pto.Auth.ActionClaimArgKeysMap[claim]}
                        validateDebounce={300}
                        noStyle
                        {...restField}
                        name={[name, 'key']}
                        rules={[{ required: true, message: `Missing claim key for ${title}` }]}
                      >
                        <Select
                          placeholder="Claim arg key"
                          disabled
                          style={{ width: '200px' }}
                          options={[{ value: Pto.Auth.ActionClaimArgKeysMap[claim], label: Pto.Auth.ActionClaimArgKeyHumanReadable[Pto.Auth.ActionClaimArgKeysMap[claim]] }]}
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item validateDebounce={300} noStyle {...restField} name={[name, 'value']} rules={[{ required: true, message: `Missing claim value for ${title}` }]}>
                        <ClaimArgOptions claim={claim} style={{ width: '200px' }} />
                      </Form.Item>
                    </div>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                </div>
              ))}
              <Form.Item>
                <Button style={{ marginTop: '10px' }} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add claim arg
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )}
    </>
  )
}

export default ClaimTreeArgs
