import { createSlice } from '@reduxjs/toolkit'
import { Pto } from '@merchx-v3/pto'
import { AxiosError } from 'axios'
import { createAppAsyncThunk } from 'app'
import { RootState } from 'app/store'
import * as pluginsAPI from './pluginsAPI'

type ProductId = string

export interface PluginsState {
  fulfillmentPlugin: Pto.Plugins.IPluginMeta | null
  fulfillmentPluginList: {
    total: Pto.Plugins.List['total']
    items: Pto.Plugins.List['items'][]
  }
  productFields: Record<ProductId, any[]>
  projectProjectItemsFields: any[]
  productVariantsFields: any[]

  fulfillmentPluginOptions: Pto.Option[]

  targetPluginSettings: Pto.Plugins.Fields.ISetting[]
  targetFulfillmentPluginList: Pto.Plugins.SupplierPluginListItem[]
  targetFulfillmentPluginDetails: Pto.Plugins.SupplierPluginListItem | null

  supplierSelectRecordList: Pto.Plugins.Fields.SupplierSelectRecord[]

  status: 'idle' | 'loading' | 'failed'
  error: string | null
}

const initialState: PluginsState = {
  fulfillmentPlugin: null,
  fulfillmentPluginList: { items: [], total: 0 },
  fulfillmentPluginOptions: [],
  productFields: {},
  projectProjectItemsFields: [],
  productVariantsFields: [],

  targetPluginSettings: [],
  targetFulfillmentPluginList: [],
  targetFulfillmentPluginDetails: null,

  supplierSelectRecordList: [],

  status: 'idle',
  error: null
}

type FetchFulfillmentPluginListPayload = {
  page?: number
  pageSize?: number
}

type GetRecordListQuery = {
  supplierSelectId: string
  supplierId: string
  page?: number
  size?: number
  searchText?: string
}

export const getFulfillmentPlugin = createAppAsyncThunk<Pto.Plugins.IPluginMeta, string>('plugins/fetchFulfillmentPlugin', async (fulfillmentPluginId) => {
  return pluginsAPI.fetchFulfillmentPlugin(fulfillmentPluginId)
})

export const getFulfillmentPluginList = createAppAsyncThunk<Pto.Plugins.List, FetchFulfillmentPluginListPayload>(
  'plugins/metadata/fulfillment',
  async ({ page, pageSize }, { getState }) => {
    return pluginsAPI.fetchFulfillmentPluginList(page, pageSize)
  }
)

export const getFulfillmentPluginOptions = createAppAsyncThunk<Pto.Option[], string | undefined>('plugins/metadata/fulfillment/options', async (searchText) => {
  return pluginsAPI.fetchFulfillmentPluginOptions(searchText)
})

export const registerSupplierFulfillmentPlugin = createAppAsyncThunk<boolean, any>('plugins/registerSupplierFulfillmentPlugin', async (args, { rejectWithValue }) => {
  try {
    const data = await pluginsAPI.registerSupplierFulfillmentPlugin(args)

    return data
  } catch (err) {
    let error = err as AxiosError<{ message: string; statusCode: string }>

    if (!error.response) {
      throw err
    }

    return rejectWithValue(error.response.data.message)
  }
})

export const saveTargetSettings = createAppAsyncThunk<boolean, { pluginId: string; supplierId: string; settings: Pto.Plugins.Fields.ISetting[] }>(
  'plugins/saveSettings',
  async (args, { rejectWithValue }) => {
    try {
      const data = await pluginsAPI.saveTargetSettings(args.pluginId, args.supplierId, args.settings)

      return data
    } catch (err) {
      let error = err as AxiosError<{ message: string; statusCode: string }>

      if (!error.response) {
        throw err
      }

      return rejectWithValue(error.response.data.message)
    }
  }
)

export const getTargetSettings = createAppAsyncThunk<Pto.Plugins.Fields.ISetting[], { pluginId: string; targetId: string }>(
  'plugins/targetPluginSettings',
  async (args, { rejectWithValue }) => {
    try {
      const data = await pluginsAPI.fetchTargetSettings(args.pluginId, args.targetId)

      return data
    } catch (err) {
      let error = err as AxiosError<{ message: string; statusCode: string }>

      if (!error.response) {
        throw err
      }

      return rejectWithValue(error.response.data.message)
    }
  }
)
// export const getSupplierFulfillmentPluginList = createAppAsyncThunk<any[], string>('plugins/supplierFulfillmentPluginList', async (supplierId) => {
//   const data = await pluginsAPI.fetchSupplierFulfillmentPlugins(supplierId)
//   return data
// })

export const getSupplierFulfillmentPluginDetails = createAppAsyncThunk<any, { supplierId: string; pluginId: string }>('plugins/supplierFulfillmentPluginDetails', async (args) => {
  const data = await pluginsAPI.fetchSupplierFulfillmentPluginDetails(args.supplierId, args.pluginId)
  return data
})

export const saveProductFieldValues = createAppAsyncThunk<void, any[]>('plugins/pluginFields', async (pluginFieldsValues) => {
  return pluginsAPI.saveProductFields(pluginFieldsValues)
})

export const saveProjectProductItemsFieldValues = createAppAsyncThunk<void, any[]>('plugins/saveProjectProductItemsFieldValues', async (args, { rejectWithValue }) => {
  try {
    return pluginsAPI.saveProjectProductItemsFields(args)
  } catch (err) {
    let error = err as AxiosError<{ message: string; statusCode: string }>

    if (!error.response) {
      throw err
    }

    return rejectWithValue(error.response.data.message)
  }
})

export const saveProductVariantsFieldValues = createAppAsyncThunk<void, any[]>('plugins/saveProductVariantsFieldValues', async (args, { rejectWithValue }) => {
  try {
    return pluginsAPI.saveProductVariantsFields(args)
  } catch (err) {
    let error = err as AxiosError<{ message: string; statusCode: string }>

    if (!error.response) {
      throw err
    }

    return rejectWithValue(error.response.data.message)
  }
})

export const getSupplierSelectRecordList = createAppAsyncThunk<any[], GetRecordListQuery>('plugins/supplierSelectRecordList', async (args) => {
  const data = await pluginsAPI.fetchSupplierSelectRecordList(args.supplierSelectId, args.supplierId, args.page, args.size, args.searchText)
  return data
})

export const createSupplierSelectRecord = createAppAsyncThunk<boolean, any>('plugins/createSupplierSelectRecord', async (args, { rejectWithValue }) => {
  try {
    return pluginsAPI.createSupplierSelectRecord(args)
  } catch (err) {
    let error = err as AxiosError<{ message: string; statusCode: string }>

    if (!error.response) {
      throw err
    }

    return rejectWithValue(error.response.data.message)
  }
})

export const updateSupplierSelectRecord = createAppAsyncThunk<boolean, any>('plugins/updateSupplierSelectRecord', async (args, { rejectWithValue }) => {
  try {
    return pluginsAPI.updateSupplierSelectRecord(args)
  } catch (err) {
    let error = err as AxiosError<{ message: string; statusCode: string }>

    if (!error.response) {
      throw err
    }

    return rejectWithValue(error.response.data.message)
  }
})

export const createPluginSupplierSelect = createAppAsyncThunk<boolean, any>('plugins/createPluginSupplierSelect', async (args, { rejectWithValue }) => {
  try {
    return pluginsAPI.createPluginSupplierSelect(args)
  } catch (err) {
    let error = err as AxiosError<{ message: string; statusCode: string }>

    if (!error.response) {
      throw err
    }

    return rejectWithValue(error.response.data.message)
  }
})

export const pluginsSlice = createSlice({
  name: 'plugins',
  initialState,
  reducers: {
    clearFulfillmentPlugin: (state) => {
      state.fulfillmentPlugin = null
    },
    clearError: (state) => {
      state.error = null
    },
    clearTargetPluginSettings: (state) => {
      state.targetPluginSettings = []
    },
    clearTargetFulfillmentPluginList: (state) => {
      state.targetFulfillmentPluginList = []
    },
    clearTargetFulfillmentPluginDetails: (state) => {
      state.targetFulfillmentPluginDetails = null
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch FulfillmentPluginList
      .addCase(getFulfillmentPluginList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getFulfillmentPluginList.fulfilled, (state, action) => {
        state.status = 'idle'
        if (!action.payload) return

        if ((state.fulfillmentPluginList.total > 0 && state.fulfillmentPluginList.total !== action.payload.total) || !action.meta.arg) {
          state.fulfillmentPluginList.items = []
          state.fulfillmentPluginList.items[1] = action.payload.items
        } else {
          state.fulfillmentPluginList.items[action.meta.arg.page || 1] = action.payload.items
        }

        state.fulfillmentPluginList.total = action.payload.total
      })
      .addCase(getFulfillmentPluginList.rejected, (state) => {
        state.status = 'failed'
      })

      // >>> Fetch FulfillmentPlugin
      .addCase(getFulfillmentPlugin.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getFulfillmentPlugin.fulfilled, (state, action) => {
        state.status = 'idle'
        if (!action.payload) return

        state.fulfillmentPlugin = action.payload
      })
      .addCase(getFulfillmentPlugin.rejected, (state) => {
        state.status = 'failed'
      })

      .addCase(getFulfillmentPluginOptions.fulfilled, (state, action) => {
        state.status = 'idle'

        state.fulfillmentPluginOptions = action.payload
      })

      .addCase(saveTargetSettings.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(saveTargetSettings.fulfilled, (state, action) => {
        state.status = 'idle'
      })
      .addCase(saveTargetSettings.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload || null
      })

      // >>> Save plugin fields values
      .addCase(saveProductFieldValues.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(saveProductFieldValues.fulfilled, (state, action) => {
        state.status = 'idle'
      })
      .addCase(saveProductFieldValues.rejected, (state, action) => {
        state.status = 'failed'
      })

      // >>> Save project product items plugin fields values
      .addCase(saveProjectProductItemsFieldValues.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(saveProjectProductItemsFieldValues.fulfilled, (state, action) => {
        state.status = 'idle'
      })
      .addCase(saveProjectProductItemsFieldValues.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload || null
      })

      // >>> Save project product items plugin fields values
      .addCase(saveProductVariantsFieldValues.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(saveProductVariantsFieldValues.fulfilled, (state, action) => {
        state.status = 'idle'
      })
      .addCase(saveProductVariantsFieldValues.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload || null
      })

      // >>> Register supplier fulfillment plugin
      .addCase(registerSupplierFulfillmentPlugin.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(registerSupplierFulfillmentPlugin.fulfilled, (state, action) => {
        state.status = 'idle'

        // const existedPlugin = state.targetFulfillmentPluginList.find((plugin) => plugin.id === action.meta.arg.pluginId)
        // if (existedPlugin) {
        //   existedPlugin.isActive = action.meta.arg.isActive
        // } else {
        //   const plugin = state.fulfillmentPlugin?.id === action.meta.arg.pluginId ? state.fulfillmentPlugin : undefined
        //   state.targetFulfillmentPluginList.push({
        //     id: action.meta.arg.pluginId,
        //     name: plugin?.name || '',
        //     displayName: plugin?.displayName || '',
        //     isActive: action.meta.arg.isActive
        //   })
        // }
      })
      .addCase(registerSupplierFulfillmentPlugin.rejected, (state, action) => {
        state.status = 'failed'
      })

      .addCase(getTargetSettings.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getTargetSettings.fulfilled, (state, action) => {
        state.status = 'idle'
        state.targetPluginSettings = action.payload
      })
      .addCase(getTargetSettings.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload || null
      })

      .addCase(getSupplierFulfillmentPluginDetails.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getSupplierFulfillmentPluginDetails.fulfilled, (state, action) => {
        state.status = 'idle'
        state.targetFulfillmentPluginDetails = action.payload
      })
      .addCase(getSupplierFulfillmentPluginDetails.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload || null
      })

      // >>> Fetch Supplier FulfillmentPlugin
      // .addCase(getSupplierFulfillmentPluginList.pending, (state) => {
      //   state.status = 'loading'
      // })
      // .addCase(getSupplierFulfillmentPluginList.fulfilled, (state, action) => {
      //   state.status = 'idle'
      //   if (!action.payload) return

      //   state.targetFulfillmentPluginList = action.payload
      // })
      // .addCase(getSupplierFulfillmentPluginList.rejected, (state) => {
      //   state.status = 'failed'
      // })

      // >>> Fetch Supplier Select Record List
      .addCase(getSupplierSelectRecordList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getSupplierSelectRecordList.fulfilled, (state, action) => {
        state.status = 'idle'
        if (!action.payload) return

        state.supplierSelectRecordList = action.payload
      })
      .addCase(getSupplierSelectRecordList.rejected, (state) => {
        state.status = 'failed'
      })

      // >>> Create supplier select record
      .addCase(createSupplierSelectRecord.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(createSupplierSelectRecord.fulfilled, (state, action) => {
        state.status = 'idle'
      })
      .addCase(createSupplierSelectRecord.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload || null
      })

      // >>> Update supplier select record
      .addCase(updateSupplierSelectRecord.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(updateSupplierSelectRecord.fulfilled, (state, action) => {
        state.status = 'idle'
      })
      .addCase(updateSupplierSelectRecord.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload || null
      })

      // >>> Create plugin supplier select
      .addCase(createPluginSupplierSelect.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(createPluginSupplierSelect.fulfilled, (state, action) => {
        state.status = 'idle'
      })
      .addCase(createPluginSupplierSelect.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload || null
      })
  }
})

export const { clearFulfillmentPlugin, clearError, clearTargetPluginSettings, clearTargetFulfillmentPluginDetails } = pluginsSlice.actions

export const selectFulfillmentPlugin = ({ plugins }: RootState) => plugins.fulfillmentPlugin
export const selectFulfillmentPluginPages = ({ plugins }: RootState) => plugins.fulfillmentPluginList.items
export const selectFulfillmentPluginOptions = ({ plugins }: RootState) => plugins.fulfillmentPluginOptions
export const selectIsLoading = ({ plugins }: RootState) => plugins.status === 'loading'
export const selectTotal = ({ plugins }: RootState) => plugins.fulfillmentPluginList.total
export const selectError = ({ plugins }: RootState) => plugins.error
export const selectProductFields = (state: RootState, productId?: string) => (productId ? state.plugins.productFields[productId] || [] : [])
export const selectProjectProductItemsFields = ({ plugins }: RootState) => plugins.projectProjectItemsFields
export const selectProductVariantsFields = ({ plugins }: RootState) => plugins.productVariantsFields
export const selectTargetPluginSettings = ({ plugins }: RootState) => plugins.targetPluginSettings
export const selectTargetFulfillmentPluginDetails = ({ plugins }: RootState) => plugins.targetFulfillmentPluginDetails
export const selectTargetFulfullmentPluginList = ({ plugins }: RootState) => plugins.targetFulfillmentPluginList
export const selectSupplierSelectRecordList = ({ plugins }: RootState) => plugins.supplierSelectRecordList
export default pluginsSlice.reducer
