import { Pto } from '@merchx-v3/pto'

export type ConversationId = string
export type UserId = string
export type MessageId = string

export type ConversationMessage<T extends Pto.Conversations.MessageContentType = Pto.Conversations.MessageContentType> = Pto.Conversations.Message<T> & {
  direction: 'incoming' | 'outgoing'
}

export type ConversationMessageGroup = {
  id: string
  isSystemMessages: boolean
  senderId: UserId
  direction: 'incoming' | 'outgoing'
  messages: ConversationMessage[]
}

export type Conversation = Pto.Conversations.Conversation & {
  isLoaded: boolean

  hasOnlineUsers: boolean
  draft: string

  typingUsers: string[]

  unreadMessages: number

  hasPrevMessages: boolean
  messages: ConversationMessage[]
  hasNextMessages: boolean
}

export type ConversationState = {
  currentMessage: string
  currentUser?: Pto.Conversations.User
  activeConversation?: Conversation
  conversations: Conversation[]
  currentMessages: ConversationMessageGroup[]
  totalUnreadMessages: number

  state: 'Idle' | 'Loading' | 'Ready' | 'Error'
}

export interface SendTypingServiceParams {
  conversationId: ConversationId
  userId: UserId
  content: string
  isTyping: boolean
}

export interface SendTypingParams extends SendTypingServiceParams {
  throttle: boolean
}

export class ConversationServiceConnected extends Event {
  constructor() {
    super('connected')
  }
}

export class ConversationServiceDisconnected extends Event {
  constructor() {
    super('disconnected')
  }
}

export class ConversationListLoadedEvent extends Event {
  constructor() {
    super('conversation-list-loaded')
  }
}

export class ConversationLoadedEvent extends CustomEvent<string> {
  constructor(conversationId: string) {
    super('conversation-loaded', { detail: conversationId })
  }
}

export interface ConversationsEventMap {
  connected: ConversationServiceConnected
  disconnected: ConversationServiceDisconnected
  'conversation-list-loaded': ConversationListLoadedEvent
  'conversation-loaded': ConversationLoadedEvent
}

export interface ConversationET extends EventTarget {
  addEventListener<K extends keyof ConversationsEventMap>(type: K, listener: (ev: ConversationsEventMap[K]) => void, options?: boolean | AddEventListenerOptions): void
  addEventListener(type: string, callback: EventListenerOrEventListenerObject | null, options?: EventListenerOptions | boolean): void
  removeEventListener<K extends keyof ConversationsEventMap>(type: K, callback: (ev: ConversationsEventMap[K]) => void): void
  removeEventListener(type: string, callback: EventListenerOrEventListenerObject): void
}

export const ConversationsEventTarget = EventTarget as { new (): ConversationET; prototype: ConversationET }

export interface IConversationsService extends ConversationET {}
