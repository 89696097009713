import { PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input, Space } from 'antd'
import styles from './AddDependencyField.module.scss'

const AddDependencyField = () => {
  return (
    <Form.List name={['value', 'fields']}>
      {(fields, { add, remove }) => (
        <>
          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Add Field(s)
            </Button>
          </Form.Item>
          {fields.map(({ key, name, ...restField }) => (
            <div key={key} className={styles.itemsColumn}>
              <Space.Compact size="large">
                <div className={styles.formItem}>
                  <Form.Item {...restField} name={[name, 'key']} rules={[{ required: true, message: 'Missing item name' }]}>
                    <Input placeholder="Name" size="large" />
                  </Form.Item>
                </div>
                <Button type="primary" style={{ background: 'red' }} onClick={() => remove(name)}>
                  Remove
                </Button>
              </Space.Compact>

              <Space.Compact size="large">
                <div className={styles.formItem}>
                  <Form.Item {...restField} name={[name, 'value']} initialValue="" rules={[{ required: false, message: 'Missing field value' }]}>
                    <Input type="number" placeholder="Value" />
                  </Form.Item>
                </div>
                <div className={styles.formItem}>
                  <Form.Item {...restField} name={[name, 'label']} initialValue="" rules={[{ required: false, message: 'Missing item label' }]}>
                    <Input type="number" placeholder="Label" />
                  </Form.Item>
                </div>
              </Space.Compact>
            </div>
          ))}
        </>
      )}
    </Form.List>
  )
}

export default AddDependencyField
