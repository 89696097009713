import dayjs from 'dayjs'
import { useContext } from 'react'
import { Pto } from '@merchx-v3/pto'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { PresentationAPI } from '@merchx-v3/shared-functions'
import { Avatar, Dropdown, Image, List, MenuProps, Popover, Skeleton, Space, Tooltip, Typography } from 'antd'
import { ClockCircleOutlined, EllipsisOutlined, CheckOutlined, PrinterOutlined, ReconciliationOutlined, SkinOutlined, ThunderboltOutlined, FormOutlined } from '@ant-design/icons'

import { PortalContext } from 'components/App/App'
import { useUser } from 'app/auth'

import { useAddDesignerMutation, useRemoveDesignerMutation } from 'app/api/design-requests'

import styles from './DesignRequestListItem.module.scss'

type Props = {
  designRequest: Pto.DesignRequests.DashboardDesignRequest
}

const completedDesignRequestStatuses = [Pto.DesignRequests.Status.Completed, Pto.DesignRequests.Status.Canceled, Pto.DesignRequests.Status.Removed]

export const DesignRequestListItem = ({ designRequest }: Props) => {
  const [user] = useUser()

  const [addDesigner] = useAddDesignerMutation()
  const [removeDesigner] = useRemoveDesignerMutation()

  const { portal } = useContext(PortalContext)

  const handleTakeTask = () => {
    if (!user) return

    addDesigner({
      designRequestId: designRequest.id,
      designerId: user.id,
      designerEmail: user.email,
      designerFirstName: user.firstName,
      designerLastName: user.lastName
    })
      .unwrap()
      .then(() => {
        toast.success('User assigned to Design request successfully')
      })
      .catch((error) => toast.error(error))
  }

  const handleRemoveDesigner = (designerId: string) => {
    removeDesigner({ designRequestId: designRequest.id, designerId })
      .unwrap()
      .then(() => {
        toast.success('User removed from Design request successfully')
      })
      .catch((error) => toast.error(error))
  }

  const formatDate = (date: Date) => {
    const formatedDate = dayjs(date).format('MM/DD/YYYY HH:mm:ss')
    return <Typography>{formatedDate}</Typography>
  }

  const actionsList: React.ReactNode[] = []

  // Add created date
  actionsList.push(
    <Space className={styles.iconCreatedDate}>
      <ClockCircleOutlined />
      Created time: {formatDate(designRequest.creationDate)}
    </Space>
  )

  // Add last modified date
  actionsList.push(
    <Space className={styles.iconModifiedDate}>
      <FormOutlined />
      Last modified time: {formatDate(designRequest.modificationDate)}
    </Space>
  )

  // Add Products popover
  actionsList.push(
    <Popover
      title="Products:"
      content={designRequest.products.map((productPreview, index) => (
        <Typography key={`${index}-${productPreview}`}>{`${index + 1}) ${productPreview}`}</Typography>
      ))}
    >
      <Space className={styles.iconText}>
        <SkinOutlined />
        <Typography>{designRequest.numberOfProducts}</Typography>
      </Space>
    </Popover>
  )

  // Add Print areas
  actionsList.push(
    <Popover
      title="Print areas:"
      content={designRequest.printAreas.map((printArea, index) => (
        <Typography key={`${index}-${printArea.printArea}`}>{`${index + 1}) ${printArea.printArea}`}</Typography>
      ))}
    >
      <Space className={styles.iconText}>
        <PrinterOutlined />
        <Typography>{designRequest.printAreas.length}</Typography>
      </Space>
    </Popover>
  )

  // Add sale source
  actionsList.push(
    <Space className={styles.iconSaleSource}>
      <ReconciliationOutlined />
      <Typography>{designRequest.saleSource.replace('_', ' ')}</Typography>
    </Space>
  )

  // Add mark as completed icon
  completedDesignRequestStatuses.includes(designRequest.status) &&
    actionsList.push(
      <Space className={styles.iconTextTime}>
        <CheckOutlined style={{ color: 'forestgreen' }} />
      </Space>
    )

  // Add designer avatars
  if (designRequest.designers.length) {
    actionsList.push(
      <Avatar.Group>
        {designRequest.designers.map((designer) => (
          <Dropdown
            key={designer.designerId}
            menu={{
              items: [
                {
                  label: <Typography onClick={() => handleRemoveDesigner(designer.designerId)}>Remove</Typography>,
                  key: '0'
                }
              ]
            }}
            trigger={['click']}
          >
            {/* findDOMNode warning */}
            <Tooltip title={designer.designerPreview} placement="top">
              <Avatar style={{ backgroundColor: '#bababa' }}>{designer.designerPreview[0] || 'U'}</Avatar>
            </Tooltip>
          </Dropdown>
        ))}
      </Avatar.Group>
    )
  }

  // Add dropdown menu
  const designRequestMenuItems: MenuProps['items'] = []

  if (!designRequest.designers.some((assignedDesigner) => assignedDesigner.designerId === user?.id)) {
    designRequestMenuItems.push({
      key: 'TakeTaskActionKey',
      label: <Typography onClick={handleTakeTask}>Take task</Typography>
    })
  }

  if (designRequestMenuItems.length) {
    actionsList.push(
      <Dropdown menu={{ items: designRequestMenuItems }} placement="bottomRight">
        <EllipsisOutlined />
      </Dropdown>
    )
  }

  if (designRequest.rushOptions.length) {
    actionsList.push(
      <Popover
        title="Rush options:"
        content={designRequest.rushOptions.map((rushOption, index) => (
          <Typography key={`${index}-${rushOption.rushOptionName}`}>{`${index + 1}) ${rushOption.rushOptionName}`}</Typography>
        ))}
      >
        <Space className={styles.iconText}>
          <ThunderboltOutlined />
          <Typography>{designRequest.rushOptions.map((item) => item.rushOptionName).join(', ')}</Typography>
        </Space>
      </Popover>
    )
  }

  return (
    <List.Item
      key={'124'}
      className={styles.listItem}
      actions={actionsList}
      extra={
        <Avatar.Group className={styles.rowImagesPreview} maxCount={1} shape="square">
          {designRequest.printAreas
            .map((designRequestPrintArea) =>
              designRequestPrintArea.referenceImages.map((imageUrl) => (
                <Image
                  key={`${designRequest.id}-${imageUrl}`}
                  width={90}
                  height={90}
                  placeholder={<Skeleton.Avatar active size={90} shape="square" />}
                  src={`/api/images/${imageUrl}?width=200&height=200`}
                  preview={true}
                />
              ))
            )
            .flat()}
        </Avatar.Group>
      }
    >
      <List.Item.Meta
        title={
          <Link to={`${portal}/design-requests/${designRequest.id}`} relative="path">
            Design request {designRequest.number} for{' '}
            {PresentationAPI.getChatUserPreview({
              email: designRequest.customerEmail,
              firstName: designRequest.customerFirstName,
              lastName: designRequest.customerLastName,
              username: ''
            })}
          </Link>
        }
        description={designRequest.printAreas[0]?.description ? designRequest.printAreas[0].description : 'No description'}
      />
    </List.Item>
  )
}

export default DesignRequestListItem
