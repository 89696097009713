import { forwardRef, useImperativeHandle, useState } from 'react'
import { toast } from 'react-toastify'
import { Space, Typography } from 'antd'
import { Pto } from '@merchx-v3/pto'
import ArtworkUpload from '../../../admin/features/plugins/components/ArtworkUpload'
import { FieldComponentProps } from '../types'
import ErrorContainer from '../ErrorContainer'

type RefProps = {
  validate: () => boolean
}

const ArtworkField = forwardRef<RefProps, FieldComponentProps<Pto.Plugins.ValueType.Artworks>>((componentProps, forwardedRef) => {
  const { field, ...props } = componentProps

  const [isShowError, setIsShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useImperativeHandle(forwardedRef, () => ({
    validate: () => {
      if (!field.value.backSide && !field.value.frontSide && field.isRequired) {
        toast.error(`Artwork field ${field.displayName} for ${props.targetId} should have at least front or back side image`)
        setErrorMessage(`Artwork field ${field.displayName} for ${props.targetId} should have at least front or back side image`)
        setIsShowError(true)
        return false
      }

      return true
    }
  }))

  return (
    <ErrorContainer isShowError={isShowError} errorMessage={errorMessage}>
      <Space direction="vertical">
        <Typography>{field.displayName}</Typography>
        <Space style={{ display: 'flex', marginBottom: 8, justifyContent: 'space-between' }}>
          <ArtworkUpload
            targetId={props.targetId}
            value={field.value.frontSide}
            side={Pto.Images.PrintSide.Front}
            field={field}
            disabled={props.disabled}
            onChange={(newUrl) => {
              props.onChange({
                frontSide: newUrl,
                backSide: field.value.backSide
              })
              setErrorMessage('')
              setIsShowError(false)
            }}
          />

          <ArtworkUpload
            targetId={props.targetId}
            value={field.value.backSide}
            side={Pto.Images.PrintSide.Back}
            field={field}
            disabled={props.disabled}
            onChange={(newUrl) => {
              props.onChange({
                frontSide: field.value.frontSide,
                backSide: newUrl
              })
              setErrorMessage('')
              setIsShowError(false)
            }}
          />
        </Space>
      </Space>
    </ErrorContainer>
  )
})

export default ArtworkField
