import { Pto } from '@merchx-v3/pto'
import { MessageSeparator } from '@chatscope/chat-ui-kit-react'

import { ConversationMessage } from 'app/chat/types'
import dayjs from 'dayjs'

type Props = {
  message: ConversationMessage<Pto.Conversations.MessageContentType.System>
}

export const SystemMessage = (props: Props) => {
  const { message } = props
  const date = dayjs(message.createdAt).format('MM/DD/YYYY HH:mm:ss').toString()

  return (
    <MessageSeparator id={message.id} style={{ marginTop: 12, marginBottom: 12 }}>
      {message.content}
      <br />
      {date}
    </MessageSeparator>
  )
}
