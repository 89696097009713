enum AppRoutes {
  // DESIGNERS ROUTES

  main = '',
  designs = 'designs',
  designRequests = 'design-requests',
  chats = 'chats',
  profile = 'profile'
}

export default AppRoutes
