import { Pto } from '@merchx-v3/pto'
import { toast } from 'react-toastify'
import { WebSocket } from '@merchx-v3/web-socket'
import { AnyAction, createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { fulfillmentTasksApi } from 'app/api/fulfillment-tasks-api'
import { projectsApiV2 } from 'app/api/projects-api'
import { shipmentsApi } from 'app/api/shipments-api'

export interface SagasState {
  isConnected: boolean
  list: Pto.Sagas.SagaWithSteps[]
}

const initialState: SagasState = {
  isConnected: false,
  list: []
}

const findOrCreateSaga = (state: SagasState, sagaInfo: Pto.Sagas.SagaWithSteps) => {
  const { steps, channelType, channelId, type, id } = sagaInfo
  let saga = state.list.find((item) => item.channelType === channelType && item.channelId === channelId && item.type === type && item.id === id)
  if (!saga) {
    saga = {
      channelType,
      channelId,
      type,
      id,
      activeStep: sagaInfo.activeStep,
      steps: steps.map((item) => ({ ...item }))
    }

    state.list.push(saga)
  }
  return saga
}

const startSaga = (state: SagasState, sagaInfo: Pto.Sagas.SagaWithSteps) => {
  const saga = findOrCreateSaga(state, sagaInfo)

  const step = saga.steps[0]
  if (!step) {
    toast.error(`${sagaInfo.type} step 0 undefined`)
    return
  }

  if (step.status === 'wait') {
    saga.activeStep = sagaInfo.activeStep
    step.status = 'process'
  }
}

const sagaStepFailed = (state: SagasState, sagaInfo: Pto.Sagas.SagaWithSteps, message: string) => {
  const saga = findOrCreateSaga(state, sagaInfo)
  const sagaStep = saga.steps[sagaInfo.activeStep]
  sagaStep.status = 'error'
  sagaStep.title = 'Failed'
  sagaStep.description = message

  const prevStepIndex = sagaInfo.activeStep - 1
  saga.activeStep = prevStepIndex
  const prevStep = saga.steps[prevStepIndex]
  if (prevStep) {
    prevStep.status = 'process'
    prevStep.title = 'Processing'
  }
}

const sagaStepCompleted = (state: SagasState, sagaInfo: Pto.Sagas.SagaWithSteps, message: string) => {
  const saga = findOrCreateSaga(state, sagaInfo)
  const sagaStep = saga.steps[sagaInfo.activeStep]
  sagaStep.status = 'finish'
  sagaStep.title = 'Finished'
  sagaStep.description = message

  const nextStepIndex = sagaInfo.activeStep + 1
  saga.activeStep = nextStepIndex

  const nextStep = saga.steps[nextStepIndex]
  if (nextStep) {
    nextStep.status = 'process'
    nextStep.title = 'Processing'
  } else {
    // Saga completed
    state.list = state.list.filter((item) => item.id !== sagaInfo.id)
  }
}

export const sagasSlice = createSlice({
  name: 'sagas',
  initialState,
  reducers: {
    setIsConnected(state, action: PayloadAction<boolean>) {
      state.isConnected = action.payload
    },
    resetSaga(state, action: PayloadAction<Pto.Sagas.SagaWithSteps>) {
      state.list = state.list.filter((item) => item.id !== action.payload.id)
    },
    sagaStatusChanged(state, action: PayloadAction<WebSocket.Sagas.Listeners.SagaStatusChangedPayload>) {
      const { saga: sagaInfo, message, isRollback } = action.payload
      if (isRollback) {
        sagaStepFailed(state, sagaInfo, message)
      } else {
        sagaStepCompleted(state, sagaInfo, message)
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(fulfillmentTasksApi.endpoints.sendTaskToFulfillment.matchFulfilled, (state, action) => {
        startSaga(state, action.payload)
      })
      .addMatcher(fulfillmentTasksApi.endpoints.cancelFulfillmentTask.matchFulfilled, (state, action) => {
        startSaga(state, action.payload)
      })
      .addMatcher(fulfillmentTasksApi.endpoints.cancelFulfillmentTaskItem.matchFulfilled, (state, action) => {
        startSaga(state, action.payload)
      })
      .addMatcher(fulfillmentTasksApi.endpoints.completeFulfillmentTaskItem.matchFulfilled, (state, action) => {
        startSaga(state, action.payload)
      })
      .addMatcher(fulfillmentTasksApi.endpoints.completeFulfillmentTask.matchFulfilled, (state, action) => {
        startSaga(state, action.payload)
      })
      .addMatcher(fulfillmentTasksApi.endpoints.holdFulfillmentTask.matchFulfilled, (state, action) => {
        startSaga(state, action.payload)
      })
      .addMatcher(fulfillmentTasksApi.endpoints.holdFulfillmentTaskItem.matchFulfilled, (state, action) => {
        startSaga(state, action.payload)
      })
      .addMatcher(fulfillmentTasksApi.endpoints.unholdFulfillmentTaskItem.matchFulfilled, (state, action) => {
        startSaga(state, action.payload)
      })
      .addMatcher(shipmentsApi.endpoints.sendToShipping.matchFulfilled, (state, action) => {
        startSaga(state, action.payload)
      })
      .addMatcher(shipmentsApi.endpoints.markShippingShipped.matchFulfilled, (state, action) => {
        startSaga(state, action.payload)
      })
      .addMatcher(shipmentsApi.endpoints.markShippingDelivered.matchFulfilled, (state, action) => {
        startSaga(state, action.payload)
      })
      .addMatcher(shipmentsApi.endpoints.cancelShipping.matchFulfilled, (state, action) => {
        startSaga(state, action.payload)
      })
      .addMatcher(shipmentsApi.endpoints.unholdShipping.matchFulfilled, (state, action) => {
        startSaga(state, action.payload)
      })
      .addMatcher(shipmentsApi.endpoints.holdShipping.matchFulfilled, (state, action) => {
        startSaga(state, action.payload)
      })
      .addMatcher(shipmentsApi.endpoints.updateShippingTaskInfo.matchFulfilled, (state, action) => {
        startSaga(state, action.payload)
      })
      .addMatcher(projectsApiV2.endpoints.updateProjectProductItems.matchFulfilled, (state, action) => {
        startSaga(state, action.payload)
      })
      .addMatcher(projectsApiV2.endpoints.createReorderProject.matchFulfilled, (state, action) => {
        startSaga(state, action.payload)
      })
  }
})

export const { resetSaga } = sagasSlice.actions

export const selectSagas = ({ sagas }: RootState, channelType: Pto.Sagas.ChannelType, channelId?: string) =>
  channelId ? sagas.list.filter((item) => item.channelType === channelType && item.channelId === channelId) : []

export const selectIsLoading = ({ sagas }: RootState, channelType: Pto.Sagas.ChannelType, channelId?: string) =>
  sagas.list.some((item) => (channelId ? item.channelType === channelType && item.channelId === channelId : false))

export const subscribeSliceToSagaEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  dispatch(sagasSlice.actions.setIsConnected(true))

  socket.on('saga-status-changed', (payload) => {
    dispatch(sagasSlice.actions.sagaStatusChanged(payload))
  })
}

export const unsubscribeSliceFromSagaEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  dispatch(sagasSlice.actions.setIsConnected(false))
  socket.off('saga-status-changed')
}

export default sagasSlice.reducer
