const environment = process.env.REACT_APP_ENV || 'development'

const protocol = ['staging', 'develop', 'production'].includes(environment) ? 'https' : 'http'

export const settings = {
  site: {
    protocol,
    domain: process.env.REACT_APP_DOMAIN || 'localhost:3004'
  },
  merchDomain: process.env.REACT_APP_MERCH_DOMAIN || 'localhost:3000'
}
