import { MainContainer, Search, Sidebar } from '@chatscope/chat-ui-kit-react'
import { useLocation } from 'react-router-dom'

import { ConversationList } from './ConversationList'
import { MessageList } from './MessageList'

const DesktopChat = () => {
  const location = useLocation()

  const conversationId = new URLSearchParams(location.search).get('id') || undefined

  console.log('Conversation ID: ', conversationId)

  return (
    <div>
      <div style={{ position: 'relative', height: '100vh' }}>
        <MainContainer>
          <Sidebar position="left" scrollable={false}>
            <Search placeholder="Search..." />
            <ConversationList defaultConversationId={conversationId} height="100vh" />
          </Sidebar>
          <MessageList />
        </MainContainer>
      </div>
    </div>
  )
}

export default DesktopChat
