import { forwardRef, useEffect, useImperativeHandle } from 'react'
import { toast } from 'react-toastify'
import { Form, Input, Select, Switch } from 'antd'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import { v4 } from 'uuid'
import { Pto } from '@merchx-v3/pto'
import { Plugins } from '@merchx-v3/plugins'
import styles from './AddModal.module.scss'
import AddDependencyField from './AddDependencyField'

type FormProps = {
  isRequired: boolean
  displayName: string
  fieldKey: string
  valueType: Pto.Plugins.ValueType
  value: string
}

type Props = {
  field?: Pto.Plugins.Fields.IField
  fieldRole: Pto.Plugins.Fields.FieldRole
  supplierSelectOptions: Pto.Option[]
  onCreateField: (field: Pto.Plugins.Fields.IField) => void
  onUpdateField: (field: Pto.Plugins.Fields.IField) => void
}

type RefProps = {
  submit: () => void
}

const AddModal = forwardRef<RefProps, Props>(({ field, fieldRole, supplierSelectOptions, onCreateField, onUpdateField }, forwardedRef) => {
  const [form] = Form.useForm()

  const valueType = Form.useWatch('valueType', form) as Pto.Plugins.ValueType
  const supplierSelectId = Form.useWatch('supplierSelect', form) as string

  useEffect(() => {
    if (valueType === Pto.Plugins.ValueType.SupplierSelect && supplierSelectId) {
      form.setFieldValue(
        'value',
        JSON.stringify({
          supplierSelectId,
          recordId: '',
          recordLabel: ''
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueType, supplierSelectId])

  useEffect(() => {
    if (!valueType || field?.valueType === valueType) return

    switch (valueType) {
      case Pto.Plugins.ValueType.Artworks: {
        form.setFieldValue(
          'value',
          JSON.stringify({
            backSide: '',
            frontSide: ''
          })
        )
        break
      }
      case Pto.Plugins.ValueType.Boolean: {
        form.setFieldValue('value', 'false')
        break
      }
      case Pto.Plugins.ValueType.Dependency: {
        form.setFieldValue('value', {
          fields: []
        })
        break
      }
      case Pto.Plugins.ValueType.Float: {
        form.setFieldValue('value', '0.0')
        break
      }
      case Pto.Plugins.ValueType.Integer: {
        form.setFieldValue('value', '0')
        break
      }
      case Pto.Plugins.ValueType.JSON: {
        form.setFieldValue('value', '{}')
        break
      }
      case Pto.Plugins.ValueType.Select: {
        form.setFieldValue(
          'value',
          JSON.stringify({
            items: [],
            value: ''
          })
        )

        break
      }
      case Pto.Plugins.ValueType.String: {
        form.setFieldValue('value', '')
        break
      }
      case Pto.Plugins.ValueType.SupplierSelect: {
        form.setFieldValue(
          'value',
          JSON.stringify({
            supplierSelectId: '',
            recordId: '',
            recordLabel: ''
          })
        )

        break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueType])

  useEffect(() => {
    if (field) {
      form.setFieldsValue({
        isRequired: field.isRequired,
        displayName: field.displayName,
        fieldKey: field.fieldKey,
        valueType: field.valueType,
        value: field.valueType === Pto.Plugins.ValueType.Dependency ? field.value : JSON.stringify(field.value)
      })
    } else {
      form.resetFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field])

  useImperativeHandle(forwardedRef, () => ({
    submit: () => {
      form.submit()
    }
  }))

  const onFinish = (values: FormProps) => {
    const data = {
      id: field?.id || v4(),
      fieldKey: field?.fieldKey || values.fieldKey,
      displayName: values.displayName,
      isRequired: values.isRequired,
      fieldRole,
      valueType: field?.valueType || values.valueType,
      value:
        values.valueType === Pto.Plugins.ValueType.JSON || values.valueType === Pto.Plugins.ValueType.Dependency
          ? values.value
          : Plugins.Transformers.Values.stringToValue(values.valueType, values.value).value
    }

    if (field) {
      onUpdateField(data)
    } else {
      onCreateField(data)
    }

    form.resetFields()
  }

  const handleFinishFailed = (errorInfo: ValidateErrorEntity) => {
    toast.error(errorInfo?.errorFields?.map((errField) => errField.errors.join('\n')).join('\n'))
  }

  return (
    <Form name="addProductField" layout="vertical" form={form} className={styles.form} onFinishFailed={handleFinishFailed} onFinish={onFinish}>
      <Form.Item label="Field" name="fieldKey" rules={[{ required: true, message: 'Please input Field!' }]}>
        <Input disabled={!!field} size="large" placeholder="Field" />
      </Form.Item>
      <Form.Item label="Display Name" name="displayName" rules={[{ required: true, message: 'Please input Display Name!' }]}>
        <Input size="large" placeholder="Display Name" />
      </Form.Item>
      <Form.Item label="Type" name="valueType" rules={[{ required: true, message: 'Please select Type!' }]}>
        <Select disabled={!!field} size="large" style={{ width: '100%' }} placeholder="Type">
          {Object.values(Pto.Plugins.ValueType).map((item) => (
            <Select.Option key={item}>{item}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      {valueType === Pto.Plugins.ValueType.SupplierSelect && (
        <Form.Item label="Supplier Select" name="supplierSelect" rules={[{ required: true, message: 'Please select Type!' }]}>
          <Select disabled={!!field} size="large" style={{ width: '100%' }} placeholder="Type">
            {supplierSelectOptions.map((item) => (
              <Select.Option key={item.value}>{item.label}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {valueType === Pto.Plugins.ValueType.Dependency && <AddDependencyField />}
      {valueType !== Pto.Plugins.ValueType.Dependency && (
        <Form.Item label="Default" name="value" initialValue="" rules={[{ required: false, message: 'Please input Default!' }]}>
          <Input size="large" placeholder="Default" />
        </Form.Item>
      )}
      <Form.Item label="Is Required" name="isRequired" valuePropName="checked" initialValue={true}>
        <Switch />
      </Form.Item>
    </Form>
  )
})

export default AddModal
