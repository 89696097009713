import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

const isFetchBaseQueryError = (error: unknown): error is FetchBaseQueryError => {
  return typeof error === 'object' && error != null && 'status' in error
}

const isErrorWithMessage = (error: unknown): error is { message: string } => {
  return typeof error === 'object' && error != null && 'message' in error && typeof (error as any).message === 'string'
}

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    // const error = action.error

    // if (isFetchBaseQueryError(action.errr)) {
    //   // you can access all properties of `FetchBaseQueryError` here
    //   const errMsg = 'error' in error ? error.error : JSON.stringify(error.data)
    //   toast.error(errMsg)
    // } else if (isErrorWithMessage(error)) {
    //   // you can access a string 'message' property here
    //   toast.error(error.message)
    // }

    console.warn('We got a rejected action!', action)

    // if (isRejectedWithValue(action)) {
    //   console.log('Rejected with value', action)
    //   toast.error(action.payload.data.error)
    //   return next(action)
    // }

    let error = action.error?.data?.message || ''
    // Bad requests
    if (action.payload.status) {
      console.log('Test2', action.payload)
      switch (action.payload.status) {
        case 400: {
          if (action.payload.data) {
            const { error, message } = action.payload.data
            if (message) {
              if (typeof message === 'string') {
                toast.error(message)
              } else {
                message?.forEach((error: string) => toast.error(error))
              }
            } else {
              if (typeof error === 'string') {
                toast.error(error)
              } else {
                error?.forEach((error: string) => toast.error(error))
              }
            }
          }
          break
        }

        case 409: {
          toast.error(action.payload.data.message)
          break
        }

        default:
        case 500: {
          toast.error(action.payload.data.message)
        }
      }
    } else {
      if (error) toast.error(error)
    }
  }

  return next(action)
}
