import { lazy } from 'react'
import { Role } from '@merchx-v3/pto'
import { Navigate, Routes, Route } from 'react-router-dom'

import { useUser } from 'app/auth'
import PrivateRoute from 'components/PrivateRoute'
import NotFoundPage from 'components/NotFoundPage'
import AppRoutes from './AppRoutes'
import Layout from '../Layout'

const EcomProductList = lazy(() => import('interfaces/sharedPages/EcomProductList'))
const EcomProduct = lazy(() => import('interfaces/sharedPages/EcomProduct'))
const SupplierProductList = lazy(() => import('interfaces/suppliers/features/supplierProducts/pages/SupplierProductList'))
const SupplierProduct = lazy(() => import('interfaces/suppliers/features/supplierProducts/pages/SupplierProduct'))
const SupplierSettingsPage = lazy(() => import('interfaces/suppliers/features/settings/pages/SupplierSettingsPage'))

// from shared pages
const CustomersList = lazy(() => import('interfaces/sharedPages/CustomerList'))
const CustomersReport = lazy(() => import('interfaces/sharedPages/CustomersReport'))
const CustomerDetails = lazy(() => import('interfaces/sharedPages/CustomerDetails'))
const Design = lazy(() => import('interfaces/sharedPages/Design'))
const DesignList = lazy(() => import('interfaces/sharedPages/DesignList'))
const EditProject = lazy(() => import('interfaces/sharedPages/EditProject'))
const InvoiceDetails = lazy(() => import('interfaces/sharedPages/InvoiceDetails'))
const ProfilePage = lazy(() => import('interfaces/sharedPages/ProfilePage'))
const ProjectList = lazy(() => import('interfaces/sharedPages/ProjectList'))
const ProjectHistory = lazy(() => import('interfaces/sharedPages/ProjectHistory'))
const MyDesignRequests = lazy(() => import('interfaces/designers/pages/Dashboard'))
const DesignRequest = lazy(() => import('interfaces/sharedPages/DesignRequest'))
const DesignRequestHistory = lazy(() => import('interfaces/sharedPages/DesignRequestHistory'))
const DesignRequestList = lazy(() => import('interfaces/sharedPages/DesignRequestList'))
const FulfillmentList = lazy(() => import('interfaces/sharedPages/FulfillmentList'))
const FulfillmentTask = lazy(() => import('interfaces/sharedPages/FulfillmentTask'))
const FulfillmentTaskHistory = lazy(() => import('interfaces/sharedPages/FulfillmentHistory'))
const ShippingList = lazy(() => import('interfaces/sharedPages/ShippingList'))
const ShippingTask = lazy(() => import('interfaces/sharedPages/ShippingTask'))
const ShippingHistory = lazy(() => import('interfaces/sharedPages/ShippingHistory'))
const EditDesignTemplate = lazy(() => import('interfaces/sharedPages/EditDesignTemplate'))
const EditProcessingTemplate = lazy(() => import('interfaces/suppliers/features/processingTemplates/pages/EditProcessingTemplate'))
const EditSupplierPluginDetails = lazy(() => import('interfaces/sharedPages/EditPluginDetails'))

const Router = () => {
  const [user, isLoading] = useUser()

  if (!user && !isLoading) return <Navigate to="/login" />

  return (
    <Layout shouldWaitUser>
      <Routes>
        <Route
          path={AppRoutes.customerReport}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <CustomersReport />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.customers}/:customerId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <CustomerDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.customers}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <CustomersList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.ecomProducts}/:ecomProductId`} // where is AppRoutes
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <EcomProduct />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.ecomProducts}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <EcomProductList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.supplierProducts}/:supplierProductId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierProduct />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.supplierProducts}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierProductList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.profile}`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ProfilePage />
            </PrivateRoute>
          }
        />{' '}
        <Route
          path={`${AppRoutes.projects}/new`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <EditProject isNew />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.projects}/:projectId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <EditProject />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.projects}/:projectId/history`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ProjectHistory />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.projects}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ProjectList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.invoices}/:invoiceId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <InvoiceDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.design}/:designId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <Design />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.design}`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <DesignList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.designRequest}/me`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <MyDesignRequests />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.designRequest}/:designRequestId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <DesignRequest />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.designRequest}/:designRequestId/history`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <DesignRequestHistory />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.designRequest}`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <DesignRequestList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.fulfillment}/:fulfillmentTaskId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <FulfillmentTask />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.fulfillment}/:fulfillmentTaskId/history`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <FulfillmentTaskHistory />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.fulfillment}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <FulfillmentList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.shipping}/:shippingTaskId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ShippingTask />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.shipping}/:shippingTaskId/history`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ShippingHistory />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.shipping}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ShippingList />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.settings}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <SupplierSettingsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.settings}/:supplierId/:pluginType/:pluginId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <EditSupplierPluginDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.dashboard}/:supplierId/${AppRoutes.designTemplates}/:templateId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <EditDesignTemplate />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.dashboard}/:supplierId/${AppRoutes.processingTemplates}/:templateId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <EditProcessingTemplate />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Layout>
  )
}

export default Router
