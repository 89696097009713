import React, { Suspense, useContext, useEffect, useState } from 'react'
import { Layout as AntdLayout, Spin } from 'antd'
import { PortalContext } from 'components/App/App'

import { useUser } from 'app/auth'
import SiderMenu from '../SiderMenu'

import styles from './Layout.module.scss'

type Props = {
  shouldWaitUser?: boolean
}

const Layout: React.FC<React.PropsWithChildren<Props>> = ({ children, shouldWaitUser = false }) => {
  const [collapsedMainMenu, setCollapsedMainMenu] = useState(false)
  const [user] = useUser()

  const { setPortal } = useContext(PortalContext)

  useEffect(() => {
    setPortal('/admin')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AntdLayout hasSider className={styles.layout}>
      <AntdLayout.Sider collapsible={true} collapsed={collapsedMainMenu} onCollapse={setCollapsedMainMenu} width={242}>
        <SiderMenu collapsed={collapsedMainMenu} />
      </AntdLayout.Sider>
      <AntdLayout>
        <AntdLayout.Content className={styles.content}>
          <Suspense
            fallback={
              <div style={{ width: '100%', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Spin size="large" />
              </div>
            }
          >
            {shouldWaitUser && !user ? (
              <div style={{ width: '100%', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Spin size="large" />
              </div>
            ) : (
              children
            )}
          </Suspense>
        </AntdLayout.Content>
      </AntdLayout>
    </AntdLayout>
  )
}

export default Layout
