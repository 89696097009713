import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import dashboardReducer from 'interfaces/admin/features/dashboard/dashboardSlice'
import invoicesReducer from 'interfaces/admin/features/invoices/invoicesSlice'
import ordersReducer from 'interfaces/admin/features/orders/ordersSlice'
import pluginsReducer from 'interfaces/admin/features/plugins/pluginsSlice'

import sagasReducer from 'interfaces/admin/features/sagas/sagasSlice'

import { filesApi } from 'shared/files-api'

import { conversationsApi } from './api/conversations-api'
import { customersApi } from './api/customers-api'
import { designsApi } from './api/designs-api'
import { designRequestsApi } from './api/design-requests'
import { designTemplatesApi } from './api/design-templates-api'
import { ecomProductsApi } from './api/ecom-products-api'
import { fulfillmentTasksApi } from './api/fulfillment-tasks-api'
import { notificationsApi } from './api/notifications-api'
import { paymentsApi } from './api/payments-api'
import { pluginsApiV2 } from './api/plugins-api'
import { processingTemplatesApi } from './api/processing-templates-api'
import { projectsApiV2 } from './api/projects-api'
import { reportingApi } from './api/reporting-api'
import { sagasApi } from './api/sagas-api'
import { shipmentsApi } from './api/shipments-api'
import { supplierProductsApi } from './api/supplier-products-api'
import { suppliersApi } from './api/suppliers-api'
import { usersApi } from './api/users-api'
import { webSocketMiddleware } from './websocket-middleware'

import { rtkQueryErrorLogger } from './error-middleware'
import { appWebSocket } from './websocket'

export const store = configureStore({
  reducer: {
    [conversationsApi.reducerPath]: conversationsApi.reducer,
    [customersApi.reducerPath]: customersApi.reducer,
    dashboard: dashboardReducer,
    [designRequestsApi.reducerPath]: designRequestsApi.reducer,
    [designsApi.reducerPath]: designsApi.reducer,
    [ecomProductsApi.reducerPath]: ecomProductsApi.reducer,
    [filesApi.reducerPath]: filesApi.reducer,
    invoices: invoicesReducer,
    orders: ordersReducer,
    plugins: pluginsReducer,
    [paymentsApi.reducerPath]: paymentsApi.reducer,
    [pluginsApiV2.reducerPath]: pluginsApiV2.reducer,
    [fulfillmentTasksApi.reducerPath]: fulfillmentTasksApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [projectsApiV2.reducerPath]: projectsApiV2.reducer,
    [reportingApi.reducerPath]: reportingApi.reducer,
    [sagasApi.reducerPath]: sagasApi.reducer,
    sagas: sagasReducer,
    [shipmentsApi.reducerPath]: shipmentsApi.reducer,
    [supplierProductsApi.reducerPath]: supplierProductsApi.reducer,
    [suppliersApi.reducerPath]: suppliersApi.reducer,
    [designTemplatesApi.reducerPath]: designTemplatesApi.reducer,
    [processingTemplatesApi.reducerPath]: processingTemplatesApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(rtkQueryErrorLogger)
      .concat(webSocketMiddleware(appWebSocket))
      .concat(conversationsApi.middleware)
      .concat(filesApi.middleware)
      .concat(designRequestsApi.middleware)
      .concat(designsApi.middleware)
      .concat(paymentsApi.middleware)
      .concat(ecomProductsApi.middleware)
      .concat(pluginsApiV2.middleware)
      .concat(suppliersApi.middleware)
      .concat(projectsApiV2.middleware)
      .concat(reportingApi.middleware)
      .concat(fulfillmentTasksApi.middleware)
      .concat(notificationsApi.middleware)
      .concat(sagasApi.middleware)
      .concat(shipmentsApi.middleware)
      .concat(supplierProductsApi.middleware)
      .concat(designTemplatesApi.middleware)
      .concat(processingTemplatesApi.middleware)
      .concat(customersApi.middleware)
      .concat(usersApi.middleware)
})

void setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
