import React from 'react'
import querystringify from 'querystringify'
import { Navigate, useLocation } from 'react-router-dom'

import { useIsLoggedIn } from 'app/auth'

type Props = {}

const AnonymousRoute = ({ children }: Props & React.PropsWithChildren) => {
  const location = useLocation()

  const [isLoggedIn] = useIsLoggedIn()

  if (isLoggedIn) {
    const { callbackUrl } = querystringify.parse(location.search) as any

    if (callbackUrl) {
      return <Navigate to={callbackUrl} />
    } else {
      return <Navigate to="/" />
    }
  }

  return <>{children}</>
}

export default AnonymousRoute
