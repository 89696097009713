import { Layout, Breadcrumb, Spin, theme } from 'antd'
import { PropsWithChildren, Suspense } from 'react'

const { Content, Header } = Layout

type Props = PropsWithChildren & {
  title: string
  breadcrumbs: (
    | {
        title: JSX.Element
      }
    | {
        title: string
      }
  )[]
}

export const RegularPage = ({ title, breadcrumbs, children }: Props) => {
  const {
    token: { colorBgContainer }
  } = theme.useToken()

  return (
    <Layout>
      <Suspense
        fallback={
          <div style={{ width: '100%', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spin size="large" />
          </div>
        }
      >
        <Header style={{ padding: 0, backgroundColor: colorBgContainer }}>{title}</Header>
        <Content style={{ margin: '0 16px' }}>
          {breadcrumbs.length && <Breadcrumb style={{ textTransform: 'capitalize', cursor: 'pointer', margin: '16px 0' }} items={breadcrumbs} />}
          <div style={{ background: colorBgContainer }}>{children}</div>
        </Content>
      </Suspense>
    </Layout>
  )
}

export default RegularPage
