import { Pto } from '@merchx-v3/pto'
import { WebSocket } from '@merchx-v3/web-socket'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { store } from 'app/store'
import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

type DesignRequestId = string

type ChangeNameArgs = {
  designRequestId: string
  name: string
}

type ChangeStatusArgs = {
  designRequestId: string
  status: Pto.DesignRequests.Status
}

type SetApprovementFlowArgs = {
  designRequestId: string
  flow: Pto.DesignRequests.ApprovementFlow
}

type SetSourceFileUploadFlowArgs = {
  designRequestId: string
  flow: Pto.DesignRequests.SourceFileUploadFlow
}

export const designRequestsApi = createApi({
  reducerPath: 'designRequestsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['DesignRequests'],
  endpoints: (builder) => ({
    createDesignRequest: builder.mutation<void, Pto.DesignRequests.Create>({
      query: (newDesignRequest) => {
        return {
          url: `/design-requests`,
          body: newDesignRequest,
          method: 'POST'
        }
      },
      invalidatesTags: [
        { type: 'DesignRequests', id: 'OPTIONS' },
        { type: 'DesignRequests', id: 'LIST' }
      ]
    }),
    designerDashboard: builder.query<Pto.DesignRequests.Dashboard, Pto.DesignRequests.SearchDesignRequestsQuery>({
      query: (params) => ({
        url: '/design-requests/dashboard',
        method: 'GET',
        params
      }),
      providesTags: (dashboardData) =>
        getProvidedTags(
          'DesignRequests',
          'DASHBOARD',
          dashboardData?.items.map((item) => item.id)
        )
    }),
    designRequestsDashboard: builder.query<Pto.DesignRequests.DesignRequestsDashboard, void>({
      query: () => ({
        url: '/design-requests/design-requests-dashboard',
        method: 'GET'
      })
    }),
    searchDesignRequests: builder.query<Pto.DesignRequests.SearchDesignRequests, Pto.DesignRequests.SearchDesignRequestsQuery>({
      query: (query) => ({
        url: '/design-requests/search',
        method: 'GET',
        params: query
      }),
      providesTags: (searchResult, _error, args) =>
        getProvidedTags(
          'DesignRequests',
          'LIST',
          searchResult?.items.map((item) => item.id)
        )
    }),
    designRequest: builder.query<Pto.DesignRequests.DesignRequest, DesignRequestId>({
      query: (designRequestId) => ({
        url: `/design-requests/${designRequestId}`,
        method: 'GET'
      }),
      providesTags: (_result, _error, designRequestId) => getProvidedTags('DesignRequests', designRequestId)
    }),
    changeDesignRequestName: builder.mutation<void, ChangeNameArgs>({
      query: ({ designRequestId, name }) => {
        return {
          url: `/design-requests/${designRequestId}/change-name`,
          body: { name },
          method: 'PATCH'
        }
      },
      invalidatesTags: (_result, _error, args) => [{ type: 'DesignRequests', id: args.designRequestId }]
    }),
    changeDesignRequestStatus: builder.mutation<void, ChangeStatusArgs>({
      query: ({ designRequestId, status }) => {
        return {
          url: `/design-requests/${designRequestId}/change-status`,
          body: { status },
          method: 'PATCH'
        }
      },
      invalidatesTags: (_result, _error, args) => [{ type: 'DesignRequests', id: args.designRequestId }]
    }),
    designRequestOptions: builder.query<Pto.DesignRequests.DesignRequestOption[], Pto.DesignRequests.GetOptions>({
      query: ({ projectId, searchText, size }) => ({
        url: `/design-requests/options`,
        params: { projectId, searchText, size },
        method: 'GET'
      }),
      providesTags: (optionsResult, _error, _args) =>
        getProvidedTags(
          'DesignRequests',
          'OPTIONS',
          optionsResult?.map((item) => item.id)
        )
    }),
    designRequestSaleSources: builder.query<Pto.Option[], void>({
      query: () => ({
        url: `/design-requests/sale-sources`,
        method: 'GET'
      })
    }),
    designRequestAttributes: builder.query<Pto.Option[], void>({
      query: () => ({
        url: `/design-requests/attributes`,
        method: 'GET'
      })
    }),
    designRequestAttributeValues: builder.query<Pto.Option[], string>({
      query: (attribute: string) => ({
        url: `/design-requests/attribute-values`,
        params: { attribute },
        method: 'GET'
      })
    }),
    updateArtworkFiles: builder.mutation<void, Pto.DesignRequests.UpdateArtworkFiles & { designRequestId: string }>({
      query: ({ designRequestId, projectProductItemId, printArea, artworkUrls }) => {
        return {
          url: `/design-requests/${designRequestId}/update-artwork-files`,
          body: { projectProductItemId, printArea, artworkUrls },
          method: 'PATCH'
        }
      },
      invalidatesTags: (_result, _error, args) => [{ type: 'DesignRequests', id: args.designRequestId }]
    }),
    uploadArtworkMockupUrl: builder.mutation<void, Pto.DesignRequests.UploadArtworkMockupUrl & { designRequestId: string }>({
      query: ({ designRequestId, projectProductItemId, printArea, mockupUrl }) => {
        return {
          url: `/design-requests/${designRequestId}/upload-artwork-mockup-url`,
          body: { projectProductItemId, printArea, mockupUrl },
          method: 'PATCH'
        }
      },
      invalidatesTags: (_result, _error, args) => [{ type: 'DesignRequests', id: args.designRequestId }]
    }),
    setApprovementFlow: builder.mutation<void, SetApprovementFlowArgs>({
      query: ({ designRequestId, ...body }) => {
        return {
          url: `/design-requests/${designRequestId}/set-approvement-flow`,
          body,
          method: 'PATCH'
        }
      },
      invalidatesTags: (_result, _error, args) => [{ type: 'DesignRequests', id: args.designRequestId }]
    }),
    setSourceFileUploadFlow: builder.mutation<void, SetSourceFileUploadFlowArgs>({
      query: ({ designRequestId, ...body }) => {
        return {
          url: `/design-requests/${designRequestId}/set-source-file-upload-flow`,
          body,
          method: 'PATCH'
        }
      },
      invalidatesTags: (_result, _error, args) => [{ type: 'DesignRequests', id: args.designRequestId }]
    }),
    addDesigner: builder.mutation<void, Pto.DesignRequests.AddDesigner>({
      query: (body) => ({
        url: '/design-requests/add-designer',
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, args) => [{ type: 'DesignRequests', id: args.designRequestId }]
    }),
    removeDesigner: builder.mutation<void, Pto.DesignRequests.RemoveDesigner>({
      query: (body) => ({
        url: '/design-requests/remove-designer',
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, args) => [{ type: 'DesignRequests', id: args.designRequestId }]
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Design request') {
    store.dispatch(designRequestsApi.util.invalidateTags([{ type: 'DesignRequests', id: payload.entityId }]))
  }
}

export const subscribeToDesignRequestEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromDesignRequestEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const {
  useAddDesignerMutation,
  useCreateDesignRequestMutation,
  useChangeDesignRequestNameMutation,
  useChangeDesignRequestStatusMutation,
  useDesignerDashboardQuery,
  useDesignRequestsDashboardQuery,
  useDesignRequestAttributeValuesQuery,
  useDesignRequestAttributesQuery,
  useDesignRequestOptionsQuery,
  useDesignRequestQuery,
  useDesignRequestSaleSourcesQuery,
  useRemoveDesignerMutation,
  useSearchDesignRequestsQuery,
  useSetApprovementFlowMutation,
  useSetSourceFileUploadFlowMutation,
  useUpdateArtworkFilesMutation,
  useUploadArtworkMockupUrlMutation
} = designRequestsApi
