import { Pto } from '@merchx-v3/pto'
import { WebSocket } from '@merchx-v3/web-socket'
import { generateNanoId } from './conversation-service'
import { Conversation, ConversationId, ConversationMessage, ConversationMessageGroup, ConversationState, UserId } from './types'

// Локальное хранилище данных бесед. Тут мы храним всю актуальную информацию по входящим сообщениям
export interface IConversationStorage {
  currentUser?: Pto.Conversations.User
  users: Record<string, Pto.Conversations.User>
  conversations: Record<string, Conversation>

  getState(): ConversationState

  addConversation(conversation: Pto.Conversations.Conversation): void
  getConversation(conversationId: ConversationId): Conversation | undefined
  updateConversationHasOnlineUsers(conversationId: ConversationId): void

  addUser(user: Pto.Conversations.User): void
  addUsers(users: Pto.Conversations.User[]): void
  getUser(userId: UserId): Pto.Conversations.User | undefined
  setActiveUser(user?: Pto.Conversations.User): void
  updateUserPresence(userId: string, presence: Pto.Conversations.Presence): void

  addMessage<T extends Pto.Conversations.MessageContentType>(conversationId: ConversationId, message: Pto.Conversations.Message<T>): void
  addPreviousMessage<T extends Pto.Conversations.MessageContentType>(conversationId: ConversationId, message: Pto.Conversations.Message<T>): void
  setCurrentMessage(message: string): void
  updateUnreadMessages(conversationId: string, numberOfUnreadMessages: number): void
  setDraft(message: string): void

  resetState(): void

  removeTypingUser(conversationId: string, userId: string): boolean

  setActiveConversation(conversationId: ConversationId): void
}

export class ConversationStorage implements IConversationStorage {
  private _conversationGroups: Record<ConversationId, ConversationMessageGroup[]> = {}
  currentMessage: string = ''
  currentUser?: Pto.Conversations.User
  users: Record<string, Pto.Conversations.User> = {}
  conversations: Record<string, Conversation> = {}
  activeConversationId?: string

  constructor() {
    this.resetState()
  }

  setActiveConversation = (conversationId: string): void => {
    this.activeConversationId = conversationId

    if (this.conversations[conversationId]) {
      this.currentMessage = this.conversations[conversationId].draft
    }
  }

  getState = (): ConversationState => {
    const activeConversation = this.activeConversationId ? this.conversations[this.activeConversationId] : undefined
    const state: ConversationState = {
      currentMessage: this.currentMessage,
      activeConversation,
      currentUser: this.currentUser,
      conversations: Object.values(this.conversations).filter((conversation) => conversation.role !== Pto.Conversations.Role.Guest),
      currentMessages: this.activeConversationId ? this._conversationGroups[this.activeConversationId] || [] : [],
      totalUnreadMessages: Object.values(this.conversations)
        .filter((conversation) => conversation.role !== Pto.Conversations.Role.Guest)
        .reduce((accum, current) => accum + current.unreadMessages, 0),
      state: 'Idle'
    }

    return state
  }

  resetState(): void {
    this._conversationGroups = {}

    this.activeConversationId = undefined
    this.currentUser = undefined
    this.currentMessage = ''
    this.users = {
      [WebSocket.Settings.SystemSenderId]: {
        id: WebSocket.Settings.SystemSenderId,
        email: 'support@merchx.com',
        firstName: 'Merchx',
        lastName: 'system',
        avatarUrl: 'https://cdn.merchx.com/merchx-v3/mc-shirt-logo.jpg',
        presence: Pto.Conversations.Presence.Offline,
        username: 'system'
      }
    }
    this.conversations = {}
  }

  updateUnreadMessages(conversationId: string, numberOfUnreadMessages: number): void {
    const conversation = this.conversations[conversationId]
    if (conversation) {
      conversation.unreadMessages = numberOfUnreadMessages
    }
  }

  addMessage = <T extends Pto.Conversations.MessageContentType>(conversationId: ConversationId, message: Pto.Conversations.Message<T>) => {
    const conversationMessage: ConversationMessage = {
      id: message.id,
      type: message.type,
      senderId: message.senderId,
      content: message.content,
      createdAt: message.createdAt,
      updatedAt: message.updatedAt,
      direction: 'incoming' //message.senderId === this.currentUser?.id ? 'outgoing' : 'incoming'
    }

    const conversation = this.conversations[conversationId]

    const isMessageExist = conversation.messages.some((item) => item.id === conversationMessage.id)
    if (isMessageExist) return

    if (conversation) {
      conversation.messages.push(conversationMessage)
    }

    const conversationMessageGroup = this._conversationGroups[conversationId]
    if (!conversationMessageGroup) {
      this._conversationGroups[conversationId] = []
    }

    const messageGroups = this._conversationGroups[conversationId]

    const lastGroup = messageGroups[messageGroups.length - 1]
    if (lastGroup && lastGroup.senderId === message.senderId && message.type !== Pto.Conversations.MessageContentType.System) {
      lastGroup.messages.push(conversationMessage)
    } else {
      const newGroup: ConversationMessageGroup = {
        id: generateNanoId(),
        isSystemMessages: message.senderId === WebSocket.Settings.SystemSenderId,
        senderId: message.senderId,
        direction: conversationMessage.direction,
        messages: [conversationMessage]
      }

      messageGroups.push(newGroup)
    }
  }

  addPreviousMessage<T extends Pto.Conversations.MessageContentType>(conversationId: string, message: Pto.Conversations.Message<T>): void {
    const conversationMessage: ConversationMessage = {
      id: message.id,
      type: message.type,
      senderId: message.senderId,
      content: message.content,
      createdAt: message.createdAt,
      updatedAt: message.updatedAt,
      direction: message.senderId === this.currentUser?.id ? 'outgoing' : 'incoming'
    }

    const isMessageExist = this.conversations[conversationId]?.messages.some((item) => item.id === conversationMessage.id)
    if (isMessageExist) return

    if (this.conversations[conversationId]) {
      this.conversations[conversationId].messages = [conversationMessage, ...this.conversations[conversationId].messages]
    }

    const conversationMessageGroup = this._conversationGroups[conversationId]
    if (!conversationMessageGroup) {
      this._conversationGroups[conversationId] = []
    }

    const messageGroups = this._conversationGroups[conversationId]

    const firstGroup = messageGroups[0]
    if (firstGroup && firstGroup.senderId === message.senderId && message.type !== Pto.Conversations.MessageContentType.System) {
      firstGroup.messages = [conversationMessage, ...firstGroup.messages]
    } else {
      const newGroup: ConversationMessageGroup = {
        id: generateNanoId(),
        isSystemMessages: message.senderId === WebSocket.Settings.SystemSenderId,
        senderId: message.senderId,
        direction: conversationMessage.direction,
        messages: [conversationMessage]
      }

      this._conversationGroups[conversationId] = [newGroup, ...messageGroups]
    }
  }

  setCurrentMessage(message: string): void {
    this.currentMessage = message
  }

  setDraft(message: string): void {
    if (this.activeConversationId && this.conversations[this.activeConversationId]) {
      this.conversations[this.activeConversationId].draft = message
    }
  }

  addConversation = (conversation: Pto.Conversations.Conversation) => {
    if (!this.conversations[conversation.id]) {
      this.conversations[conversation.id] = {
        id: conversation.id,
        name: conversation.name,
        description: conversation.description,
        avatarUrl: conversation.avatarUrl,
        accessLevel: conversation.accessLevel,
        meta: conversation.meta,
        role: conversation.role,
        status: conversation.status,
        lastReadMessage: conversation.lastReadMessage,
        lastMessage: conversation.lastMessage,
        creationTime: new Date(conversation.creationTime),
        lastActivityAt: new Date(conversation.lastActivityAt),

        isLoaded: false,

        hasOnlineUsers: this.hasOnlineParticipant(conversation.participants),
        draft: '',
        hasPrevMessages: false,
        messages: [],
        hasNextMessages: false,
        unreadMessages: conversation.unreadMessages,
        participants: conversation.participants,
        typingUsers: []
      }
    } else {
      this.conversations[conversation.id].participants = conversation.participants
      this.conversations[conversation.id].role = conversation.role
    }
  }

  getConversation(conversationId: ConversationId): Conversation | undefined {
    return this.conversations[conversationId]
  }

  addUser(user: Pto.Conversations.User) {
    this.users[user.id] = user
  }

  addUsers(users: Pto.Conversations.User[]) {
    users.forEach((user) => this.addUser(user))
  }

  getUser(userId: UserId): Pto.Conversations.User | undefined {
    return this.users[userId]
  }

  setActiveUser(user?: Pto.Conversations.User | undefined): void {
    this.currentUser = user
  }

  updateUserPresence(userId: string, presence: Pto.Conversations.Presence): void {
    if (this.users[userId]) {
      this.users[userId].presence = presence
    }

    const conversationIds = Object.keys(this.conversations)

    conversationIds.forEach((conversationId) => this.updateConversationHasOnlineUsers(conversationId))
  }

  removeTypingUser(conversationId: string, userId: string): boolean {
    let isTypingUserRemoved = false

    const conversation = this.conversations[conversationId]
    if (conversation && conversation.typingUsers.includes(userId)) {
      conversation.typingUsers = conversation.typingUsers.filter((typingUserId) => typingUserId !== userId)
      isTypingUserRemoved = true
    }

    return isTypingUserRemoved
  }

  updateConversationHasOnlineUsers(conversationId: string): void {
    const conversation = this.conversations[conversationId]
    if (conversation) {
      conversation.hasOnlineUsers = this.hasOnlineParticipant(conversation.participants)
    }
  }

  private hasOnlineParticipant(participants: Pto.Conversations.Participant[]) {
    return participants
      .map((participant) => this.getUser(participant.userId))
      .filter((participant) => participant && participant.id !== this.currentUser?.id)
      .some((participant) => participant!.presence === Pto.Conversations.Presence.Online)
  }
}

export const conversationStorage = new ConversationStorage()
