import { Card, Spin } from 'antd'
import { Pto } from '@merchx-v3/pto'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Row } from 'components'
import { useDesignTemplateQuery } from 'app/api/design-templates-api'
import { DesignRequestArtworkCardItem } from './DesignRequestArtworkCardItem'
import styles from './DesignRequestArtworkCard.module.scss'

type Props = {
  designRequest: Pto.DesignRequests.DesignRequest
  product: Pto.DesignRequests.Product
  onArtworkUploaded: (artwork: Pto.DesignRequests.UpdateArtworkFiles) => Promise<boolean>
  onArtworkMockupUrlUploaded: (mockup: Pto.DesignRequests.UploadArtworkMockupUrl) => Promise<boolean>
}

export const DesignRequestArtworkCard = (props: Props) => {
  const { designRequest, product, onArtworkUploaded, onArtworkMockupUrlUploaded } = props

  const { data: designTemplate, isLoading } = useDesignTemplateQuery(designRequest.designTemplateId)

  if (isLoading) return <Spin />

  const isValid = product.artworks.reduce((accum, current) => {
    return accum && !!current.artworkUrls.length && !!current.mockupUrl
  }, true)

  const designTemplatePrintAreas = designTemplate?.printAreas || []

  const sortedPrintAreas = [...designRequest.printAreas].sort((a, b) => {
    const aPrintAreaIndex = designTemplatePrintAreas.findIndex((area) => area.artworkKey === a.printArea)
    const bPrintAreaIndex = designTemplatePrintAreas.findIndex((area) => area.artworkKey === b.printArea)

    return aPrintAreaIndex > bPrintAreaIndex ? 1 : aPrintAreaIndex < bPrintAreaIndex ? -1 : 0
  })

  return (
    <Card
      className={styles.artworkCard}
      classNames={{ header: styles.cardHeader, title: styles.cardTitle }}
      title={`${product.productName} - ${product.productVariant}`}
      extra={isValid ? <CheckOutlined style={{ color: '#52c41a', fontSize: 24 }} /> : <CloseOutlined style={{ color: '#ff0000', fontSize: 24 }} />}
    >
      {sortedPrintAreas.map((printArea) => {
        const filteredArtworksData = product.artworks.filter((item) => item.printArea === printArea.printArea)

        return (
          <Row key={printArea.printArea} className={styles.row}>
            {filteredArtworksData.map((artwork) => (
              <DesignRequestArtworkCardItem
                key={artwork.printArea}
                designRequest={designRequest}
                artwork={artwork}
                onArtworkUploaded={onArtworkUploaded}
                onArtworkMockupUrlUploaded={onArtworkMockupUrlUploaded}
              />
            ))}
          </Row>
        )
      })}
    </Card>
  )
}
