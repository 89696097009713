import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { toast } from 'react-toastify'
import { Pto } from '@merchx-v3/pto'
import { InputNumber } from 'antd'
import { useDebounce } from 'helpers'
import { FieldComponentProps } from '../types'
import ErrorContainer from '../ErrorContainer'

type RefProps = {
  validate: () => boolean
}

const IntegerField = forwardRef<RefProps, FieldComponentProps<Pto.Plugins.ValueType.Integer>>(({ field, onChange, targetId, ...props }, forwardedRef) => {
  const [isShowError, setIsShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [value, setValue] = useState<number>(field.value)
  const debouncedValue = useDebounce(value, 300)

  useImperativeHandle(forwardedRef, () => ({
    validate: () => {
      if (field.value === undefined && field.isRequired) {
        toast.error(`Integer field ${field.displayName} for ${targetId} should be defined`)
        setIsShowError(true)
        setErrorMessage(`Integer field ${field.displayName} for ${targetId} should be defined`)

        return false
      }

      return true
    }
  }))

  useEffect(() => {
    onChange(debouncedValue)
    setErrorMessage('')
    setIsShowError(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  return (
    <ErrorContainer isShowError={isShowError} errorMessage={errorMessage}>
      <InputNumber
        status={isShowError ? 'error' : undefined}
        value={value}
        disabled={props.disabled}
        onChange={(newValue) => (newValue !== null ? setValue(newValue) : null)}
        step={1}
        placeholder={field.displayName}
      />
    </ErrorContainer>
  )
})

export default IntegerField
