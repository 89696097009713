import React, { useEffect, useRef, useState } from 'react'
import { sha1 } from 'object-hash'
import { PlusOutlined } from '@ant-design/icons'
import { AutoComplete } from 'antd'
import { Space, Tag, Tooltip } from 'antd'
import { BaseSelectRef } from 'rc-select'
import { useDebounce } from 'helpers'
import { useTagOptionsQuery } from 'app/api/fulfillment-tasks-api'
import styles from './ManageFulfillmentTags.module.scss'

type PropsType = {
  value?: string[]
  disabled?: boolean

  style?: React.CSSProperties
  onChange?: (value: string[]) => void
}

const ManageFulfillmentTags = (props: PropsType) => {
  const { disabled, value = [], onChange } = props

  const [tags, setTags] = useState(value)
  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [editInputIndex, setEditInputIndex] = useState(-1)
  const [editInputValue, setEditInputValue] = useState('')
  const inputRef = useRef<BaseSelectRef>(null)
  const editInputRef = useRef<BaseSelectRef>(null)

  const debouncedSearchText = useDebounce(inputValue, 500)

  const { data: tagOptions } = useTagOptionsQuery({ searchText: debouncedSearchText, size: 20 })

  useEffect(() => {
    setTags(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sha1(value)])

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus()
    }
  }, [inputVisible])

  useEffect(() => {
    editInputRef.current?.focus()
  }, [inputValue])

  const addTag = (tag: string) => {
    const newTags = [...tags, tag]
    setTags(newTags)
    onChange && onChange(newTags)
  }
  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag)
    setTags(newTags)
    onChange && onChange(newTags)
  }
  const editTag = (tag: string, index: number) => {
    const newTags = [...tags]

    if (!tag) {
      delete newTags[index]
    } else {
      newTags[index] = tag
    }
    setTags(newTags)
    onChange && onChange(newTags)
  }

  const showInput = () => {
    setInputVisible(true)
  }

  const handleInputChange = (value: string) => {
    setInputValue(value)
  }
  const handleEditInputChange = (value: string) => {
    setEditInputValue(value)
  }

  const handleInputConfirm: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key !== 'Enter') return

    if (inputValue && tags.indexOf(inputValue) === -1) {
      addTag(inputValue)
    }
    setInputVisible(false)
    setInputValue('')
  }
  const handleEditInputConfirm: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key !== 'Enter') return

    editTag(editInputValue, editInputIndex)
    setEditInputIndex(-1)
    setEditInputValue('')
  }

  const handleInputBlur = () => {
    setInputVisible(false)
    setInputValue('')
  }

  const handleInputSelected = (value: string) => {
    if (tags.indexOf(value) === -1) {
      addTag(value)
    }
    setInputVisible(false)
    setInputValue('')
  }
  const handleEditInputSelected = (value: string) => {
    editTag(value, editInputIndex)
    setEditInputIndex(-1)
    setEditInputValue('')
  }

  const tagInputStyle: React.CSSProperties = {
    width: 180,
    verticalAlign: 'top',
    height: 22
  }

  const tagPlusStyle: React.CSSProperties = {
    borderStyle: 'dashed'
  }

  return (
    <Space size={[0, 8]} wrap className={styles.container} style={props.style}>
      <Space size={[0, 8]} wrap>
        {tags.map((tag, index) => {
          if (editInputIndex === index) {
            return (
              <AutoComplete
                key={tag}
                ref={editInputRef}
                size="small"
                options={tagOptions}
                style={tagInputStyle}
                value={editInputValue}
                disabled={disabled}
                onSelect={handleEditInputSelected}
                onKeyUp={handleEditInputConfirm}
                onBlur={handleInputBlur}
                onSearch={handleEditInputChange}
              />
            )
          }
          const isLongTag = tag.length > 20
          const tagElem = (
            <Tag key={tag} closable style={{ userSelect: 'none' }} onClose={() => handleClose(tag)}>
              <span
                onDoubleClick={(e) => {
                  setEditInputIndex(index)
                  setEditInputValue(tag)
                  e.preventDefault()
                }}
              >
                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
              </span>
            </Tag>
          )
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          )
        })}
      </Space>
      {inputVisible ? (
        <AutoComplete
          ref={inputRef}
          size="small"
          options={tagOptions}
          style={tagInputStyle}
          value={inputValue}
          disabled={disabled}
          onSelect={handleInputSelected}
          onKeyUp={handleInputConfirm}
          onSearch={handleInputChange}
          onBlur={handleInputBlur}
        />
      ) : (
        <Tag style={tagPlusStyle} onClick={showInput}>
          <PlusOutlined /> New Tag
        </Tag>
      )}
    </Space>
  )
}

export default ManageFulfillmentTags
