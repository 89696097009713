import { useEffect, useState } from 'react'

import Login from './Login'
import Register from './Register'
import { useLazyIsInviteLinkUsedQuery } from 'app/api/users-api'
import { useUser } from 'app/auth'
import { Navigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Spin } from 'antd'

const GuestLogin = () => {
  const [user] = useUser()

  const { inviteLink } = useParams<{ inviteLink: string }>()
  const [isDataReceived, setIsDataReceived] = useState(false)
  const [email, setEmail] = useState<string>()
  const [firstName, setFirstName] = useState<string>()
  const [lastName, setLastName] = useState<string>()
  const [conversationId, setConversationId] = useState<string>('')
  const [isUserRegistered, setIsUserRegistered] = useState(false)

  const [isInviteLinkUsed] = useLazyIsInviteLinkUsedQuery()

  useEffect(() => {
    if (inviteLink) {
      setIsDataReceived(false)
      isInviteLinkUsed(inviteLink)
        .unwrap()
        .then((inviteLinkData) => {
          console.log('Is link used', inviteLinkData)
          setIsUserRegistered(!!inviteLinkData.userId)
          setEmail(inviteLinkData.email)
          setFirstName(inviteLinkData.firstName)
          setLastName(inviteLinkData.lastName)
          setConversationId(inviteLinkData.conversationId)
          setIsDataReceived(true)
        })
        .catch((err) => {
          toast.error(err)
          setIsDataReceived(true)
        })
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteLink])

  if (!inviteLink) return <Navigate to="/login" />

  if (!user && !isDataReceived) return <Spin />

  if (isUserRegistered) return <Login conversationId={conversationId} />

  return <Register inviteLink={inviteLink} conversationId={conversationId} email={email} firstName={firstName} lastName={lastName} />
}

export default GuestLogin
