import React from 'react'
import querystringify from 'querystringify'
import { Navigate, useLocation } from 'react-router-dom'
import { Role } from '@merchx-v3/pto'

import { useIsLoggedIn } from 'app/auth'
import AppRoutes from '../Router/AppRoutes'

type Props = {
  userRoles?: Role[]
  roles?: Role[]
}

const PrivateRoute = ({ children, userRoles = [], roles = [] }: Props & React.PropsWithChildren) => {
  const location = useLocation()

  const [isLoggedIn] = useIsLoggedIn()
  const canAccess = userRoles.includes(Role.SystemAdmin) || !roles.length || userRoles.filter((x) => roles.includes(x)).length !== 0

  if (isLoggedIn) {
    return canAccess ? <>{children}</> : <Navigate to="/" />
  }

  if (!location.pathname && !location.search) {
    return <Navigate to={`/${AppRoutes.login}`} />
  } else {
    const callbackUrl = querystringify.stringify({ callbackUrl: location.pathname + location.search })
    return <Navigate to={`/${AppRoutes.login}?${callbackUrl}`} />
  }
}

export default PrivateRoute
