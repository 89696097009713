import React, { useState, Suspense, useEffect, useContext } from 'react'
import { Layout as AntdLayout, Spin } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { useUser } from 'app/auth'
import SiderMenuSuppliers from '../SiderMenuSuppliers'
import { AddSupplierCenterModal } from './AddSupplierCenterModal'
import { useSupplierCentersBySupplierQuery } from 'app/api/suppliers-api'

import styles from './Layout.module.scss'
import { PortalContext } from 'components/App/App'

const supplierId = '28840339-660d-4a0b-b804-cf8f99e94d90'

export const SupplierCenterContext = React.createContext<string | undefined>(undefined)

type Props = {
  shouldWaitUser?: boolean
}

const Layout: React.FC<React.PropsWithChildren<Props>> = ({ children, shouldWaitUser = false }) => {
  const [collapsedMainMenu, setCollapsedMainMenu] = useState(false)
  const [supplierCenterId, setSupplierCenterId] = useState<string>()
  const [user] = useUser()
  const [isAddSupplierCenterModalVisible, setIsAddSupplierCenterModalVisible] = useState(false)
  const { setPortal } = useContext(PortalContext)

  const { data: supplierCenters = [] } = useSupplierCentersBySupplierQuery(supplierId)

  useEffect(() => {
    setPortal('/suppliers')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (supplierCenters.length && !supplierCenterId) {
      setSupplierCenterId(supplierCenters[0].id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierCenters])

  return (
    <SupplierCenterContext.Provider value={supplierCenterId}>
      <AntdLayout hasSider className={styles.layout}>
        <div className={styles.extraMenu}>
          {supplierCenters.map((supplierCenter) => (
            <div
              key={supplierCenter.id}
              className={supplierCenter.id === supplierCenterId ? styles.extraMenuItemActive : styles.extraMenuItem}
              onClick={() => setSupplierCenterId((currentSupplierCenterId) => (supplierCenter.id === currentSupplierCenterId ? undefined : supplierCenter.id))}
            >
              {supplierCenter.name}
            </div>
          ))}
          <div className={styles.extraMenuItemAddNew} onClick={() => setIsAddSupplierCenterModalVisible(true)}>
            <PlusOutlined />
          </div>
          <AddSupplierCenterModal supplierId={supplierId} isOpened={isAddSupplierCenterModalVisible} onClose={() => setIsAddSupplierCenterModalVisible(false)} />
        </div>

        <AntdLayout.Sider collapsible={true} collapsed={collapsedMainMenu} onCollapse={setCollapsedMainMenu} width={242}>
          <SiderMenuSuppliers collapsed={collapsedMainMenu} selectedSupplierCenter={supplierCenters.find((item) => supplierCenterId === item.id)} />
        </AntdLayout.Sider>
        <AntdLayout>
          <AntdLayout.Content className={styles.content}>
            <Suspense
              fallback={
                <div style={{ width: '100%', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Spin size="large" />
                </div>
              }
            >
              {shouldWaitUser && !user ? (
                <div style={{ width: '100%', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Spin size="large" />
                </div>
              ) : (
                children
              )}{' '}
            </Suspense>
          </AntdLayout.Content>
        </AntdLayout>
      </AntdLayout>
    </SupplierCenterContext.Provider>
  )
}

export default Layout
