enum AppRoutes {
  // CUSTOMER ROUTES

  orders = '/',
  conversations = 'conversations',
  invoices = 'invoices',
  inviteLink = 'invite/:inviteLink',
  profile = 'profile'
}

export default AppRoutes
