import clsx from 'clsx'
import styles from './Column.module.scss'

type Props = {
  className?: string
  hidden?: boolean
}

export const Column = (props: React.PropsWithChildren<Props>) => {
  return (
    <div hidden={props.hidden} className={clsx([styles.column, props.className])}>
      {props.children}
    </div>
  )
}

export default Column
