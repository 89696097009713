import dayjs from 'dayjs'
import { useEffect } from 'react'
import { Pto } from '@merchx-v3/pto'
import { PresentationAPI } from '@merchx-v3/shared-functions'
import { Message, MessageGroup } from '@chatscope/chat-ui-kit-react'

import { useIsVisible } from 'helpers'
import { ImageMessage } from './ImageMessage'
import { SystemMessage } from './SystemMessage'
import { GalleryMessage } from './GalleryMessage'
import { getMessageAvatar, getMessagePosition, MessagePosition } from './helpers'
import { ConversationMessage as ConversationMessageType, ConversationMessageGroup as ConversationMessageGroupType } from 'app/chat/types'

type Props = {
  message: ConversationMessageType<Pto.Conversations.MessageContentType>
  position: MessagePosition
  user?: Pto.Conversations.User

  onBecameVisible: (message: ConversationMessageType<Pto.Conversations.MessageContentType>) => void
}

type GroupProps = {
  group: ConversationMessageGroupType
  user?: Pto.Conversations.User

  onBecameVisible: (message: ConversationMessageType<Pto.Conversations.MessageContentType>) => void
}

export const ConversationMessage = (props: Props) => {
  const { message, position, onBecameVisible } = props

  const isVisible = useIsVisible(message.id)

  useEffect(() => {
    isVisible && onBecameVisible(message)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  switch (message.type) {
    case Pto.Conversations.MessageContentType.Image: {
      const typedMessage = message as ConversationMessageType<Pto.Conversations.MessageContentType.Image>
      return <ImageMessage message={typedMessage} position={position} />
    }

    case Pto.Conversations.MessageContentType.Gallery: {
      const typedMessage = message as ConversationMessageType<Pto.Conversations.MessageContentType.Gallery>
      return <GalleryMessage message={typedMessage} position={position} />
    }

    case Pto.Conversations.MessageContentType.System: {
      const typedMessage = message as ConversationMessageType<Pto.Conversations.MessageContentType.System>

      return <SystemMessage message={typedMessage} />
    }

    case Pto.Conversations.MessageContentType.Html: {
      const typedMessage = message as ConversationMessageType<Pto.Conversations.MessageContentType.Html>

      return <Message id={message.id} model={{ type: 'html', message: typedMessage.content, direction: message.direction, position }} avatarSpacer={true} />
    }

    case Pto.Conversations.MessageContentType.Text:
    default: {
      const typedMessage = message as ConversationMessageType<Pto.Conversations.MessageContentType.Text>

      return <Message id={message.id} model={{ type: 'text', message: typedMessage.content, direction: message.direction, position }} avatarSpacer={true} />
    }
  }
}

export const ConversationMessageGroup = ({ group, user, onBecameVisible }: GroupProps): JSX.Element => {
  if (group.isSystemMessages) {
    return (
      <>
        {group.messages.map((m, index) => (
          <ConversationMessage key={m.id} message={m} position={getMessagePosition(index, group.messages.length)} user={user} onBecameVisible={onBecameVisible} />
        ))}
      </>
    )
  }

  const time = group.messages[group.messages.length - 1].createdAt

  return (
    <MessageGroup key={group.id} direction={group.direction} avatarPosition="tl">
      {
        <MessageGroup.Header>
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            {getMessageAvatar({ isVisible: true, user })}
            <span style={{ color: 'rgba(0, 0, 0, 0.88)', fontWeight: 'bold' }}>{PresentationAPI.getChatUserShortPreview(user)} </span>
            {dayjs(time).format('MM/DD/YYYY HH:mm:ss')}
          </div>
        </MessageGroup.Header>
      }
      <MessageGroup.Messages>
        {group.messages.map((m, index) => (
          <ConversationMessage key={m.id} message={m} position={getMessagePosition(index, group.messages.length)} user={user} onBecameVisible={onBecameVisible} />
        ))}
      </MessageGroup.Messages>
    </MessageGroup>
  )
}
