import { useState } from 'react'
import { AutoComplete } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { PresentationAPI } from '@merchx-v3/shared-functions'
import { Pto } from '@merchx-v3/pto'
import useDebounce from 'helpers/useDebounce'
import { useUserOptionsQuery } from 'app/api/users-api'

type Props = {
  role?: Pto.Auth.Role
  defaultValue?: string
  placeholder?: string
  disabled?: boolean
  className?: string
  value?: Pto.Users.User
  size?: SizeType

  onChange?: (value?: Pto.Users.User) => void
}

const UserOptions = (props: Props) => {
  const { placeholder, disabled, className, value, size, role, onChange } = props

  const [searchText, setSearchText] = useState<string>('')
  const debouncedSearchText = useDebounce(searchText, 500)

  const { data: userOptions = [] } = useUserOptionsQuery({ searchText: debouncedSearchText, userRole: role })

  const handleSelect = (_value: string, option: Pto.Users.User) => {
    onChange && onChange(option)
  }

  const handleSearch = (value: string) => {
    setSearchText(value)
  }

  const handleClear = () => {
    onChange && onChange(undefined)
    setSearchText('')
  }

  return (
    <AutoComplete
      options={userOptions?.map((user) => ({ ...user, value: user.id, label: PresentationAPI.getUserPreview(user) }))}
      className={className}
      value={value?.email}
      searchValue={searchText}
      disabled={disabled}
      onSelect={handleSelect}
      onSearch={handleSearch}
      placeholder={placeholder}
      size={size}
      allowClear
      onClear={handleClear}
    />
  )
}

export default UserOptions
