enum AppRoutes {
  // SUPPLIERS ROUTES

  main = '/',
  filfillment = 'fulfillment',
  shipping = 'shipping',
  settings = 'settings',
  supplierProducts = 'supplier-products',
  designTemplates = 'design-templates',
  processingTemplates = 'processing-templates',
  profile = 'profile'
}

export default AppRoutes
