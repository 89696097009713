import { useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Empty, Modal, Space, Spin, Typography, Upload, UploadFile } from 'antd'
import type { RcFile } from 'antd/es/upload'
import { UploadOutlined } from '@ant-design/icons'
import { Pto } from '@merchx-v3/pto'
import styles from './ArtworkUpload.module.scss'

type Props = {
  targetId?: string
  field: Pto.Plugins.Fields.IField<Pto.Plugins.ValueType.Artworks>
  side: Pto.Images.PrintSide
  value?: string
  disabled?: boolean

  onChange?: (newValue: string) => void
}

const ArtworkUpload = (props: Props) => {
  const { targetId, field, side, value, disabled, onChange } = props
  const isUploaded = !!value

  const [isUploadOpened, setIsUploadOpened] = useState(false)
  const [previewImage, setPreviewImage] = useState(value ? `/images/${value}?width=800&height=800&quality=100` : undefined)
  const [fileToUpload, setFileToUpload] = useState<UploadFile<RcFile> | undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loaded, setLoaded] = useState(false)

  // const [uploadPluginArtwork] = useUploadPluginArtworkMutation()

  const beforeUpload = (file: RcFile) => {
    const isImage = ['image/jpeg', 'image/png'].includes(file.type)
    if (!isImage) {
      toast.error('You can upload only JPG/PNG files')
    }
    const isLt2M = file.size / 1024 / 1024 < 50
    if (!isLt2M) {
      toast.error('File size exceed. Limit is 50Mb')
    }
    return isImage || Upload.LIST_IGNORE
  }

  const handleUpload = async (info: any) => {
    const fileName = info.file.name.trim().replace(/\s+/g, '_')

    const uploadedFile = info.file as any

    const tempFileUrl = URL.createObjectURL(info.file)

    const newFileToUpload = {
      uid: uploadedFile.uid,
      name: fileName,
      status: 'done',
      size: uploadedFile.size,
      type: uploadedFile.type,
      originFileObj: info.file,
      url: tempFileUrl
    } as UploadFile<RcFile>

    setPreviewImage(tempFileUrl)
    setFileToUpload(newFileToUpload)
  }

  const handleRemove = (file: UploadFile<any>) => {
    setFileToUpload(undefined)
  }

  const handleOk = async () => {
    setIsLoading(true)
    if (targetId && fileToUpload) {
      toast.error('You need to move artwork file upload to new Files microservice, it uses unsupported code. See plugins-api history')
      // await uploadPluginArtwork({
      //   file: fileToUpload,
      //   uploadArtwork: {
      //     printSide: side,
      //     targetId: targetId,
      //     pluginFieldId: field.id
      //   }
      // })
      //   .unwrap()
      //   .then((uploadedFileUrl) => {
      //     if (uploadedFileUrl) {
      //       toast.success('File uploaded sucessfully')
      //       onChange && onChange(uploadedFileUrl)
      //       setIsUploadOpened(false)
      //     }
      //   })
      //   .catch((error) => toast.error(error))
    }
    setIsLoading(false)
  }

  return (
    <Space>
      <Typography>{side} side:</Typography>
      <Typography style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={disabled ? () => {} : () => setIsUploadOpened(true)}>
        {isUploaded ? 'Uploaded' : 'Click to upload'}
      </Typography>
      <Modal
        open={isUploadOpened}
        onCancel={() => setIsUploadOpened(false)}
        cancelButtonProps={{ loading: disabled }}
        okButtonProps={{ loading: disabled }}
        footer={[
          <Button disabled={isLoading || disabled} onClick={() => setIsUploadOpened(false)}>
            Cancel
          </Button>,
          <Button loading={isLoading || disabled} type="primary" onClick={handleOk}>
            Ok
          </Button>
        ]}
      >
        <div className={styles.modalContainer}>
          {previewImage && !loaded && <Spin className={styles.spinner} />}
          {previewImage && <img alt="example" style={{ width: '100%' }} src={previewImage} onLoad={() => setLoaded(true)} />}

          <Upload
            listType="picture"
            fileList={fileToUpload ? [fileToUpload] : []}
            beforeUpload={beforeUpload}
            customRequest={handleUpload}
            onRemove={handleRemove}
            className={styles.upload}
            disabled={disabled}
          >
            {previewImage && (
              <Button disabled={isLoading || disabled} icon={<UploadOutlined />}>
                Click to Upload
              </Button>
            )}
            {!previewImage && (
              <Empty
                description={
                  <span>
                    <a>Click to upload {side} artwork</a>
                  </span>
                }
              />
            )}
          </Upload>
        </div>
      </Modal>
    </Space>
  )
}

export default ArtworkUpload
