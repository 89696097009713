import { lazy } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import NotFoundPage from 'components/NotFoundPage'
import AnonymousRoute from '../AnonymousRoute'
import AppRoutes from './AppRoutes'
import PrivateRoute from 'components/PrivateRoute'
import IndexPage from 'components/IndexPage'
import { SetNewPassword, ResetPassword } from 'interfaces/sharedPages/ResetPassword'

const Login = lazy(() => import('interfaces/admin/features/user/components/Login'))
// const DesignConfirmation = lazy(() => import('interfaces/designers/features/designs/pages/DesignConfirmation'))
const PayInvoice = lazy(() => import('interfaces/admin/features/invoices/pages/PayInvoice'))

// MAIN ROUTER

const Router = () => {
  return (
    <Routes>
      <Route
        path={AppRoutes.login}
        element={
          <AnonymousRoute>
            <Login />
          </AnonymousRoute>
        }
      />

      <Route
        path={AppRoutes.setNewPassword}
        element={
          <AnonymousRoute>
            <SetNewPassword />
          </AnonymousRoute>
        }
      />

      <Route
        path={AppRoutes.resetPassword}
        element={
          <AnonymousRoute>
            <ResetPassword />
          </AnonymousRoute>
        }
      />

      {/* <Route path={AppRoutes.designConfirmaion} element={<DesignConfirmation />} /> */}
      <Route path={AppRoutes.payInvoice} element={<PayInvoice />} />
      <Route
        index
        element={
          <PrivateRoute>
            <IndexPage />
          </PrivateRoute>
        }
      />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default Router
