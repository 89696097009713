import { toast } from 'react-toastify'
import { Form, Modal, Typography } from 'antd'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import { Pto } from '@merchx-v3/pto'
import { UserOptions } from 'interfaces/admin/features/user/components'
import { useAddProjectManagerMutation } from 'app/api/projects-api'
import styles from './AddManagerModal.module.scss'

type Props = {
  projectId: string
  isModalVisible: boolean
  onClose: () => void
  onFinish?: () => void
}

type FormData = {
  user: Pto.Users.User
}

const AddManagerModal = ({ projectId, isModalVisible, onClose, onFinish }: Props) => {
  const [form] = Form.useForm<FormData>()

  const [addProjectManager, { isLoading }] = useAddProjectManagerMutation()

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    toast.error(errorInfo?.errorFields?.map((errField) => errField.errors.join('\n')).join('\n'))
  }

  const onFinishHandler = (values: FormData) => {
    addProjectManager({
      projectId,
      manager: { email: values.user.email, firstName: values.user.firstName, lastName: values.user.lastName }
    })
      .unwrap()
      .then(() => {
        toast.success('User assigned to project successfully')
        form.resetFields()
        onFinish && onFinish()
        onClose()
      })
      .catch((error) => toast.error(error))
  }

  return (
    <Modal
      forceRender
      open={isModalVisible}
      closable={!isLoading}
      onCancel={onClose}
      okText="Save"
      onOk={form.submit}
      destroyOnClose
      okButtonProps={{ loading: isLoading }}
      cancelButtonProps={{ loading: isLoading }}
    >
      <Typography.Title level={5}>Add Manager</Typography.Title>
      <Form
        className={styles.form}
        disabled={isLoading}
        layout="vertical"
        form={form}
        style={{ width: '100%' }}
        initialValues={{}}
        onFinish={onFinishHandler}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item label="User" name="user" rules={[{ required: true, message: 'Please select user!' }]}>
          <UserOptions role={Pto.Auth.Role.User} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddManagerModal
