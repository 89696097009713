import { forwardRef, useImperativeHandle, useState } from 'react'
import { toast } from 'react-toastify'
import { Select } from 'antd'
import { Pto } from '@merchx-v3/pto'
import { FieldComponentProps } from '../types'
import ErrorContainer from '../ErrorContainer'

type RefProps = {
  validate: () => boolean
}

const SelectField = forwardRef<RefProps, FieldComponentProps<Pto.Plugins.ValueType.Select>>(({ field, onChange, targetId, ...props }, forwardedRef) => {
  const [isShowError, setIsShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useImperativeHandle(forwardedRef, () => ({
    validate: () => {
      if (!field.isRequired) return true

      if (!field.value) {
        toast.error(`Select field ${field.displayName} for ${targetId} should be defined`)
        setErrorMessage(`Select field ${field.displayName} for ${targetId} should be defined`)
        setIsShowError(true)
        return false
      }

      if (!field.value?.items.length) {
        toast.error(`Select field ${field.displayName} items for ${targetId} is empty`)
        setErrorMessage(`Select field ${field.displayName} items for ${targetId} is empty`)
        setIsShowError(true)
        return false
      }

      if (!field.value?.value) {
        toast.error(`Select field ${field.displayName} value for ${targetId} should be selected`)
        setErrorMessage(`Select field ${field.displayName} value for ${targetId} should be selected`)
        setIsShowError(true)
        return false
      }

      return true
    }
  }))

  const handleRecordChanged = (record: string) => {
    const selectedOption = field.value.items.find((option) => option === record)
    if (selectedOption) {
      onChange({
        items: field.value.items,
        value: selectedOption
      })
      setErrorMessage('')
      setIsShowError(false)
    }
  }

  return (
    <ErrorContainer isShowError={isShowError} errorMessage={errorMessage}>
      <Select
        status={isShowError ? 'error' : undefined}
        value={field.value.value}
        {...props}
        onChange={handleRecordChanged}
        options={field.value.items.map((option) => ({ value: option, label: option }))}
        placeholder={field.displayName}
      />
    </ErrorContainer>
  )
})

export default SelectField
