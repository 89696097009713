import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { WebSocket } from '@merchx-v3/web-socket'
import { Pto } from '@merchx-v3/pto'
import { settings } from 'config/settings'

import { tokenProvider } from 'app/auth/token-provider'
import { store } from 'app/store'
import { shipmentsApi } from './shipments-api'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

type SetProjectShippingAddressArgs = {
  projectId: string
  address: Pto.Addresses.ShippingAddress
}

type CreateProjectNoteArgs = {
  projectId: string
  note: {
    id: string
    note: string
  }
}

type AddProjectManagerArgs = {
  projectId: string
  manager: Pto.Projects.ProjectManager
}

type RemoveProjectManagerArgs = {
  projectId: string
  managerEmail: string
}

type DeleteProjectNoteArgs = {
  projectId: string
  noteId: string
}

export const projectsApiV2 = createApi({
  reducerPath: 'projectsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['Projects', 'DesignRequests', 'ProjectProductItemAttachments', 'FulfillmentTasks', 'Invoices'],

  endpoints: (builder) => ({
    projectList: builder.query<Pto.Projects.List, Pto.Projects.ProjectListQuery>({
      query: (params) => ({
        url: 'projects',
        params
      }),
      providesTags: (projectListData, _error, _args) =>
        getProvidedTags(
          'Projects',
          'LIST',
          projectListData?.items.map((project) => project.id)
        )
    }),
    projectListByCustomer: builder.query<Pto.Projects.List, Pto.Projects.ProjectListByCustomerQuery>({
      query: (params) => ({
        url: 'projects/by-customer',
        params
      }),
      providesTags: (projectListByCustomerData, _error, _args) =>
        getProvidedTags(
          'Projects',
          'LIST',
          projectListByCustomerData?.items.map((project) => project.id)
        )
    }),
    project: builder.query<Pto.Projects.Project, string>({
      query: (projectId) => ({
        url: `projects/${projectId}`
      }),
      providesTags: (project, _error, projectId) => [
        ...getProvidedTags('Projects', projectId),
        ...getProvidedTags('DesignRequests', undefined, project?.productItems.map((item) => item.designRequestId).filter(Boolean) as string[])
      ]
    }),
    projectProductItemAttachments: builder.query<Pto.Files.Attachment[], string>({
      query: (projectProductItemId) => ({
        url: `projects/project-item/${projectProductItemId}/attachments`
      }),
      providesTags: (_result, _error, _args) => getProvidedTags('ProjectProductItemAttachments', 'LIST')
    }),
    createProject: builder.mutation<void, Pto.Projects.GeneralInfo>({
      query: (body) => ({
        url: 'projects/create',
        body,
        method: 'POST',
        responseHandler: 'text'
      })
    }),

    updateProject: builder.mutation<void, Pto.Projects.CreateUpdateProject>({
      query: (body) => ({
        url: 'projects/update',
        body,
        method: 'POST'
      })
    }),
    setProjectShippingAddress: builder.mutation<void, SetProjectShippingAddressArgs>({
      query: ({ projectId, address }) => ({
        url: `projects/${projectId}/shipping-address`,
        body: address,
        method: 'POST'
      })
    }),
    createProjectNote: builder.mutation<void, CreateProjectNoteArgs>({
      query: ({ projectId, note }) => ({
        url: `projects/note`,
        body: { projectId, note },
        method: 'POST'
      })
    }),
    updateProjectTags: builder.mutation<void, Pto.Projects.UpdateProjectTags>({
      query: (body) => ({
        url: `projects/tags`,
        body,
        method: 'POST'
      })
    }),
    deleteProjectNote: builder.mutation<void, DeleteProjectNoteArgs>({
      query: ({ projectId, noteId }) => ({
        url: `projects/${projectId}/note/${noteId}`,
        body: { projectId, noteId },
        method: 'delete'
      })
    }),
    invoiceList: builder.query<Pto.Invoices.List, string>({
      query: (projectId) => ({
        url: `/invoices/list-by-project/${projectId}`
      }),
      providesTags: (invoiceListData, _error, _args) =>
        getProvidedTags(
          'Invoices',
          'LIST',
          invoiceListData?.items.map((invoice) => invoice.id)
        )
    }),
    createInvoice: builder.mutation<void, Pto.Invoices.CreateInvoice>({
      query: (body) => ({
        url: '/invoices/create',
        body,
        method: 'POST'
      }),
      invalidatesTags: (_result, _, args) => [
        { type: 'Invoices', id: 'LIST' },
        { type: 'Projects', id: args.projectId }
      ]
    }),
    invoice: builder.query<Pto.Invoices.Invoice, string>({
      query: (invoiceId) => ({
        url: `/invoices/${invoiceId}`
      }),
      providesTags: (_result, _error, invoiceId) => getProvidedTags('Invoices', invoiceId)
    }),
    invoicePaid: builder.mutation<void, { invoiceId: string; checkoutSessionId: string }>({
      query: ({ invoiceId, checkoutSessionId }) => ({
        url: `/payments/invoices/${invoiceId}/${checkoutSessionId}/paid`,
        method: 'POST'
      })
    }),
    invoiceChangeStatus: builder.mutation<void, Pto.Invoices.ChangeStatus>({
      query: (body) => ({
        url: `/invoices/change-status`,
        method: 'POST',
        body
      })
    }),
    getInvoiceOptions: builder.query<Pto.Invoices.Option[], string>({
      query: (projectId) => ({
        url: '/invoices/options',
        params: { projectId }
      }),
      providesTags: (invoiceOptionsData, _error, _args) =>
        getProvidedTags(
          'Invoices',
          'OPTIONS',
          invoiceOptionsData?.map((item) => item.id)
        )
    }),
    sendInvoiceEmail: builder.mutation<void, Pto.Notifications.SendInvoiceEmail>({
      query: (body) => ({
        url: '/notifications/send-invoice',
        body,
        method: 'POST'
      })
    }),
    updateProjectServiceItem: builder.mutation<void, Pto.Projects.UpdateProjectServiceItems>({
      query: (body) => ({
        url: '/projects/services',
        body,
        method: 'POST'
      })
    }),
    updateProjectProductItems: builder.mutation<Pto.Sagas.SagaWithSteps, Pto.Projects.UpdateProjectProductItems>({
      query: (updateProjectProductItems) => {
        return {
          url: '/sagas/projects/update-product-items',
          body: updateProjectProductItems,
          method: 'POST'
        }
      }
    }),
    updateDeliveryDate: builder.mutation<void, Pto.Projects.UpdateProjectDeliveryDate>({
      query: (body) => ({
        url: '/projects/delivery-date',
        body,
        method: 'POST'
      })
    }),
    updateSaleSource: builder.mutation<void, Pto.Projects.UpdateProjectSaleSource>({
      query: (body) => ({
        url: '/projects/sale-source',
        body,
        method: 'PATCH'
      })
    }),
    manualUpdateProjectStatus: builder.mutation<void, Pto.Projects.ManualUpdateProjectStatus>({
      query: (body) => ({
        url: '/projects/manual-update-status',
        body,
        method: 'POST'
      })
    }),
    addProjectManager: builder.mutation<void, AddProjectManagerArgs>({
      query: (body) => ({
        url: '/projects/managers',
        body,
        method: 'put'
      })
    }),
    removeProjectManager: builder.mutation<void, RemoveProjectManagerArgs>({
      query: (body) => ({
        url: `/projects/${body.projectId}/managers/${body.managerEmail}`,
        method: 'delete'
      })
    }),
    projectStatusHistoryList: builder.query<Pto.Projects.StatusHistoryList, Pto.Projects.ProjectStatusHistoryListQuery>({
      query: ({ projectId, page, size }) => ({
        url: 'projects/status-history',
        params: { projectId, page, size }
      }),
      providesTags: (_result, _error, { projectId }) => getProvidedTags('Projects', projectId)
    }),
    createReorderProject: builder.mutation<Pto.Sagas.SagaWithSteps, Pto.Sagas.CreateReorderProject>({
      query: (createReorderProject) => {
        return {
          url: '/sagas/projects/create-reorder-project',
          body: createReorderProject,
          method: 'POST'
        }
      }
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Project') {
    store.dispatch(
      projectsApiV2.util.invalidateTags([
        { type: 'Projects', id: payload.entityId },
        { type: 'ProjectProductItemAttachments', id: 'LIST' },
        { type: 'Invoices', id: `LIST` }
      ])
    )

    store.dispatch(shipmentsApi.util.invalidateTags([{ type: 'Projects', id: payload.entityId }, { type: 'ShippingTasks' }]))
  }

  if (payload.entityType === 'Design request') {
    store.dispatch(projectsApiV2.util.invalidateTags([{ type: 'DesignRequests', id: payload.entityId }]))
  }
}

export const subscribeToProjectEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('project-created', () => {
    dispatch(projectsApiV2.util.invalidateTags([{ type: 'Projects', id: 'LIST' }]))
  })
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromProjectEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
  socket.off('project-created')
}

export const {
  useProjectListQuery,
  useProjectListByCustomerQuery,
  useProjectQuery,
  useProjectProductItemAttachmentsQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useSetProjectShippingAddressMutation,
  useCreateProjectNoteMutation,
  useDeleteProjectNoteMutation,
  useUpdateProjectTagsMutation,
  useInvoiceListQuery,
  useInvoiceQuery,
  useInvoicePaidMutation,
  useInvoiceChangeStatusMutation,
  useCreateInvoiceMutation,
  useGetInvoiceOptionsQuery,
  useSendInvoiceEmailMutation,
  useUpdateProjectServiceItemMutation,
  useUpdateProjectProductItemsMutation,
  useUpdateDeliveryDateMutation,
  useManualUpdateProjectStatusMutation,
  useUpdateSaleSourceMutation,
  useAddProjectManagerMutation,
  useRemoveProjectManagerMutation,
  useProjectStatusHistoryListQuery,
  useCreateReorderProjectMutation
} = projectsApiV2
