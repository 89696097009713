import React from 'react'
import { Select } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { Pto } from '@merchx-v3/pto'
import { useSupplierPluginOptionsQuery } from 'app/api/plugins-api'

type Props = {
  pluginType: Pto.Plugins.PluginType
  supplierId?: string
  category?: string
  subcategory?: string
  defaultValue?: string
  placeholder?: string
  disabled?: boolean
  className?: string
  value?: Pto.Option
  size?: SizeType
  onChange?: (value?: Pto.Option) => void
}

const SupplierPluginOptions = (props: Props) => {
  const { placeholder, disabled, className, value: selectedPlugin, pluginType, size, onChange, supplierId, category, subcategory } = props

  const { data: fulfillmentPluginOptions = [] } = useSupplierPluginOptionsQuery(
    {
      supplierId: supplierId!,
      category,
      subcategory,
      pluginType
    },
    {
      skip: ![Pto.Plugins.PluginType.Fulfillment, Pto.Plugins.PluginType.Shipping].includes(pluginType) || !supplierId
    }
  )

  const handleSelect = (_value: string, option: Pto.Option) => {
    onChange && onChange(option)
  }

  const handleClear = () => {
    onChange && onChange(undefined)
  }

  // useEffect(() => {
  //   onChange && onChange(undefined)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [supplierId, category, subcategory])

  let pluginOptions: Pto.Option[] = []

  if ([Pto.Plugins.PluginType.Fulfillment, Pto.Plugins.PluginType.Shipping].includes(pluginType)) {
    pluginOptions = fulfillmentPluginOptions
  }

  return (
    <Select
      options={pluginOptions}
      className={className}
      value={selectedPlugin?.value}
      disabled={disabled}
      onSelect={handleSelect}
      placeholder={placeholder}
      size={size}
      allowClear
      onClear={handleClear}
    />
  )
}

export default SupplierPluginOptions
