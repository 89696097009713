import { WebSocket } from '@merchx-v3/web-socket'
import { Middleware } from '@reduxjs/toolkit'

import { subscribeToConversationEvents, unsubscribeFromConversationEvents } from './api/conversations-api'
import { subscribeToCustomerEvents, unsubscribeFromCustomerEvents } from './api/customers-api'
import { subscribeToDesignRequestEvents, unsubscribeFromDesignRequestEvents } from './api/design-requests'
import { subscribeToDesignTemplateEvents, unsubscribeFromDesignTemplateEvents } from './api/design-templates-api'
import { subscribeToDesignEvents, unsubscribeFromDesignEvents } from './api/designs-api'
import { subscribeToEcomProductEvents, unsubscribeFromEcomProductEvents } from './api/ecom-products-api'
import { subscribeToFulfillmentTaskEvents, unsubscribeFromFulfillmentTaskEvents } from './api/fulfillment-tasks-api'
import { subscribeToPaymentEvents, unsubscribeFromPaymentEvents } from './api/payments-api'
import { subscribeToPluginEvents, unsubscribeFromPluginEvents } from './api/plugins-api'
import { subscribeToProcessingTemplateEvents, unsubscribeFromProcessingTemplateEvents } from './api/processing-templates-api'
import { subscribeToProjectEvents, unsubscribeFromProjectEvents } from './api/projects-api'
import { subscribeToSagaEvents, unsubscribeFromSagaEvents } from './api/sagas-api'
import { subscribeToShipmentEvents, unsubscribeFromShipmentEvents } from './api/shipments-api'
import { subscribeToSupplierProductEvents, unsubscribeFromSupplierProductEvents } from './api/supplier-products-api'
import { subscribeToSupplierEvents, unsubscribeFromSupplierEvents } from './api/suppliers-api'
import { subscribeToUserEvents, unsubscribeFromUserEvents } from './api/users-api'

import { subscribeSliceToSagaEvents, unsubscribeSliceFromSagaEvents } from 'interfaces/admin/features/sagas/sagasSlice'

type WebSocketMiddleware = (socket: WebSocket.MxWebSocket) => Middleware

// const protocol = process.env.REACT_APP_PROTOCOL
// const domain = process.env.REACT_APP_DOMAIN

export const webSocketMiddleware: WebSocketMiddleware = (socket: WebSocket.MxWebSocket) => (params) => (next) => (action) => {
  const { dispatch, getState } = params
  const { type, payload } = action

  switch (type) {
    case 'socket/connect':
      // Put other subscriptions after that line

      subscribeSliceToSagaEvents(socket, dispatch)

      subscribeToConversationEvents(socket, dispatch)
      subscribeToCustomerEvents(socket, dispatch)
      subscribeToDesignRequestEvents(socket, dispatch)
      subscribeToDesignTemplateEvents(socket, dispatch)
      subscribeToDesignEvents(socket, dispatch)
      subscribeToEcomProductEvents(socket, dispatch)
      subscribeToFulfillmentTaskEvents(socket, dispatch)
      subscribeToPaymentEvents(socket, dispatch)
      subscribeToPluginEvents(socket, dispatch)
      subscribeToProcessingTemplateEvents(socket, dispatch)
      subscribeToProjectEvents(socket, dispatch)
      subscribeToSagaEvents(socket, dispatch)
      subscribeToShipmentEvents(socket, dispatch)
      subscribeToSupplierProductEvents(socket, dispatch)
      subscribeToSupplierEvents(socket, dispatch)
      subscribeToUserEvents(socket, dispatch)
      break

    case 'socket/disconnect':
      unsubscribeFromConversationEvents(socket)
      unsubscribeFromCustomerEvents(socket)
      unsubscribeFromDesignRequestEvents(socket)
      unsubscribeFromDesignTemplateEvents(socket)
      unsubscribeFromDesignEvents(socket)
      unsubscribeFromEcomProductEvents(socket)
      unsubscribeFromFulfillmentTaskEvents(socket)
      unsubscribeFromPaymentEvents(socket)
      unsubscribeFromPluginEvents(socket)
      unsubscribeFromProjectEvents(socket)
      unsubscribeFromProcessingTemplateEvents(socket)
      unsubscribeFromSagaEvents(socket)
      unsubscribeFromShipmentEvents(socket)
      unsubscribeFromSupplierProductEvents(socket)
      unsubscribeFromSupplierEvents(socket)
      unsubscribeFromUserEvents(socket)

      unsubscribeSliceFromSagaEvents(socket, dispatch)

      // Put other subscriptions before
      break

    default:
      break
  }

  return next(action)
}
