import { Pto } from '@merchx-v3/pto'
import { DatePicker, Form, Input, Modal, Typography } from 'antd'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { useBanUserMutation } from 'app/api/users-api'

type Props = {
  user: Pto.Users.ListItem
  isModalOpened: boolean
  onClose: () => void
}

const BanUserModal = ({ user, isModalOpened, onClose }: Props) => {
  const [form] = Form.useForm()

  const [banUser, { isLoading }] = useBanUserMutation()

  const handleBan = (values: { reason: string; banTime: dayjs.Dayjs }) => {
    const now = dayjs()
    const banTimeInMilliseconds = values.banTime.diff(now)

    banUser({ userId: user.id, reason: values.reason, banTimeInMilliseconds })
    toast.success('User baned successfully!')
    onClose()
  }

  return (
    <Modal forceRender open={isModalOpened} onCancel={onClose} centered title="Ban User" okText="Ban" onOk={form.submit} confirmLoading={isLoading}>
      <Form name="banInfo" layout="vertical" form={form} onFinish={handleBan}>
        <Typography style={{ fontWeight: 'bold', marginBottom: 8 }}> User Email: {user.email}</Typography>
        <Form.Item label="Ban Time" name="banTime" rules={[{ required: true, message: 'Please set Ban Time!' }]}>
          {/* findDOMNode warning */}
          <DatePicker showTime style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item label="Reason" name="reason" rules={[{ required: true, message: 'Please input Ban Reason!' }]}>
          <Input placeholder="Reason" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default BanUserModal
