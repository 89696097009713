import * as React from 'react'

export const useHandleClickOutside = (ref: any, handler: (e?: any) => void, excludeRefs: any[] = []) => {
  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target) && !excludeRefs.some((item) => item.current === event.target)) {
        handler(event)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref])
}

export default useHandleClickOutside
