import { Modal } from 'antd'
import { Pto } from '@merchx-v3/pto'
import { toast } from 'react-toastify'

import { useActivateUserMutation, useDeactivateUserMutation } from 'app/api/users-api'

type Props = {
  user: Pto.Users.ListItem
  isModalOpened: boolean

  onClose: () => void
}

const DisableUserModal = ({ user, isModalOpened, onClose }: Props) => {
  const [activateUser, { isLoading: isActivateLoading }] = useActivateUserMutation()
  const [disableUser, { isLoading: isDeactivateLoading }] = useDeactivateUserMutation()

  const handleActivateUser = async () => {
    activateUser(user.id)
      .unwrap()
      .then(() => {
        toast.success('User was activated successfully')
        onClose()
      })
      .catch((error) => toast.error(error))
  }

  const handleDisableUser = async () => {
    disableUser(user.id)
      .unwrap()
      .then(() => {
        toast.success('User was deactivated successfully')
        onClose()
      })
      .catch((error) => toast.error(error))
  }

  const isActive = user.status === Pto.Users.UserStatus.Active

  return (
    <Modal
      open={isModalOpened}
      onCancel={onClose}
      centered
      title={isActive ? 'Deactivate User' : 'Activate User'}
      onOk={isActive ? handleDisableUser : handleActivateUser}
      okButtonProps={{ loading: isDeactivateLoading || isActivateLoading }}
      cancelButtonProps={{ loading: isDeactivateLoading || isActivateLoading }}
    >
      {/* <Typography style={{ fontWeight: 'bold', marginBottom: 8 }}>Functionality is not implemented yet</Typography> */}
    </Modal>
  )
}

export default DisableUserModal
