import { Typography } from 'antd'
import styles from './ErrorContainer.module.scss'

type Props = {
  children: any
  isShowError: boolean
  errorMessage: string
}

const ErrorContainer = ({ children, isShowError, errorMessage }: Props) => {
  return (
    <div className={styles.container}>
      <label className={styles.wrapper}>
        {children}
        {isShowError && errorMessage && (
          <div className={styles.iconError}>
            <i className="icon icon-error" style={{ fontSize: 24 }} />
          </div>
        )}
      </label>
      {isShowError && errorMessage && <Typography className={styles.errorMessage}>{errorMessage}</Typography>}
    </div>
  )
}

export default ErrorContainer
