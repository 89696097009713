import { Select } from 'antd'
import { useState } from 'react'
import { Pto } from '@merchx-v3/pto'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import useDebounce from 'helpers/useDebounce'
import { useClaimArgOptionsQuery } from 'app/api/users-api'

type Props = {
  claim: Pto.Auth.Claim
  defaultValue?: string
  placeholder?: string
  disabled?: boolean
  className?: string
  value?: string
  size?: SizeType
  style?: React.CSSProperties

  onChange?: (value?: string) => void
}

const ClaimArgOptions = (props: Props) => {
  const { placeholder, disabled, className, value, size, style, claim, onChange } = props

  const [searchText, setSearchText] = useState<string>(value || '')

  const debouncedSearchText = useDebounce(searchText, 500)

  const { data: claimArgOptions = [] } = useClaimArgOptionsQuery({ claim, searchText: debouncedSearchText })

  const handleSelect = (selectedOption: string | Pto.Option) => {
    let seletedValue: string = ''

    if (typeof selectedOption === 'string') {
      seletedValue = selectedOption
    } else {
      seletedValue = selectedOption.value
    }

    onChange && onChange(seletedValue)
  }

  const handleSearch = (value: string) => {
    setSearchText(value)
  }

  const handleClear = () => {
    onChange && onChange(undefined)
    setSearchText('')
  }

  return (
    <Select
      options={claimArgOptions}
      className={className}
      value={value}
      style={style}
      searchValue={searchText}
      disabled={disabled}
      showSearch
      onSelect={handleSelect}
      onSearch={handleSearch}
      placeholder={placeholder}
      size={size}
      allowClear
      labelInValue
      optionFilterProp="label"
      onClear={handleClear}
    />
  )
}

export default ClaimArgOptions
