import { v4 } from 'uuid'
import { WebSocket } from '@merchx-v3/web-socket'

import { store } from './store'
import { authProvider } from './auth/auth-provider'
import { tokenProvider } from './auth/token-provider'

export const appWebSocket = new WebSocket.MxWebSocket(v4())

tokenProvider.addEventListener('token-provider-initialized', (event) => {
  console.log('Web socket: initialization started')
  appWebSocket.setAccessToken(event.detail)
  appWebSocket.reconnect()
})

tokenProvider.addEventListener('access-token-changed', (event) => {
  console.log('Web socket: access token changed')
  appWebSocket.setAccessToken(event.detail)
})

authProvider.addEventListener('user-sign-in', (event) => {
  console.log('Web socket: user signed in')
  appWebSocket.disconnect()
  appWebSocket.reconnect()

  store.dispatch({ type: 'socket/connect' })
})

authProvider.addEventListener('user-sign-out', (event) => {
  console.log('Web socket: user signed out')
  appWebSocket.disconnect()
  store.dispatch({ type: 'socket/disconnect' })
})
