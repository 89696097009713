import clsx from 'clsx'
import styles from './Row.module.scss'

type Props = {
  className?: string
  wrap?: boolean
}

export const Row = (props: React.PropsWithChildren<Props>) => {
  return (
    <div data-wrap={props.wrap} className={clsx(props.className, styles.row)}>
      {props.children}
    </div>
  )
}

export default Row
