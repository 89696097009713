import { Pto } from '@merchx-v3/pto'
import { Avatar } from '@chatscope/chat-ui-kit-react'
import { PresentationAPI } from '@merchx-v3/shared-functions'

import styles from './MessageAvatar.module.scss'

type Props = {
  isVisible?: boolean
  user?: Pto.Conversations.User
}

export type MessagePosition = 'single' | 'first' | 'normal' | 'last' | 0 | 1 | 2 | 3

export const getMessageAvatar = ({ isVisible, user }: Props) => {
  if (!isVisible) return null

  if (!user) return <Avatar className={styles.avatar}>{'U'}</Avatar>

  if (!!user.avatarUrl) return <Avatar active={user.presence === Pto.Conversations.Presence.Online ? true : false} src={user.avatarUrl} />

  return (
    <Avatar active={user.presence === Pto.Conversations.Presence.Online ? true : false} className={styles.avatar}>
      {PresentationAPI.getUserFirstLetter(user)}
    </Avatar>
  )
}

export const getMessagePosition = (messageIndex: number, totalMessages: number) => {
  if (totalMessages === 1) return 'single'

  if (messageIndex === 0) return 'first'

  if (messageIndex === totalMessages - 1) return 'last'

  return 'normal'
}
