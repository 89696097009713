import { Pto } from '@merchx-v3/pto'

export type AuthProviderState = 'INIT' | 'PROCESSING' | 'READY' | 'ERROR'

export class AuthProviderStateChanged extends CustomEvent<AuthProviderState> {
  constructor(state: AuthProviderState) {
    super('state-changed', { detail: state })
  }
}

export class UserSignInEvent extends CustomEvent<Pto.Users.User> {
  constructor(user: Pto.Users.User) {
    super('user-sign-in', { detail: user })
  }
}

export class UserSignOutEvent extends CustomEvent<Pto.Users.User> {
  constructor(user: Pto.Users.User) {
    super('user-sign-out', { detail: user })
  }
}

export class UserChangedEvent extends CustomEvent<Pto.Users.User | undefined> {
  constructor(user?: Pto.Users.User) {
    super('user-changed', { detail: user })
  }
}

export interface AuthProviderEventMap {
  'state-changed': AuthProviderStateChanged
  'user-sign-in': UserSignInEvent
  'user-sign-out': UserSignOutEvent
  'user-changed': UserChangedEvent
}

interface AuthProviderET extends EventTarget {
  addEventListener<K extends keyof AuthProviderEventMap>(type: K, listener: (ev: AuthProviderEventMap[K]) => void, options?: boolean | AddEventListenerOptions): void
  addEventListener(type: string, callback: EventListenerOrEventListenerObject | null, options?: EventListenerOptions | boolean): void
  removeEventListener<K extends keyof AuthProviderEventMap>(type: K, callback: (ev: AuthProviderEventMap[K]) => void): void
  removeEventListener(type: string, callback: EventListenerOrEventListenerObject): void
}

export const AuthProviderEventTarget = EventTarget as { new (): AuthProviderET; prototype: AuthProviderET }

export interface IAuthProvider extends AuthProviderET {
  get state(): AuthProviderState
  get error(): string | undefined

  get isLoggedIn(): boolean

  getUser: () => Pto.Users.User | undefined

  signIn: (accessToken: string, refreshToken: string, isRememberMe?: boolean) => void
  signOut: () => void
}
