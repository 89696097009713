import { Pto } from '@merchx-v3/pto'
import type { ProgressProps } from 'antd'
import { useEffect, useState } from 'react'
import { WebSocket } from '@merchx-v3/web-socket'
import { Modal, Progress, Typography } from 'antd'

import { useUser } from 'app/auth'
import { appWebSocket } from 'app/websocket'

const directFlowColors: ProgressProps['strokeColor'] = {
  '0%': '#108ee9',
  '100%': '#87d068'
}

const rollbackFlowColors: ProgressProps['strokeColor'] = {
  '0%': '#cf2b4c',
  '100%': '#a840ad'
}

const phaseStatus: Record<Pto.SagasV2.Status, 'normal' | 'exception' | 'active' | 'success'> = {
  [Pto.SagasV2.Status.Processing]: 'active',
  [Pto.SagasV2.Status.Completed]: 'success',
  [Pto.SagasV2.Status.Failed]: 'exception',
  [Pto.SagasV2.Status.Rollback]: 'active'
}

const phaseColors: Record<Pto.SagasV2.Status, ProgressProps['strokeColor']> = {
  [Pto.SagasV2.Status.Processing]: directFlowColors,
  [Pto.SagasV2.Status.Completed]: directFlowColors,
  [Pto.SagasV2.Status.Failed]: rollbackFlowColors,
  [Pto.SagasV2.Status.Rollback]: rollbackFlowColors
}

export const SagaV2Modal = () => {
  const [user] = useUser()
  const [isOpened, setIsOpened] = useState(true)
  const [saga, setSaga] = useState<WebSocket.SagasV2.Listeners.SagaV2UpdatedPayload>()

  useEffect(() => {
    const handleSagaV2Updated = (sagaUpdatedPayload: WebSocket.SagasV2.Listeners.SagaV2UpdatedPayload) => {
      setSaga(sagaUpdatedPayload)
      setIsOpened(sagaUpdatedPayload.status !== Pto.SagasV2.Status.Completed)
    }

    appWebSocket.on('saga-v2-updated', handleSagaV2Updated)

    return () => {
      appWebSocket.off('saga-v2-updated', handleSagaV2Updated)
    }
  })

  if (!user || !saga) return null

  return (
    <Modal
      title={
        <Typography.Title level={5} style={{ textAlign: 'center' }}>
          {saga.name}
        </Typography.Title>
      }
      centered
      open={isOpened}
      onCancel={() => setIsOpened(false)}
      footer={false}
    >
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography>{saga.activePhase}</Typography>
        <Progress percent={saga.progress} strokeColor={phaseColors[saga.status]} status={phaseStatus[saga.status]} />
        <Typography.Text type="secondary" style={{ fontSize: 12, whiteSpace: 'pre-line' }}>
          {saga.message}
        </Typography.Text>
      </div>
    </Modal>
  )
}
