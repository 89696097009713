import { Image } from 'antd'
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons'
import { Message } from '@chatscope/chat-ui-kit-react'
import { Pto } from '@merchx-v3/pto'

import { ConversationMessage } from 'app/chat/types'
import { MessagePosition } from './helpers'

import styles from './GalleryMessage.module.scss'

type Props = {
  message: ConversationMessage<Pto.Conversations.MessageContentType.Gallery>
  position: MessagePosition
  user?: Pto.Conversations.User
}

export const GalleryMessage = (props: Props) => {
  const { message, position } = props

  const onDownload = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, imgUrl: string) => {
    e.stopPropagation()
    fetch(imgUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]))
        const link = document.createElement<'a'>('a')
        link.href = url
        link.download = 'image.png'
        document.body.appendChild(link)
        link.click()
        URL.revokeObjectURL(url)
        link.remove()
      })
  }

  const mask = (imgUrl: string) => (
    <div className={styles.preview}>
      <EyeOutlined />
      <DownloadOutlined onClick={(e) => onDownload(e, imgUrl)} />
    </div>
  )

  return (
    <Message
      id={message.id}
      type="custom"
      model={{
        direction: message.direction,
        position
      }}
      avatarSpacer={true}
    >
      <Message.CustomContent>
        <div className={styles.gallery} data-count={message.content.images.length}>
          {message.content.images.map((galleryItem) => (
            <Image
              key={galleryItem.url}
              src={`/images/${galleryItem.url}?width=200&height=200`}
              preview={{ src: `/images/${galleryItem.url}`, mask: mask(`/images/${galleryItem.url}`) }}
              alt={galleryItem.altName}
            />
          ))}
        </div>

        <Message.HtmlContent html={message.content.message} />
      </Message.CustomContent>
    </Message>
  )
}
