import { Pto } from '@merchx-v3/pto'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

export const reportingApi = createApi({
  reducerPath: 'reportingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['Reports'],
  endpoints: (builder) => ({
    adminReport: builder.query<Pto.Reports.AdminReportItem[], Pto.Reports.AdminReportFilters>({
      query: (params) => ({
        url: `/reporting/admin`,
        params,
        method: 'GET'
      }),
      providesTags: () => getProvidedTags('Reports', 'admin')
    }),
    adminReportCsv: builder.query<null, Pto.Reports.AdminReportFilters>({
      queryFn: async (params, _api, _extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `/reporting/admin/csv`,
          params,
          responseHandler: (response) => response.blob()
        })
        const hiddenElement = document.createElement('a')
        const url = window.URL || window.webkitURL
        const blob = url.createObjectURL(result.data as Blob)
        hiddenElement.href = blob
        hiddenElement.target = '_blank'
        hiddenElement.download = `admin-report.csv`
        hiddenElement.click()
        return { data: null }
      }
    }),
    projectHistory: builder.query<Pto.Reports.ProjectHistoryReportItem[], Pto.Reports.ProjectHistoryReportFilters>({
      query: (params) => ({
        url: `/reporting/project-history`,
        params,
        method: 'GET'
      })
    }),
    shippingHistory: builder.query<Pto.Reports.ShippingHistoryReportItem[], Pto.Reports.ShippingHistoryReportFilters>({
      query: (params) => ({
        url: `/reporting/shipping-history`,
        params,
        method: 'GET'
      })
    }),
    fulfillmentHistory: builder.query<Pto.Reports.FulfillmentHistoryReportItem[], Pto.Reports.FulfillmentHistoryReportFilters>({
      query: (params) => ({
        url: `/reporting/fulfillment-history`,
        params,
        method: 'GET'
      })
    }),
    designRequestHistory: builder.query<Pto.Reports.DesignRequestHistoryReportItem[], Pto.Reports.DesignRequestHistoryReportFilters>({
      query: (params) => ({
        url: `/reporting/design-request-history`,
        params,
        method: 'GET'
      })
    })
  })
})

export const { useAdminReportQuery, useLazyAdminReportCsvQuery, useProjectHistoryQuery, useShippingHistoryQuery, useFulfillmentHistoryQuery, useDesignRequestHistoryQuery } =
  reportingApi
