enum AppRoutes {
  // MANAGERS ROUTES

  main = '/',
  ecomProducts = 'ecom-products',
  customers = 'customers',
  customerReport = 'customers/report',
  projects = 'projects',
  profile = 'profile',
  designs = 'designs',
  designRequest = 'design-requests',
  invoices = 'invoices',
  fulfillment = 'fulfillment',
  chats = 'chats'
}

export default AppRoutes
