import { lazy } from 'react'
import { Pto, Role } from '@merchx-v3/pto'
import { Navigate, Route, Routes } from 'react-router-dom'

import { useUser } from 'app/auth'
import AppRoutes from './AppRoutes'
import PrivateRoute from 'components/PrivateRoute'
import NotFoundPage from 'components/NotFoundPage'
import Layout from '../Layout'
import { Spin } from 'antd'

// DESIGNERS

const Chats = lazy(() => import('interfaces/sharedComponents/Chat/DesktopChat'))
const Design = lazy(() => import('interfaces/sharedPages/Design'))
const DesignList = lazy(() => import('interfaces/sharedPages/DesignList'))
const DesignRequestList = lazy(() => import('interfaces/sharedPages/DesignRequestList'))
const DesignRequest = lazy(() => import('interfaces/sharedPages/DesignRequest'))
const DesignRequestHistory = lazy(() => import('interfaces/sharedPages/DesignRequestHistory'))
const Dashboard = lazy(() => import('interfaces/designers/pages/Dashboard'))
const ProfilePage = lazy(() => import('interfaces/sharedPages/ProfilePage'))

const Router = () => {
  const [user, isLoading] = useUser()

  if (isLoading) return <Spin />

  if (
    user &&
    !user.roles.includes(Role.SystemAdmin) &&
    !user.claims.some((claim) => claim.claim === Pto.Auth.Claim.DesignerPortal && [Pto.Auth.AccessLevel.AllowAll, Pto.Auth.AccessLevel.LimitedAccess].includes(claim.accessLevel))
  ) {
    return <Navigate to="/" />
  }

  if (!user && !isLoading) return <Navigate to="/login" />

  return (
    <Layout shouldWaitUser>
      <Routes>
        <Route
          index
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.designs}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <DesignList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.designs}/:designId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <Design />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.designRequests}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <DesignRequestList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.designRequests}/:designRequestId`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <DesignRequest />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.designRequests}/:designRequestId/history`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <DesignRequestHistory />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.chats}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <Chats />
            </PrivateRoute>
          }
        />
        <Route
          path={`${AppRoutes.profile}`}
          element={
            <PrivateRoute userRoles={user?.roles} roles={[Role.User, Role.SystemAdmin]}>
              <ProfilePage />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Layout>
  )
}

export default Router
