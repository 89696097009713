import axios from 'app/axios'
import { AxiosError } from 'axios'
import { Pto } from '@merchx-v3/pto'
import { RcFile } from 'antd/es/upload'
import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'

import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'

const { protocol, domain } = settings.site

type UploadTemporaryFileArgs = Pto.Files.FileUploads & {
  files: RcFile[]
  onUploadProgress: (loaded: number, progress: number) => void
}

export const filesApi = createApi({
  reducerPath: 'filesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),

  endpoints: (builder) => ({
    uploadTemporaryFiles: builder.mutation<Pto.Files.UploadedFile[], UploadTemporaryFileArgs>({
      queryFn: async ({ owner, ownerId, type, uploads, files, onUploadProgress }, api) => {
        try {
          const formData = new FormData()
          if (!files.length) throw new AxiosError('Unprocessable content', '422')

          formData.append('owner', owner)
          formData.append('ownerId', ownerId)
          formData.append('type', type)
          for (let i = 0; i < files.length; i++) {
            formData.append(`files`, files[i])
          }
          for (let i = 0; i < uploads.length; i++) {
            const { id, displayName, meta } = uploads[i]
            formData.append(`uploads[${i}][id]`, id)
            formData.append(`uploads[${i}][displayName]`, displayName || '')
            if (meta?.length) {
              for (let j = 0; j < meta.length; j++) {
                formData.append(`uploads[${i}][meta][${j}][key]`, meta[j].key)
                formData.append(`uploads[${i}][meta][${j}][value]`, meta[j].value)
              }
            }
          }

          const result = await axios.post('/files', formData, {
            signal: api.signal,
            onUploadProgress: (upload) => {
              const uploadloadProgress = Math.round((100 * upload.loaded) / (upload.total || 1))
              onUploadProgress(upload.loaded, uploadloadProgress)
            }
          })

          return { data: result.data }
        } catch (axiosError) {
          let err = axiosError as AxiosError

          const fetchError: FetchBaseQueryError = {
            status: err.response?.status || 500,
            data: err.response?.data || err.message
          }

          return {
            error: fetchError
          }
        }
      }
    })
  })
})

export const { useUploadTemporaryFilesMutation } = filesApi
