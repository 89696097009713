enum AppRoutes {
  // SHARED ROUTES
  login = 'login',
  logout = 'logout',

  resetPassword = 'reset-password',
  setNewPassword = 'set-new-password',

  guestLogin = 'customers/:inviteLink',

  designConfirmaion = 'design-requests/:designRequestId/:version/confirm-design',
  payInvoice = 'invoices/:invoiceId/pay'
  // resetPassword = '/reset-password',
  // confirmInvite = '/confirm-invite'
}

export default AppRoutes
